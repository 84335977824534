import { memo, ReactNode } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import MDEditor from "@uiw/react-md-editor/nohighlight";
import { AxiosError } from "axios";
import { toast } from "sonner";
import * as z from "zod";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";

import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";
import { UserGenders } from "~/api/model";
import { useUpdateUserProfileV1UsersUserIdUpdateUserProfilePatch } from "~/api/users/users.gen";
import { Button } from "~/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { CountryList, useGetCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";

const formSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    postalCode: z.string(),
    city: z.string(),
    street: z.string(),
    country: z.string(),
    profileEmail: z.string(),
    email: z.string(),
    gender: z.string().optional(),
    birthdate: z.date().optional(),
    comments: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.birthdate) {
      const today = new Date();
      const birthdate = new Date(data.birthdate);
      let age = today.getFullYear() - birthdate.getFullYear();
      const month = today.getMonth() - birthdate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
        age--;
      }
      if (age < 12) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "User must be at least 12 years old",
          path: ["birthdate"],
        });
      }
    }
    if (data.email && data.profileEmail) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Cannot set both email and profile email",
        path: ["profileEmail"],
      });
    }
  });

interface UserProfileFormProps {
  userId: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  city: string;
  street: string;
  country: string;
  profileEmail: string;
  email: string;
  gender?: UserGenders;
  birthdate?: Date;
  comments: string;
  refetch: () => void;
}

function UserProfileForm({
  userId,
  firstName,
  lastName,
  postalCode,
  city,
  street,
  country,
  profileEmail,
  email,
  gender,
  birthdate,
  comments,
  refetch,
}: UserProfileFormProps) {
  const queryClient = useQueryClient();

  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      initialData: [],
    },
  });

  const updateUserProfileMutation = useUpdateUserProfileV1UsersUserIdUpdateUserProfilePatch({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
        toast("User profile was successfully updated!");
        refetch();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      postalCode: postalCode ?? "",
      city: city ?? "",
      street: street ?? "",
      country: country ?? "",
      profileEmail: profileEmail ?? "",
      email: email ?? "",
      gender: gender || "",
      birthdate: birthdate ? new Date(birthdate) : undefined,
      comments: comments ?? "",
    },
  });

  const onProfileSubmit = async (values: z.infer<typeof formSchema>) => {
    await updateUserProfileMutation.mutateAsync({
      userId: userId as string,
      data: {
        first_name: values.firstName,
        last_name: values.lastName,
        postal_code: values.postalCode,
        city: values.city,
        street: values.street,
        country: values.country,
        comments: values.comments,
        profile_email: values.profileEmail,
        email: values.email,
        birthdate: values.birthdate?.toISOString(),
        gender: values.gender !== "" ? (values.gender as UserGenders) : undefined,
      },
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onProfileSubmit)} className="space-y-8">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First name:</FormLabel>
                <FormControl>
                  <Input placeholder="User first name." {...field} />
                </FormControl>
                <FormDescription>User first name.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last name:</FormLabel>
                <FormControl>
                  <Input placeholder="User last name." {...field} />
                </FormControl>
                <FormDescription>User last name.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Verified Email:</FormLabel>
                <FormControl>
                  <Input placeholder="The email that the user has verified." {...field} />
                </FormControl>
                <FormDescription>The email that the user has verified.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="profileEmail"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Profile email:</FormLabel>
                <FormControl>
                  <Input placeholder="The old email field that is deprecated." {...field} />
                </FormControl>
                <FormDescription>The old email field that is deprecated.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="birthdate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Birthdate:</FormLabel>
                <FormControl>
                  <Input
                    type="date"
                    placeholder="The day the user was born."
                    {...field}
                    onChange={(e) => {
                      field.onChange(new Date(e.target.value));
                    }}
                    value={field.value instanceof Date ? field.value.toISOString().split("T")[0] : field.value || ""}
                  />
                </FormControl>
                <FormDescription>The day the user was born.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="gender"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Gender:</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={(value) => {
                      if (value === "None") {
                        field.onChange(undefined);
                      } else {
                        field.onChange(value);
                      }
                    }}
                    defaultValue={field.value ?? "None"}
                  >
                    <SelectTrigger ref={field.ref}>
                      <SelectValue placeholder="No gender known" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="None">No gender known</SelectItem>
                      <SelectItem value={UserGenders.Female}>Female</SelectItem>
                      <SelectItem value={UserGenders.Male}>Male</SelectItem>
                      <SelectItem value={UserGenders.Other}>Other</SelectItem>
                      <SelectItem value={UserGenders.PreferNotToSay}>Prefer not to say</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormDescription>The gender of the user.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Market:</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={(value) => {
                      if (value === "None") {
                        field.onChange(undefined);
                      } else {
                        field.onChange(value);
                      }
                    }}
                    defaultValue={field.value ?? "None"}
                  >
                    <SelectTrigger ref={field.ref}>
                      <SelectValue placeholder="No market" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="None">No market</SelectItem>
                      {countries.map((country) => {
                        const countryEntry = CountryList.find((element) => element.code === country);
                        return (
                          <SelectItem key={country} value={country}>
                            {countryEntry?.name}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormDescription>User&apos;s choosen market.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormLabel>City:</FormLabel>
                <FormControl>
                  <Input placeholder="User city." {...field} />
                </FormControl>
                <FormDescription>User city.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="street"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Street:</FormLabel>
                <FormControl>
                  <Input placeholder="User street." {...field} />
                </FormControl>
                <FormDescription>User street.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="postalCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Postal code:</FormLabel>
                <FormControl>
                  <Input placeholder="User postal code." {...field} />
                </FormControl>
                <FormDescription>User postal code.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="comments"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Comments:</FormLabel>
              <FormControl>
                <MDEditor {...field} preview="edit" />
              </FormControl>
              <FormDescription>Admin user comments about the user.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">Update profile</Button>
      </form>
    </Form>
  );
}

export const MemoizedUserProfileForm = memo(UserProfileForm);
