"use client";

import React from "react";
import { CSSProperties, ReactNode } from "react";
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ColumnDef, flexRender, Row, Table as TableType } from "@tanstack/react-table";

import { Button } from "@/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { Icon } from "./icon/icon";

import { Reward } from "~/pages/Marketplace/CollectionsParts/types";
import { HighlightedCollectionWithCollection } from "~/providers/marketplace";

type Props = {
  table: TableType<HighlightedCollectionWithCollection> | TableType<Reward>;
  columns: ColumnDef<HighlightedCollectionWithCollection>[] | ColumnDef<Reward>[];
  handleDragEnd: (event: DragEndEvent) => void;
  dataIds: string[];
  combobox?: ReactNode;
};

// Cell Component
export const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    // Alternatively, you could set these attributes on the rows themselves
    <Button variant="ghost" {...attributes} {...listeners} type="button">
      <Icon icon="DragAndDrop" />
    </Button>
  );
};

// Row Component
const DraggableRow = ({ row }: { row: Row<HighlightedCollectionWithCollection> | Row<Reward> }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id as string,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), // let dnd-kit do its thing
    transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };
  return (
    // connect row ref to dnd-kit, apply important styles
    <TableRow ref={setNodeRef} style={style} data-state={row.getIsSelected() && "selected"}>
      {row.getVisibleCells().map((cell) => {
        // @ts-expect-error onClick is manually added by us
        if (cell.column.columnDef.onClick) {
          return (
            <TableCell
              key={cell.id}
              style={{ width: cell.column.getSize() }}
              // @ts-expect-error onClick is manually added by us
              onClick={(event) => cell.column.columnDef.onClick(event, cell.row)}
              className="cursor-pointer"
            >
              {
                // @ts-expect-error onClick is manually added by us
                flexRender(cell.column.columnDef.cell, cell.getContext())
              }
            </TableCell>
          );
        } else {
          return (
            <TableCell key={cell.id} style={{ width: cell.column.getSize() }}>
              {
                // @ts-expect-error onClick is manually added by us
                flexRender(cell.column.columnDef.cell, cell.getContext())
              }
            </TableCell>
          );
        }
      })}
    </TableRow>
  );
};

export function DnDDataTable({ table, columns, handleDragEnd, dataIds, combobox }: Props) {
  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  return (
    <DndContext collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd} sensors={sensors}>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="">
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {
                      // @ts-expect-error the editor compains but the type check is now happier
                      header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())
                    }
                    {header.column.columnDef.id === "addCombobox" && combobox}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <TableRow className="border-0">
            <TableCell className="p-3" />
          </TableRow>
          <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => <DraggableRow key={row.id} row={row} />)
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </SortableContext>
        </TableBody>
      </Table>
    </DndContext>
  );
}
