import { memo, useCallback, useMemo } from "react";
import { Link } from "@tanstack/react-router";

import { Icon } from "@/icon/icon";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/ui/sidebar";

import { Button, buttonVariants } from "./ui/button";
import { CreateFeedbackFromButton } from "./sentry-feedback";

import { cn } from "~/lib/utils";
import { useThemeSelector } from "~/providers/theme";
import { useAuthStore } from "~/store/authStore";
import { useMarketStore } from "~/store/market";

interface MenuItem {
  title: string;
  url: string;
  icon?: React.ReactNode;
  target?: string;
}

interface SidebarMenuGroupProps {
  title?: string;
  items: MenuItem[];
}

const campaignItems = [
  {
    title: "Invite Campaigns",
    url: "/invite-campaigns",
    icon: <Icon icon="InviteCampaign" />,
  },
  {
    title: "Step Campaigns",
    url: "/step-campaigns",
    icon: <Icon icon="StepCampaign" />,
  },
  {
    title: "Partner Apps",
    url: "/partner-apps",
    icon: <Icon icon="PartnerApps" />,
  },
];

const linkItems = [
  {
    title: "Points Links",
    url: "/points-links",
    icon: <Icon icon="PointsLinks" />,
  },
  {
    title: "Invite Links",
    url: "/invite-links",
    icon: <Icon icon="InviteLinks" />,
  },
];

const settingsItems = [
  {
    title: "General Config",
    url: "/general-config",
    icon: <Icon icon="FaGlobe" />,
  },
  {
    title: "Markets",
    url: "/markets",
    icon: <Icon icon="FaEarthEurope" />,
  },
  {
    title: "Admin Users",
    url: "/users/admin",
    icon: <Icon icon="FaUserTie" />,
  },
];

const externalItems = [
  {
    title: "Analytics",
    url: "https://analytics.stepler.io/",
    icon: <Icon icon="Analytics" />,
    target: "_blank",
  },
  {
    title: "Order Analytics",
    url: "https://analytics.stepler.io/dashboard/14-order-analytics?tab=262-information&date_filter=past1months&country=SE",
    icon: <Icon icon="BarChart" />,
    target: "_blank",
  },
  {
    title: "Samsung link generator",
    url: "https://samsung-link-generator.stepler.io/",
    icon: <Icon icon="Link" />,
    target: "_blank",
  },
  {
    title: "App Store Scraper",
    url: "https://app-store-scraper.stepler.io/",
    icon: <Icon icon="Store" />,
    target: "_blank",
  },
];

const MenuItem = memo(({ item }: { item: MenuItem }) => (
  <SidebarMenuItem>
    <SidebarMenuButton asChild>
      <Link
        to={item.url}
        className="group flex items-center whitespace-nowrap bg-sidebar hover:relative hover:border-primary"
        target={item.target}
        rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
      >
        {item.icon && (
          <div title={item.title} className="">
            {item.icon}
          </div>
        )}
        <div className="p-2 text-sm">{item.title}</div>
      </Link>
    </SidebarMenuButton>
  </SidebarMenuItem>
));

MenuItem.displayName = "MenuItem";

const SidebarMenuGroup = memo(({ title, items }: SidebarMenuGroupProps) => {
  return (
    <SidebarGroup>
      {title && <SidebarGroupLabel>{title}</SidebarGroupLabel>}
      <SidebarGroupContent>
        <SidebarMenu>
          {items.map((item) => (
            <MenuItem key={item.title} item={item} />
          ))}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
});

const SidebarFooterButtons = memo(() => {
  const user = useAuthStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);

  const { theme, setTheme } = useThemeSelector((context) => ({
    theme: context.theme,
    setTheme: context.setTheme,
  }));

  const handleThemeChange = useCallback(() => {
    setTheme(theme === "light" ? "dark" : "light");
  }, [theme, setTheme]);

  return (
    <div className="flex justify-between gap-2 group-data-[state=collapsed]:flex-col">
      {user && (
        <Link className={cn(buttonVariants({ variant: "ghost" }), "group-data-[state=collapsed]:px-0")} to="/users/$userId" params={{ userId: user.user_id }}>
          <Icon icon="FaClipboardUser" />
        </Link>
      )}
      <Button variant="ghost" className="group-data-[state=collapsed]:px-0" title="Change Theme" onClick={handleThemeChange}>
        <Icon icon={theme === "light" ? "DarkMode" : "Sunny"} />
      </Button>
      <Button variant="ghost" className="group-data-[state=collapsed]:px-0" title="Logout" onClick={logout}>
        <Icon icon="LogOut" />
      </Button>
    </div>
  );
});

SidebarFooterButtons.displayName = "SidebarFooterButtons";

export const AppSidebar = memo(() => {
  const isAdminOwner = useAuthStore((state) => state.isAdminOwner);
  const market = useMarketStore((state) => state.market);

  const items = useMemo(
    () => [
      {
        title: "Dashboard",
        url: "/dashboard",
        icon: <Icon icon="Dashboard" />,
      },
      {
        title: "Marketplace",
        url: `/${market || "SE"}/marketplace`,
        icon: <Icon icon="MarketPlace" />,
      },
      // {
      //   title: "Step Challenges",
      //   url: "/step-challenges",
      //   icon: <Icon icon="StepCampaign" />,
      // },
      {
        title: "Users",
        url: "/users",
        icon: <Icon icon="Users" />,
      },
      {
        title: "Transactions",
        url: "/transactions",
        icon: <Icon icon="Transactions" />,
      },
      {
        title: "Tickets",
        url: "/tickets",
        icon: <Icon icon="CustomerSupport" />,
      },
    ],
    [market],
  );

  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <div className="overflow-hidden rounded-md bg-purple-600 p-2 text-center capitalize text-white">
          {import.meta.env.VITE_REACT_APP_NODE_ENV}
        </div>
      </SidebarHeader>
      <SidebarContent>
        <SidebarMenuGroup items={items} />
        <SidebarMenuGroup title="Campaigns" items={campaignItems} />
        <SidebarMenuGroup title="Links" items={linkItems} />
        {isAdminOwner && <SidebarMenuGroup title="Settings" items={settingsItems} />}
        <SidebarMenuGroup title="External" items={externalItems} />
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <CreateFeedbackFromButton />
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarFooterButtons />
      </SidebarFooter>
    </Sidebar>
  );
});
