import type { ReactNode } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { SuspiciousActivitySubModel, UserModelOutput, UserRole } from "~/api/model";
import { useUpdateUserRolesV1UsersUserIdSetUserRolesPatch } from "~/api/users/users.gen";
import formatDate from "~/helpers/date-formatting";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Checkbox } from "~/oldComponents/form/Checkbox";

interface SuspiciousActivitySectionProps {
  user: UserModelOutput;
  refetch: () => void;
}

export function SuspiciousActivitySection({
  user,
  refetch,
}: SuspiciousActivitySectionProps) {
  const queryClient = useQueryClient();

  const updateUserRolesMutation = useUpdateUserRolesV1UsersUserIdSetUserRolesPatch({
    mutation: {
      onSuccess: () => {
        toast("User role was successfully updated!");
        queryClient.invalidateQueries({ queryKey: ["user", user?._id] });
        refetch();
      },
      onError: (error: AxiosError) => {
        queryClient.invalidateQueries({ queryKey: ["user", user?._id] });
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const toggleCheaterRole = async () => {
    const baseRoles = user?.roles || ([] as UserRole[]);
    const roles = baseRoles.includes(UserRole.Cheater)
      ? baseRoles.filter((role: string) => role !== UserRole.Cheater)
      : [...baseRoles, UserRole.Cheater];
    await updateUserRolesMutation.mutateAsync({
      userId: user?._id as string,
      data: {
        roles,
      },
    });
  };

  const togglePossibleCheaterRole = async () => {
    const baseRoles = user?.roles || ([] as UserRole[]);
    const roles = baseRoles.includes(UserRole.Possible_Cheater)
      ? baseRoles.filter((role: string) => role !== UserRole.Possible_Cheater)
      : [...baseRoles, UserRole.Possible_Cheater];
    await updateUserRolesMutation.mutateAsync({
      userId: user?._id as string,
      data: {
        roles,
      },
    });
  };

  return (
    <section className="user-data_section">
      <h5 className="data_section-header">Suspicious activity:</h5>
      <div className="user-data_checkboxes">
        <div className="user-data_checkbox-item">
          <span className="user-data_address-form_label">Cheater:</span>
          <Checkbox
            name="ischeater"
            checked={user?.roles?.includes(UserRole.Cheater)}
            onChange={toggleCheaterRole}
          />
        </div>
        <div className="user-data_checkbox-item">
          <span className="user-data_address-form_label">Possible cheater:</span>
          <Checkbox
            name="ispossiblecheater"
            checked={user?.roles?.includes(UserRole.Possible_Cheater)}
            onChange={togglePossibleCheaterRole}
          />
        </div>
      </div>
      {!!user?.suspiciousActivity?.length && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[300px]">Check date</TableHead>
              <TableHead>What happened</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {user?.suspiciousActivity.map((activity: SuspiciousActivitySubModel) => (
              <TableRow key={activity.date}>
                <TableCell className="font-bold">
                  {formatDate(activity.date || "") as ReactNode}
                </TableCell>
                <TableCell>{activity.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {user?.suspiciousActivity?.length === 0 && (
        <small>No suspicious activity reported</small>
      )}
    </section>
  );
}
