import { ReactNode, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "sonner";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/ui/alert-dialog";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { useCreateTransactionV1TransactionsPost, useGetAllTransactionsV1TransactionsGet } from "~/api/transactions/transactions.gen";
import { getUserDiamondsV1UsersUserIdDiamondsGetQueryOptions, getUserPointV1UsersUserIdPointsGetQueryOptions } from "~/api/users/users.gen";
import { parseFastAPIError } from "~/helpers/parse-errors";
import Button from "~/oldComponents/button/Button";
import { Form } from "~/oldComponents/form/Form";
import type { Field } from "~/oldComponents/form/types";
import { TransactionTable } from "~/pages/transaction";

interface UserTransactionsSectionProps {
  userId: string;
  points: number;
  diamondShards: number;
  refetchUser: () => void;
}

export function UserTransactionsSection({
  userId,
  points,
  diamondShards,
  refetchUser,
}: UserTransactionsSectionProps) {
  const maxDiamonds = Number((diamondShards / import.meta.env.VITE_REACT_DIAMOND_SHARDING).toFixed(2));

  const [enableTransactions, setEnableTransactions] = useState(false);
  const [formValues, setFormValues] = useState({
    pointsAmount: 0,
    diamonds: "",
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [transactionFilter, setTransactionFilter] = useState<{ sourcetype: string | undefined; offerid: string | undefined }>({
    sourcetype: undefined,
    offerid: undefined,
  });

  const [dialogType, setDialogType] = useState<'points' | 'diamonds' | null>(null);

  const { data: userPoint, refetch: refetchUserPoint } = useQuery({
    ...getUserPointV1UsersUserIdPointsGetQueryOptions(userId),
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return failureCount < 3;
    },
    throwOnError: (error) => {
      return error?.response?.status !== 404;
    },
  });

  const { data: userDiamonds, refetch: refetchUserDiamond } = useQuery({
    ...getUserDiamondsV1UsersUserIdDiamondsGetQueryOptions(userId),
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return failureCount < 3;
    },
    throwOnError: (error) => {
      return error?.response?.status !== 404;
    },
  });

  const {
    data: { items: transactions, total } = {
      items: [],
      total: 0,
    },
    isFetching: loadingTransactions,
    refetch: refetchTransactions,
  } = useGetAllTransactionsV1TransactionsGet(
    {
      user_id: userId,
      source_type: transactionFilter.sourcetype,
      offer_id: transactionFilter.offerid,
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
    },
    {
      query: {
        enabled: enableTransactions,
        initialData: undefined,
      },
    },
  );

  const handleSubmit = (userid: string | undefined, sourcetype: string | undefined, offerid: string | undefined) => {
    setTransactionFilter({
      sourcetype,
      offerid,
    });
  };

  const createTransactionMutation = useCreateTransactionV1TransactionsPost({
    mutation: {
      onSuccess: () => {
        setFormValues({ pointsAmount: 0, diamonds: "" });
        toast("Admin points transaction successfully created!");
        setTimeout(() => {
          refetchUser();
          refetchUserPoint();
          refetchUserDiamond();
          if (enableTransactions) {
            refetchTransactions();
          }
        }, 1000);
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onTransactionSubmit = async (event: React.FormEvent) => {
    event?.preventDefault();

    const { pointsAmount, diamonds } = formValues;
    const numberPointsAmount = Number(pointsAmount);
    const numberDiamonds = Number(diamonds) || 0;

    if ((numberPointsAmount === 0 && numberDiamonds === 0) || (isNaN(numberPointsAmount) && isNaN(numberDiamonds))) {
      return toast.warning("Please be generous", { description: "Giving 0 points and 0 diamond shards does not make sense." });
    }

    if (numberDiamonds > 10) {
      return toast.warning("Invalid diamond amount", {
        description: `You cannot give more than 10 diamonds. Attempted: ${numberDiamonds}`
      });
    }

    if (numberDiamonds < 0 && Math.abs(numberDiamonds) > maxDiamonds) {
      return toast.warning("Invalid diamond amount", {
        description: `You cannot remove more than ${Math.abs(maxDiamonds)} diamonds. Attempted: ${Math.abs(numberDiamonds)}`
      });
    }

    createTransactionMutation.mutateAsync({
      data: {
        amount: numberPointsAmount,
        diamond_shards: numberDiamonds !== 0 ? numberDiamonds * import.meta.env.VITE_REACT_DIAMOND_SHARDING : undefined,
        user_id: userId,
      },
    });
  };

  const handleRemoveAll = async (type: 'points' | 'diamonds') => {
    await createTransactionMutation.mutateAsync({
      data: {
        user_id: userId,
        amount: type === 'points' ? Math.ceil(-points) : 0,
        diamond_shards: type === 'diamonds' ? -diamondShards : 0,
      },
    });
    setDialogType(null);
  }

  const validateDiamondInput = (value: string) => {
    // Allow empty string and partial decimal inputs (0., 1.)
    if (value === "" || value === "0." || value === "-0." || value === "-") {
      return true;
    }

    const number = Number(value);
    // Check if it's a valid number with at most 2 decimal places
    if (Number.isNaN(number) || !value.match(/^-?\d*\.?\d{0,2}$/)) {
      return false;
    }

    return true;
  };

  const transactionFields: Field[] = [
    {
      name: "pointsAmount",
      label: `Points amount (${Math.floor(points)})`,
      helpText: "Provide user certain amount of points.",
      type: "number",
      value: formValues.pointsAmount,
      change: (e: React.ChangeEvent<HTMLInputElement>) => setFormValues(prev => ({ ...prev, pointsAmount: Number(e.target.value) })),
    },
    {
      name: "diamonds",
      label: `Diamonds (${maxDiamonds})`,
      helpText: "Provide user certain amount of diamonds to reward the user.",
      type: "number",
      value: formValues.diamonds,
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (validateDiamondInput(value)) {
          setFormValues(prev => ({ ...prev, diamonds: value }));
        }
      },
    },
  ];

  return (
    <>
      <section className="user-data_section">
        <h5 className="data_section-header">Create admin transaction</h5>
        <Form vertical name="admin-points" fields={transactionFields} onSubmit={onTransactionSubmit} submitText="Create transaction">
          <div className="flex flex-grow gap-2">
            <Button
              styleType="danger"
              type="button"
              onClick={() => setDialogType('points')}
            >
              Remove all points
            </Button>
            <Button
              styleType="danger"
              type="button"
              onClick={() => setDialogType('diamonds')}
            >
              Remove all diamonds
            </Button>
          </div>
        </Form>
      </section>

      {(userPoint && Object.keys(userPoint.points).length > 0) || (userDiamonds && Object.keys(userDiamonds.diamondShards).length > 0) ? (
        <div className="flex gap-4">
          {userPoint && Object.keys(userPoint.points).length > 0 && (
            <section className="flex-1 user-data_section">
              <h3 className="data_section-header">User total points</h3>

              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[300px]">Type</TableHead>
                    <TableHead>Points</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Object.entries(userPoint?.points)
                    .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
                    .map(([type, points]: [string, number]) => (
                      <TableRow key={type}>
                        <TableCell className="font-bold">{type}</TableCell>
                        <TableCell>{points}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </section>
          )}
          {userDiamonds && Object.keys(userDiamonds.diamondShards).length > 0 && (
            <section className="flex-1 user-data_section">
              <h3 className="data_section-header">User diamonds</h3>

              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[300px]">Type</TableHead>
                    <TableHead>Diamonds</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Object.entries(userDiamonds?.diamondShards)
                    .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
                    .map(([type, points]: [string, number]) => (
                      <TableRow key={type}>
                        <TableCell className="font-bold">{type}</TableCell>
                        <TableCell>{points / import.meta.env.VITE_REACT_DIAMOND_SHARDING}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </section>
          )}
        </div>
      ) : null}
      <section>
        {enableTransactions ? (
          <TransactionTable
            pagination={pagination}
            setPagination={setPagination}
            initUserId={userId}
            handleSubmit={handleSubmit}
            items={transactions}
            total={total}
            loading={loadingTransactions}
          />
        ) : (
          <Button onClick={() => setEnableTransactions(true)}>Show transactions</Button>
        )}
      </section>

      <AlertDialog open={dialogType !== null} onOpenChange={() => setDialogType(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {dialogType === 'points' ? 'Remove all points' : 'Remove all diamonds'}
            </AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to remove all {dialogType === 'points' ? `points (${Math.floor(points)})` : `diamonds (${maxDiamonds})`} from this user?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => dialogType && handleRemoveAll(dialogType)}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
