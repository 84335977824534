export function isValidUrl(urlString: string): boolean {
  try {
    const url = new URL(urlString.trim());
    // Ensure URL is absolute (has origin)
    if (!url.origin || url.origin === "null") {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  } catch {
    return false;
  }
}

export function ensureHttpProtocol(url: string): string {
  if (!url?.trim()) {
    throw new Error("URL cannot be empty");
  }

  const trimmedUrl = url.trim();

  // Handle URLs that might have malformed/partial protocols
  const protocolMatch = trimmedUrl.match(/^[a-zA-Z]+:\/?\/?/);
  if (protocolMatch) {
    const protocol = protocolMatch[0];
    if (protocol === "http://" || protocol === "https://") {
      return trimmedUrl;
    }
    // Remove any existing protocol
    const urlWithoutProtocol = trimmedUrl.slice(protocol.length);
    return `https://${urlWithoutProtocol}`;
  }
  return `https://${trimmedUrl}`;
}
