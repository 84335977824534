import { redirect } from "@tanstack/react-router";
import Axios, { AxiosError, AxiosRequestConfig } from "axios";

import { refreshTokenCall } from "./auth";

import { getBackendUrl } from "~/helpers/backend";
import { useAuthStore } from "~/store/authStore";

export const AXIOS_INSTANCE = Axios.create({ baseURL: getBackendUrl() });

// Add response interceptor
AXIOS_INSTANCE.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If error is not 401 or request already retried, reject
    if (error.response?.status !== 401 || originalRequest._retry) {
      return Promise.reject(error);
    }

    // Mark this request as retried
    originalRequest._retry = true;

    const state = useAuthStore.getState();

    if (!state.user?.user_id || !state.refreshToken) {
      state.logout();
      redirect({ to: "/login" });
      return Promise.reject(error);
    }

    try {
      const response = await refreshTokenCall({
        refreshToken: state.refreshToken,
        userId: state.user?.user_id as string,
      });

      if (response.status !== 200) {
        throw new Error("Failed to refresh token");
      }
      // Update tokens in store
      state.setTokens({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      });
      state.setUser(response.data.user);

      // Update Authorization header
      AXIOS_INSTANCE.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;

      // Retry original request with new token
      originalRequest.headers["Authorization"] = `Bearer ${response.data.access_token}`;

      return AXIOS_INSTANCE(originalRequest);
    } catch (refreshError) {
      console.error("Failed to refresh token:", refreshError);
      // Clear auth state and redirect to login
      state.logout();
      return Promise.reject(error);
    }
  },
);

// add a second `options` argument here if you want to pass extra options to each generated query
export const axiosInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-expect-error - this is a hack to make sure the promise is cancelable
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<e> = AxiosError<e>;
export type BodyType<BodyData> = BodyData;
