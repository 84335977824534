import React, { memo, ReactNode } from "react";
import { useEffect, useState } from "react";
import { ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { ConfirmDialog } from "@/confirm_dialog";
import { Icon } from "@/icon/icon";
import { Badge } from "@/ui/badge";
import { Button } from "@/ui/button";
import { Checkbox } from "@/ui/checkbox";
import { Skeleton } from "@/ui/skeleton";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import RewardOrderPopup from "../RewardOrder";

import { BusinessRuleCreateSheet } from "./BusinessCreateSheet";

import { useDeleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete } from "~/api/marketplace/marketplace.gen";
import { BusinessRuleType, HighlightedCollectionType } from "~/api/model";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { cn } from "~/lib/utils";
import { BusinessRuleWithStatusAndCount, useMarketplace } from "~/providers/marketplace";

const BusinessRulesPart = memo(() => {
  const { businessRules, refetchMarketplace } = useMarketplace();

  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedRuleForDelete, setSelectedRuleForDelete] = useState<BusinessRuleWithStatusAndCount | undefined>(undefined);
  const [selectedRule, setSelectedRule] = useState<BusinessRuleWithStatusAndCount | null>(null);
  const [mappedBusinessRules, setMappedBusinessRules] = useState<BusinessRuleWithStatusAndCount[]>([]);

  const deleteTagMutation = useDeleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete({
    mutation: {
      onSuccess: () => {
        setLoading(true);
        refetchMarketplace();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  useEffect(() => {
    setMappedBusinessRules(businessRules);
    setLoading(false);
  }, [businessRules]);

  const deleteTagFn = () => {
    if (selectedRuleForDelete && selectedRuleForDelete._id) {
      deleteTagMutation.mutate({
        ruleId: selectedRuleForDelete._id,
      });
    }
  };

  const businessRulesColumns: ColumnDef<BusinessRuleWithStatusAndCount>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />,
    },
    {
      accessorKey: "name",
      header: "Tag Name",
    },
    {
      accessorKey: "type",
      header: "Rule Type",
    },
    {
      id: "rules",
      header: "Rules",
      cell: ({ row }) => {
        if (row.original.type === BusinessRuleType.All) {
          return;
        }
        if (row.original.type === BusinessRuleType.MostSold) {
          return <div>Amount of rewards: {row.original.amountOfRewards}</div>;
        }
        if (row.original.type === BusinessRuleType.NewUserSpecial) {
          return (
            <div>
              Shown to users created between: {row.original.startDateFilter} - {row.original.endDateFilter}
            </div>
          );
        }
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const status: string[] = row.getValue("status");
        return (
          <div className="flex gap-1.5">
            {status.map((status, index) => (
              <Badge key={`tag-status-${index}`} variant={status === "recommended" ? "default" : "secondary"}>
                {status}
              </Badge>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "publishedCount",
      header: "Published rewards",
    },
    {
      accessorKey: "unpublishedCount",
      header: "unpublished rewards",
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <Button
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRuleForDelete(row.original);
                setOpenConfirmDialog(true);
              }}
            >
              <Icon icon="Trash" className="text-red-500" />
            </Button>
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data: mappedBusinessRules,
    columns: businessRulesColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      columnVisibility: {
        select: false,
      },
    },
  });

  const openDialogFn = (businessRule: BusinessRuleWithStatusAndCount) => {
    setSelectedRule(businessRule);
    setOpenDialog(true);
  };

  const renderTableRows = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={businessRulesColumns.length} className="h-24 text-center">
            <Skeleton className="w-100 h-20" />
          </TableCell>
        </TableRow>
      );
    }

    if (table.getRowModel().rows?.length) {
      return table.getRowModel().rows.map((row) => (
        <TableRow key={row.id} data-state={row.getIsSelected() && "selected"} onClick={() => openDialogFn(row.original)}>
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
          ))}
        </TableRow>
      ));
    }

    return (
      <TableRow>
        <TableCell colSpan={businessRulesColumns.length} className="h-24 text-center">
          No results found.
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <h3>Business Rules Collection</h3>
      <div className="w-full">
        <div className="rounded-md">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className={cn({ "text-right": header.column.columnDef.id === "actions" })}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.columnDef.id === "actions" ? (
                          <BusinessRuleCreateSheet setLoading={setLoading} key={businessRules.length || 0} />
                        ) : null}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              <TableRow className="border-0">
                <TableCell className="p-3" />
              </TableRow>
              {renderTableRows()}
            </TableBody>
          </Table>
        </div>
        <ConfirmDialog
          openDialog={openConfirmDialog}
          setOpenDialog={setOpenConfirmDialog}
          title="Are you sure you want to delete this business rule?"
          confirmAction={deleteTagFn}
          confirmText="Delete"
        />
        {selectedRule && openDialog && (
          <RewardOrderPopup
            parent={selectedRule}
            parentType={HighlightedCollectionType.BusinessRules}
            refetch={refetchMarketplace}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            showDelete={false}
          />
        )}
      </div>
    </>
  );
});

BusinessRulesPart.displayName = "BusinessRulesPart";

export default BusinessRulesPart;
