import React, { memo } from "react";
import { useEffect, useMemo, useState } from "react";
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

import { Icon } from "@/icon/icon";
import { Badge } from "@/ui/badge";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import RewardOrderPopup from "../RewardOrder";

import { HighlightedCollectionType } from "~/api/model";
import { CategoryWithStatusAndRewardCount, useMarketplace } from "~/providers/marketplace";
import { useMarketStore } from "~/store/market";

const CategoryCollectionsPart = memo(() => {
  const market = useMarketStore((state) => state.market);
  const { categories, refetchMarketplace, marketplaceLoading } = useMarketplace();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<CategoryWithStatusAndRewardCount | null>(null);
  const [mappedCategories, setMappedCategories] = useState<CategoryWithStatusAndRewardCount[]>([]);

  const categoriesColumns = useMemo<ColumnDef<CategoryWithStatusAndRewardCount>[]>(
    () => [
      {
        accessorKey: "icon",
        header: "",
        cell: ({ row }) => {
          return (
            <div className="inline-block rounded-sm bg-categoryIcon p-1.5 text-categoryIcon-foreground">
              <Icon icon={row.getValue("icon")} className="h-6 w-6" />
            </div>
          );
        },
      },
      {
        accessorKey: "order",
        header: "Order",
      },
      {
        accessorKey: "name",
        header: "Category Name",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => {
          const status: string[] = row.getValue("status");
          return (
            <div className="flex gap-1.5">
              {status.map((status: string, index: number) => (
                <Badge key={`cc-status-${index}`} variant={status === "recommended" ? "default" : "secondary"}>
                  {status}
                </Badge>
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "publishedCount",
        header: "Published rewards",
      },
      {
        accessorKey: "unpublishedCount",
        header: "Unpublished rewards",
      },
    ],
    [],
  );

  const table = useReactTable({
    data: mappedCategories,
    columns: categoriesColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "order",
          desc: false,
        },
      ],
      columnVisibility: {
        order: false,
      },
    },
  });

  useEffect(() => {
    if (!market) {
      console.warn("No market selected. Categories cannot be loaded.");
      return;
    }

    if (categories) {
      const mappedCategories: CategoryWithStatusAndRewardCount[] = categories;
      setMappedCategories(mappedCategories);
    }
  }, [categories, market]);

  const openDialogFn = (category: CategoryWithStatusAndRewardCount) => {
    setSelectedCategory(category);
    setOpenDialog(true);
  };

  if (!market) {
    return (
      <div className="flex flex-col items-center justify-center p-8 text-center">
        <p className="text-lg font-medium text-gray-600">Please select a market first</p>
        <p className="text-sm text-gray-500">Categories will be displayed once a market is selected</p>
      </div>
    );
  }

  if (marketplaceLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-8">
        <p className="text-lg font-medium text-gray-600">Loading categories...</p>
      </div>
    );
  }

  if (!categories || categories.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center p-8 text-center">
        <p className="text-lg font-medium text-gray-600">No categories found</p>
        <p className="text-sm text-gray-500">Try selecting a different market or check if categories are configured for this market</p>
      </div>
    );
  }

  return (
    <>
      <h3>Categories</h3>
      <div className="w-full">
        <div className="rounded-md">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              <TableRow className="border-0">
                <TableCell className="p-3" />
              </TableRow>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} data-state={row.getIsSelected() && "selected"} onClick={() => openDialogFn(row.original)}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={categoriesColumns.length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {selectedCategory && openDialog && (
          <RewardOrderPopup
            parent={selectedCategory}
            parentType={HighlightedCollectionType.Category}
            refetch={refetchMarketplace}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            showDelete={false}
          />
        )}
      </div>
    </>
  );
});

CategoryCollectionsPart.displayName = "CategoryCollectionsPart";

export default CategoryCollectionsPart;
