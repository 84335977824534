import React, { ReactNode } from "react";
import { useState } from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";
import { ImageType, StepCampaignModelOutput } from "~/api/model";
import { useCreateStepCampaignV1StepCampaignsPost } from "~/api/step-campaigns/step-campaigns.gen";
import { parseMarketCountries } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/step-campaigns/create")({
  component: () => <CreateStepCampaignPage />,
});

function CreateStepCampaignPage() {
  const navigate = useNavigate();

  const [stepCampaign, setStepCampaign] = useState<StepCampaignModelOutput>({
    country: "",
    title: "",
    subTitle: "",
    stepsDivider: 2500,
    reward: 0,
    acceptableStepsPerMin: 1000,
    publishDate: null,
    unPublishDate: null,
    companyLogoUrl: "",
    backgroundImageUrl: "",
  });

  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      initialData: [],
    },
  });

  const createStepCampaignMutation = useCreateStepCampaignV1StepCampaignsPost({
    mutation: {
      onSuccess: () => {
        toast("Step campaign was successfully created!");
        navigate({
          to: `/step-campaigns?status=Unpublished&country=${stepCampaign.country}`,
        });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    if (!stepCampaign.companyLogoUrl || !stepCampaign.backgroundImageUrl) {
      return toast.error("Error", {
        description: "Please upload both a company logo and background image before saving.",
      });
    }

    await createStepCampaignMutation.mutateAsync({ data: stepCampaign });
  };

  const page: PageConfig = {
    title: "Create step campaign",
    description: "Use this elements, if you want to show some hints or additional information",
  };

  const form: FormConfig = {
    fields: [
      {
        name: "country",
        label: "Country:",
        type: "search-select",
        helpText: "Specify campaign country.",
        change: (data: string) => setStepCampaign((prev) => ({ ...prev, country: data })),
        value: stepCampaign.country,
        options: parseMarketCountries(countries),
        empty: " ",
        row: true,
        required: true,
      },
      {
        name: "title",
        label: "Title:",
        type: "text",
        helpText: "Campaign title.",
        value: stepCampaign.title,
        change: (event: React.ChangeEvent<HTMLInputElement>) => setStepCampaign((prev) => ({ ...prev, title: event.target.value })),
        row: true,
        required: true,
      },
      {
        name: "subtitle",
        label: "SubTitle:",
        type: "text",
        helpText: "Campaign sub-title.",
        value: stepCampaign.subTitle,
        change: (event: React.ChangeEvent<HTMLInputElement>) =>
          setStepCampaign((prev) => ({
            ...prev,
            subTitle: event.target.value,
          })),
        row: true,
        required: true,
      },
      {
        name: "stepsDivider",
        label: "Steps divider:",
        type: "number",
        helpText: "How many steps user need to get to be rewarded.",
        value: stepCampaign.stepsDivider,
        change: (event: React.ChangeEvent<HTMLInputElement>) =>
          setStepCampaign((prev) => ({
            ...prev,
            stepsDivider: +event.target.value,
          })),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "reward",
        label: "Reward:",
        type: "number",
        helpText: "Reward points amount.",
        value: stepCampaign.reward,
        change: (event: React.ChangeEvent<HTMLInputElement>) => setStepCampaign((prev) => ({ ...prev, reward: +event.target.value })),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "stepsPerMinute",
        label: "Steps per minute:",
        type: "number",
        helpText: "Acceptable steps per minute.",
        value: stepCampaign.acceptableStepsPerMin,
        change: (event: React.ChangeEvent<HTMLInputElement>) =>
          setStepCampaign((prev) => ({
            ...prev,
            acceptableStepsPerMin: +event.target.value,
          })),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "publishDate",
        label: "Publish date:",
        helpText: "Campaign publish date.",
        type: "datetime-picker",
        value: stepCampaign.publishDate,
        change: (date: Date) =>
          setStepCampaign((prev) => ({
            ...prev,
            publishDate: date.toISOString(),
          })),
        custom: true,
      },
      {
        name: "unpublishDate",
        label: "Unpublish date:",
        helpText: "Campaign unpublish date.",
        type: "datetime-picker",
        value: stepCampaign.unPublishDate,
        change: (date: Date) =>
          setStepCampaign((prev) => ({
            ...prev,
            unPublishDate: date.toISOString(),
          })),
        custom: true,
      },
      {
        name: "logo",
        label: "Logo:",
        helpText: "Campaign logo.",
        type: "image-cropper",
        imageType: ImageType.step_campaign_logo,
        value: stepCampaign.companyLogoUrl,
        change: (imageUrl: string) => setStepCampaign((prev) => ({ ...prev, companyLogoUrl: imageUrl })),
        row: true,
        custom: true,
      },
    ],
    onSubmit,
    submitText: "Save",
    name: "create-step-campaign-link",
  };

  return <CreateEditPage loading={false} page={page} form={form} />;
}
