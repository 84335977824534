import { create } from "zustand";
import { persist } from "zustand/middleware";

import { MarketState } from "~/types/market";

interface MarketStore extends MarketState {
  setMarket: (market: string) => void;
}

const initialState: MarketState = {
  market: "",
};

export const useMarketStore = create<MarketStore>()(
  persist(
    (set) => ({
      ...initialState,

      setMarket: (market) => {
        set({ market });
      },
    }),
    {
      name: "market",
      partialize: (state) => ({
        market: state.market,
      }),
    },
  ),
);
