import React, { cloneElement, ReactNode } from "react";

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";

interface DisabledComponentTooltipProps {
  children: ReactNode;
  tooltipText?: string;
  disabled: boolean;
}

const DisabledComponentTooltip: React.FC<DisabledComponentTooltipProps> = ({ children, tooltipText, disabled }) => {
  return disabled ? (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          {/* the children must be inside an active div since tooltip works with hover feature
          of active elements, when the children is disabled and hover doesn't work. The tooltip is connected to div instead of children. */}
          <div>{React.isValidElement(children) ? cloneElement(children as React.ReactElement, { disabled }) : children}</div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{tooltipText}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    children
  );
};

export default DisabledComponentTooltip;
