import React, { useState } from "react";

import { Icon } from "../icon/icon";

export const DeeplinkExplanation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="text-xs px-4 pb-4">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 text-sm font-medium hover:text-primary"
      >
        <Icon icon={isOpen ? "ArrowDown" : "ArrowRight"} />
        Deeplink Explanation
      </button>

      <div className={`mt-2 overflow-hidden transition-all duration-200 ${isOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"}`}>
        For partner apps we can have multiple types of deeplinks. All have their advantages and disadvantages. Below we list the different options with a brief description of each. In the previews everything after the pipe | is what we call the &quot;deeplink parameters&quot;. They should not be provided by the partners.
        <ul className="list-disc pl-4 pt-4">
          <li className="mb-4">
            We can get a regular deeplink that we attach parameters to.<br />
            <b className="underline">https://deeplink.stepler.io/abc | ?user_id=123&partner_key=abc&language=se&reward_type=diamonds</b><br />
            <span className="text-[10px]">here the attached parameters might be stripped when opening the link when ATT is enabled.</span>
          </li>
          <li className="mb-4">
            We can get a regular deeplink that already has parameters attached. We will attach our own parameters to the link.<br />
            <b className="underline">https://deeplink.stepler.io/abc?something=123 | &user_id=123&partner_key=abc&language=se&reward_type=diamonds</b><br />
            <span className="text-[10px]">here the attached parameters might be stripped when opening the link when ATT is enabled.</span>
          </li>
          <li className="mb-4">
            We can get a deeplink that contains [replace] somewhere in the deeplink. We will replace this with our needed parameters that are also fully URL encoded.<br />
            <b className="underline">https://deeplink.stepler.io/?link=https://app.steplerapp.com/stepler?[replace]&other_param=123</b><br />
            <span className="text-[10px]">This is more complex than the previous options but it makes sure that the needed parameters are present even when ATT is enabled</span>
          </li>
        </ul>
      </div>
    </div>
  );
};
