import { memo } from "react";
import { createFileRoute, Navigate, Outlet } from "@tanstack/react-router";

import { AppSidebar } from "@/app-sidebar";
import Header from "@/nav/Header";
import { SidebarProvider } from "@/ui/sidebar";

import "../styles/layout.scss";

import { useAuthStore } from "~/store/authStore";

const AuthLayout = () => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return (
    <SidebarProvider>
      <AppSidebar />
      <MainContent />
    </SidebarProvider>
  );
};

export const Route = createFileRoute("/_auth")({
  component: AuthLayout,
});

const MainContent = memo(() => (
  <main className="w-pageWidth grow .group[data-collapsible='icon']:w-pageWidthIcon">
    <Header />
    <div className="overflow-auto w-full h-pageHeight">
      <Outlet />
    </div>
  </main>
));

MainContent.displayName = "MainContent";
