/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type OfferType = typeof OfferType[keyof typeof OfferType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferType = {
  Regular: 'Regular',
  Bonus: 'Bonus',
  NewUserSpecialOffer: 'NewUserSpecialOffer',
  Diamond: 'Diamond',
  DiamondAndPoints: 'DiamondAndPoints',
} as const;
