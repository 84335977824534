/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  BonusOfferInput,
  Error400Response,
  GetOffersV1OffersGetParams,
  HTTPValidationError,
  OfferInput,
  OfferModel,
  OfferWithCouponCount
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Create offer
 * @summary Create Offer
 */
export const createOfferV1OffersPost = (
    offerInput: BodyType<OfferInput>,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OfferModel>(
      {url: `/v1/offers/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: offerInput, signal
    },
      options);
    }
  


export const getCreateOfferV1OffersPostMutationOptions = <TData = Awaited<ReturnType<typeof createOfferV1OffersPost>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<OfferInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['createOfferV1OffersPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOfferV1OffersPost>>, {data: BodyType<OfferInput>}> = (props) => {
          const {data} = props ?? {};

          return  createOfferV1OffersPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: BodyType<OfferInput>}, TContext>}

    export type CreateOfferV1OffersPostMutationResult = NonNullable<Awaited<ReturnType<typeof createOfferV1OffersPost>>>
    export type CreateOfferV1OffersPostMutationBody = BodyType<OfferInput>
    export type CreateOfferV1OffersPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Offer
 */
export const useCreateOfferV1OffersPost = <TData = Awaited<ReturnType<typeof createOfferV1OffersPost>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<OfferInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {data: BodyType<OfferInput>},
        TContext
      > => {

      const mutationOptions = getCreateOfferV1OffersPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get offers by filters
 * @summary Get Offers
 */
export const getOffersV1OffersGet = (
    params?: GetOffersV1OffersGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OfferWithCouponCount[]>(
      {url: `/v1/offers/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOffersV1OffersGetQueryKey = (params?: GetOffersV1OffersGetParams,) => {
    return [`/v1/offers/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOffersV1OffersGetQueryOptions = <TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(params?: GetOffersV1OffersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOffersV1OffersGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOffersV1OffersGet>>> = ({ signal }) => getOffersV1OffersGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetOffersV1OffersGetQueryResult = NonNullable<Awaited<ReturnType<typeof getOffersV1OffersGet>>>
export type GetOffersV1OffersGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetOffersV1OffersGet<TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params: undefined |  GetOffersV1OffersGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getOffersV1OffersGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetOffersV1OffersGet<TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetOffersV1OffersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getOffersV1OffersGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetOffersV1OffersGet<TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetOffersV1OffersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Offers
 */

export function useGetOffersV1OffersGet<TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetOffersV1OffersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetOffersV1OffersGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOffersV1OffersGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(params?: GetOffersV1OffersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOffersV1OffersGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOffersV1OffersGet>>> = ({ signal }) => getOffersV1OffersGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetOffersV1OffersGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getOffersV1OffersGet>>>
export type GetOffersV1OffersGetSuspenseQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetOffersV1OffersGetSuspense<TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params: undefined |  GetOffersV1OffersGetParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetOffersV1OffersGetSuspense<TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetOffersV1OffersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetOffersV1OffersGetSuspense<TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetOffersV1OffersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Offers
 */

export function useGetOffersV1OffersGetSuspense<TData = Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetOffersV1OffersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOffersV1OffersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetOffersV1OffersGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Delete offers by ids
 * @summary Delete Offers By Ids
 */
export const deleteOffersByIdsV1OffersDelete = (
    deleteOffersByIdsV1OffersDeleteBody: BodyType<string[]>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/offers/`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: deleteOffersByIdsV1OffersDeleteBody
    },
      options);
    }
  


export const getDeleteOffersByIdsV1OffersDeleteMutationOptions = <TData = Awaited<ReturnType<typeof deleteOffersByIdsV1OffersDelete>>, TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['deleteOffersByIdsV1OffersDelete'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOffersByIdsV1OffersDelete>>, {data: BodyType<string[]>}> = (props) => {
          const {data} = props ?? {};

          return  deleteOffersByIdsV1OffersDelete(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: BodyType<string[]>}, TContext>}

    export type DeleteOffersByIdsV1OffersDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOffersByIdsV1OffersDelete>>>
    export type DeleteOffersByIdsV1OffersDeleteMutationBody = BodyType<string[]>
    export type DeleteOffersByIdsV1OffersDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Offers By Ids
 */
export const useDeleteOffersByIdsV1OffersDelete = <TData = Awaited<ReturnType<typeof deleteOffersByIdsV1OffersDelete>>, TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {data: BodyType<string[]>},
        TContext
      > => {

      const mutationOptions = getDeleteOffersByIdsV1OffersDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update offer
 * @summary Update Offer
 */
export const updateOfferV1OffersOfferIdPut = (
    offerId: string,
    offerInput: BodyType<OfferInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<OfferModel>(
      {url: `/v1/offers/${offerId}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: offerInput
    },
      options);
    }
  


export const getUpdateOfferV1OffersOfferIdPutMutationOptions = <TData = Awaited<ReturnType<typeof updateOfferV1OffersOfferIdPut>>, TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{offerId: string;data: BodyType<OfferInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['updateOfferV1OffersOfferIdPut'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOfferV1OffersOfferIdPut>>, {offerId: string;data: BodyType<OfferInput>}> = (props) => {
          const {offerId,data} = props ?? {};

          return  updateOfferV1OffersOfferIdPut(offerId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{offerId: string;data: BodyType<OfferInput>}, TContext>}

    export type UpdateOfferV1OffersOfferIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateOfferV1OffersOfferIdPut>>>
    export type UpdateOfferV1OffersOfferIdPutMutationBody = BodyType<OfferInput>
    export type UpdateOfferV1OffersOfferIdPutMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Offer
 */
export const useUpdateOfferV1OffersOfferIdPut = <TData = Awaited<ReturnType<typeof updateOfferV1OffersOfferIdPut>>, TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{offerId: string;data: BodyType<OfferInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {offerId: string;data: BodyType<OfferInput>},
        TContext
      > => {

      const mutationOptions = getUpdateOfferV1OffersOfferIdPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get offer by id
 * @summary Get Offer By Id
 */
export const getOfferByIdV1OffersOfferIdGet = (
    offerId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OfferModel>(
      {url: `/v1/offers/${offerId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOfferByIdV1OffersOfferIdGetQueryKey = (offerId: string,) => {
    return [`/v1/offers/${offerId}/`] as const;
    }

    
export const getGetOfferByIdV1OffersOfferIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(offerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferByIdV1OffersOfferIdGetQueryKey(offerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>> = ({ signal }) => getOfferByIdV1OffersOfferIdGet(offerId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(offerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetOfferByIdV1OffersOfferIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>>
export type GetOfferByIdV1OffersOfferIdGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetOfferByIdV1OffersOfferIdGet<TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 offerId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetOfferByIdV1OffersOfferIdGet<TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 offerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetOfferByIdV1OffersOfferIdGet<TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 offerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Offer By Id
 */

export function useGetOfferByIdV1OffersOfferIdGet<TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 offerId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetOfferByIdV1OffersOfferIdGetQueryOptions(offerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOfferByIdV1OffersOfferIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(offerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferByIdV1OffersOfferIdGetQueryKey(offerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>> = ({ signal }) => getOfferByIdV1OffersOfferIdGet(offerId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetOfferByIdV1OffersOfferIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>>
export type GetOfferByIdV1OffersOfferIdGetSuspenseQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetOfferByIdV1OffersOfferIdGetSuspense<TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 offerId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetOfferByIdV1OffersOfferIdGetSuspense<TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 offerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetOfferByIdV1OffersOfferIdGetSuspense<TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 offerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Offer By Id
 */

export function useGetOfferByIdV1OffersOfferIdGetSuspense<TData = Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 offerId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferByIdV1OffersOfferIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetOfferByIdV1OffersOfferIdGetSuspenseQueryOptions(offerId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Delete offer by id
 * @summary Delete Offer By Id
 */
export const deleteOfferByIdV1OffersOfferIdDelete = (
    offerId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/offers/${offerId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteOfferByIdV1OffersOfferIdDeleteMutationOptions = <TData = Awaited<ReturnType<typeof deleteOfferByIdV1OffersOfferIdDelete>>, TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{offerId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['deleteOfferByIdV1OffersOfferIdDelete'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOfferByIdV1OffersOfferIdDelete>>, {offerId: string}> = (props) => {
          const {offerId} = props ?? {};

          return  deleteOfferByIdV1OffersOfferIdDelete(offerId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{offerId: string}, TContext>}

    export type DeleteOfferByIdV1OffersOfferIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOfferByIdV1OffersOfferIdDelete>>>
    
    export type DeleteOfferByIdV1OffersOfferIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Offer By Id
 */
export const useDeleteOfferByIdV1OffersOfferIdDelete = <TData = Awaited<ReturnType<typeof deleteOfferByIdV1OffersOfferIdDelete>>, TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{offerId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {offerId: string},
        TContext
      > => {

      const mutationOptions = getDeleteOfferByIdV1OffersOfferIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update offer deeplink
 * @summary Update Offer Deeplink
 */
export const updateOfferDeeplinkV1OffersOfferIdPost = (
    offerId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OfferModel>(
      {url: `/v1/offers/${offerId}/`, method: 'POST', signal
    },
      options);
    }
  


export const getUpdateOfferDeeplinkV1OffersOfferIdPostMutationOptions = <TData = Awaited<ReturnType<typeof updateOfferDeeplinkV1OffersOfferIdPost>>, TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{offerId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['updateOfferDeeplinkV1OffersOfferIdPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOfferDeeplinkV1OffersOfferIdPost>>, {offerId: string}> = (props) => {
          const {offerId} = props ?? {};

          return  updateOfferDeeplinkV1OffersOfferIdPost(offerId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{offerId: string}, TContext>}

    export type UpdateOfferDeeplinkV1OffersOfferIdPostMutationResult = NonNullable<Awaited<ReturnType<typeof updateOfferDeeplinkV1OffersOfferIdPost>>>
    
    export type UpdateOfferDeeplinkV1OffersOfferIdPostMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Offer Deeplink
 */
export const useUpdateOfferDeeplinkV1OffersOfferIdPost = <TData = Awaited<ReturnType<typeof updateOfferDeeplinkV1OffersOfferIdPost>>, TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{offerId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {offerId: string},
        TContext
      > => {

      const mutationOptions = getUpdateOfferDeeplinkV1OffersOfferIdPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create bonus offer
 * @summary Create Bonus Offer
 */
export const createBonusOfferV1OffersBonusOfferPost = (
    bonusOfferInput: BodyType<BonusOfferInput>,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OfferModel>(
      {url: `/v1/offers/bonus-offer/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bonusOfferInput, signal
    },
      options);
    }
  


export const getCreateBonusOfferV1OffersBonusOfferPostMutationOptions = <TData = Awaited<ReturnType<typeof createBonusOfferV1OffersBonusOfferPost>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<BonusOfferInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['createBonusOfferV1OffersBonusOfferPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBonusOfferV1OffersBonusOfferPost>>, {data: BodyType<BonusOfferInput>}> = (props) => {
          const {data} = props ?? {};

          return  createBonusOfferV1OffersBonusOfferPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: BodyType<BonusOfferInput>}, TContext>}

    export type CreateBonusOfferV1OffersBonusOfferPostMutationResult = NonNullable<Awaited<ReturnType<typeof createBonusOfferV1OffersBonusOfferPost>>>
    export type CreateBonusOfferV1OffersBonusOfferPostMutationBody = BodyType<BonusOfferInput>
    export type CreateBonusOfferV1OffersBonusOfferPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Bonus Offer
 */
export const useCreateBonusOfferV1OffersBonusOfferPost = <TData = Awaited<ReturnType<typeof createBonusOfferV1OffersBonusOfferPost>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<BonusOfferInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {data: BodyType<BonusOfferInput>},
        TContext
      > => {

      const mutationOptions = getCreateBonusOfferV1OffersBonusOfferPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update bonus offer
 * @summary Update Bonus Offer
 */
export const updateBonusOfferV1OffersBonusOfferOfferIdPut = (
    offerId: string,
    bonusOfferInput: BodyType<BonusOfferInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<OfferModel>(
      {url: `/v1/offers/bonus-offer/${offerId}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bonusOfferInput
    },
      options);
    }
  


export const getUpdateBonusOfferV1OffersBonusOfferOfferIdPutMutationOptions = <TData = Awaited<ReturnType<typeof updateBonusOfferV1OffersBonusOfferOfferIdPut>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{offerId: string;data: BodyType<BonusOfferInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['updateBonusOfferV1OffersBonusOfferOfferIdPut'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBonusOfferV1OffersBonusOfferOfferIdPut>>, {offerId: string;data: BodyType<BonusOfferInput>}> = (props) => {
          const {offerId,data} = props ?? {};

          return  updateBonusOfferV1OffersBonusOfferOfferIdPut(offerId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{offerId: string;data: BodyType<BonusOfferInput>}, TContext>}

    export type UpdateBonusOfferV1OffersBonusOfferOfferIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateBonusOfferV1OffersBonusOfferOfferIdPut>>>
    export type UpdateBonusOfferV1OffersBonusOfferOfferIdPutMutationBody = BodyType<BonusOfferInput>
    export type UpdateBonusOfferV1OffersBonusOfferOfferIdPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Bonus Offer
 */
export const useUpdateBonusOfferV1OffersBonusOfferOfferIdPut = <TData = Awaited<ReturnType<typeof updateBonusOfferV1OffersBonusOfferOfferIdPut>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{offerId: string;data: BodyType<BonusOfferInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {offerId: string;data: BodyType<BonusOfferInput>},
        TContext
      > => {

      const mutationOptions = getUpdateBonusOfferV1OffersBonusOfferOfferIdPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    