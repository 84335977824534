import { Table, TableBody, TableCell, TableRow } from "~/components/ui/table";

interface PersonalDataSectionProps {
  _id?: string | null;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  walletBalance?: number;
  spentPoints?: number | null;
  diamondShards?: number;
  spentDiamondShards?: number;
  phonenumber?: string | null;
  appleId?: string | null;
  fbId?: string | null;
  googleId?: string | null;
  platform?: string | null;
  language?: string | null;
  totalSteps?: number | null;
  isPremium?: boolean | null;
}

export function PersonalDataSection({
  _id,
  status,
  createdAt,
  updatedAt,
  walletBalance,
  spentPoints,
  diamondShards,
  spentDiamondShards,
  phonenumber,
  appleId,
  fbId,
  googleId,
  platform,
  language,
  totalSteps,
  isPremium,
}: PersonalDataSectionProps) {
  return (
    <section className="user-data_section">
      <h5 className="data_section-header">Personal data</h5>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="w-[300px] font-bold">User ID:</TableCell>
            <TableCell>{_id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Status:</TableCell>
            <TableCell>{status}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Created at:</TableCell>
            <TableCell>{createdAt ? new Date(createdAt).toDateString() : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Last updated:</TableCell>
            <TableCell>{updatedAt ? new Date(updatedAt).toDateString() : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Point balance:</TableCell>
            <TableCell>{walletBalance ? Math.floor(walletBalance) : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Spent points:</TableCell>
            <TableCell>{spentPoints ? Math.floor(spentPoints) : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Diamond balance:</TableCell>
            <TableCell>{diamondShards ? diamondShards / import.meta.env.VITE_REACT_DIAMOND_SHARDING : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Spent diamonds:</TableCell>
            <TableCell>{spentDiamondShards ? spentDiamondShards / import.meta.env.VITE_REACT_DIAMOND_SHARDING : "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Phone:</TableCell>
            <TableCell>{phonenumber || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Apple ID:</TableCell>
            <TableCell>{appleId || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Facebook ID:</TableCell>
            <TableCell>{fbId || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Google ID:</TableCell>
            <TableCell>{googleId || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Last used platform:</TableCell>
            <TableCell>{platform || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Language:</TableCell>
            <TableCell>{language || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Total steps:</TableCell>
            <TableCell>{totalSteps}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="w-[300px] font-bold">Is Premium:</TableCell>
            <TableCell>{isPremium ? "Yes" : "No"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </section>
  );
}
