import { memo } from "react";
import type { Cell, ColumnDef, Row, Table } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";

import { Button } from "@/ui/button";
import { Table as UITable, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { OfferWithCategoriesAndTags } from "~/providers/marketplace";

interface RewardsTableHeaderProps {
  table: Table<OfferWithCategoriesAndTags>;
}

export const RewardsTableHeader = memo(({ table }: RewardsTableHeaderProps) => (
  <TableHeader>
    {table.getHeaderGroups().map((headerGroup) => (
      <TableRow key={headerGroup.id} className="">
        {headerGroup.headers.map((header) => (
          <TableHead key={header.id}>
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </TableHead>
        ))}
      </TableRow>
    ))}
  </TableHeader>
));

RewardsTableHeader.displayName = "RewardsTableHeader";

interface TableCellProps {
  cell: Cell<OfferWithCategoriesAndTags, unknown>;
}

const areCellsEqual = (
  prevProps: TableCellProps,
  nextProps: TableCellProps
) => {
  if (!prevProps?.cell || !nextProps?.cell) return false;
  
  const prevCell = prevProps.cell;
  const nextCell = nextProps.cell;
  
  if (!prevCell.column?.columnDef?.cell || !nextCell.column?.columnDef?.cell) return false;
  
  try {
    return (
      prevCell.id === nextCell.id &&
      flexRender(prevCell.column.columnDef.cell, prevCell.getContext()) === 
      flexRender(nextCell.column.columnDef.cell, nextCell.getContext())
    );
  } catch (e) {
    return false;
  }
};

const MemoizedCell = memo(({ cell }: TableCellProps) => (
  <TableCell key={cell.id}>
    {flexRender(cell.column.columnDef.cell, cell.getContext())}
  </TableCell>
), areCellsEqual);

MemoizedCell.displayName = "MemoizedCell";

interface TableRowProps {
  row: Row<OfferWithCategoriesAndTags>;
}

const areRowsEqual = (
  prevProps: TableRowProps,
  nextProps: TableRowProps
) => {
  if (!prevProps?.row || !nextProps?.row) return false;
  
  const prevRow = prevProps.row;
  const nextRow = nextProps.row;
  
  if (!prevRow.original || !nextRow.original) return false;
  
  return (
    prevRow.id === nextRow.id &&
    prevRow.getIsSelected() === nextRow.getIsSelected() &&
    JSON.stringify(prevRow.original) === JSON.stringify(nextRow.original)
  );
};

const MemoizedRow = memo(({ row }: TableRowProps) => (
  <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
    {row.getVisibleCells().map((cell) => (
      <MemoizedCell key={cell.id} cell={cell} />
    ))}
  </TableRow>
), areRowsEqual);

MemoizedRow.displayName = "MemoizedRow";

interface RewardsTableBodyProps {
  table: Table<OfferWithCategoriesAndTags>;
  columns: ColumnDef<OfferWithCategoriesAndTags>[];
  isLoading: boolean;
}

export const RewardsTableBody = memo(({ table, columns, isLoading }: RewardsTableBodyProps) => (
  <TableBody>
    <TableRow className="border-0">
      <TableCell className="p-3" />
    </TableRow>
    {table.getRowModel().rows?.length ? (
      table.getRowModel().rows.map((row) => (
        <MemoizedRow key={row.id} row={row} />
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={columns.length} className="h-24 text-center">
          {isLoading ? "Loading..." : "No results."}
        </TableCell>
      </TableRow>
    )}
  </TableBody>
));

RewardsTableBody.displayName = "RewardsTableBody";

interface RewardsTableProps {
  table: Table<OfferWithCategoriesAndTags>;
  columns: ColumnDef<OfferWithCategoriesAndTags>[];
  isLoading: boolean;
}

export const RewardsTable = memo(({ table, columns, isLoading }: RewardsTableProps) => (
  <div className="flex flex-col gap-4">
    <UITable>
      <RewardsTableHeader table={table} />
      <RewardsTableBody table={table} columns={columns} isLoading={isLoading} />
    </UITable>
    <div className="flex gap-4 justify-end">
      <Button variant="outline" size="sm" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
        Previous
      </Button>
      <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
        Next
      </Button>
    </div>
  </div>
));

RewardsTable.displayName = "RewardsTable";
