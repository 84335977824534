import React from "react";
import { memo, useState } from "react";

import "./checkbox.scss";

type OwnProps = {
  wrapperClassName?: string;
  label?: string;
  className?: string;
  checked?: boolean;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Checkbox = memo(({ className, checked, name, onChange, ...restProps }: Props) => {
  const [localChecked, setChecked] = useState(checked || false);

  const onLocalChange = () => {
    setChecked(!localChecked);
    if (onChange) {
      onChange({
        target: { name: name || "checkbox", checked: !localChecked, value: !localChecked ? "on" : "off" } as HTMLInputElement,
        nativeEvent: new MouseEvent("click"),
        currentTarget: { value: !localChecked ? "on" : "off" } as HTMLInputElement,
        bubbles: false,
        cancelable: false,
        defaultPrevented: false,
        eventPhase: 0,
        isTrusted: false,
        preventDefault (): void {
          throw new Error("Function not implemented.");
        },
        isDefaultPrevented (): boolean {
          throw new Error("Function not implemented.");
        },
        stopPropagation (): void {
          throw new Error("Function not implemented.");
        },
        isPropagationStopped (): boolean {
          throw new Error("Function not implemented.");
        },
        persist (): void {
          throw new Error("Function not implemented.");
        },
        timeStamp: 0,
        type: "",
      });
    }
  };

  return (
    <input id={name} name={name} className={`checkbox ${className}`} type="checkbox" onChange={onLocalChange} checked={localChecked} {...restProps} />
  );
});
Checkbox.displayName = "Checkbox";
