import React, { useMemo } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "@tanstack/react-router";
import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { DataTable } from "@/datatable";
import { Icon } from "@/icon/icon";
import { buttonVariants } from "@/ui/button";

import { SourceType, TransactionWithAdditionalData } from "~/api/model";
import formatDate from "~/helpers/date-formatting";
import { flattenNestedObjects } from "~/helpers/object-transformer";
import { Form } from "~/oldComponents/form/Form";
import { FormConfig } from "~/oldComponents/layout/types";

type Props = {
  staticUserId?: boolean;
  initUserId?: string;
  initSourceType?: string;
  initOfferId?: string;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  handleSubmit: (userid: string | undefined, sourcetype: string | undefined, offerid: string | undefined) => void;
  setPagination: React.Dispatch<React.SetStateAction<{
    pageIndex: number;
    pageSize: number;
  }>>;
  items: TransactionWithAdditionalData[];
  total: number;
  loading: boolean;
};

export const TransactionTable = React.memo(function TransactionTable({
  staticUserId,
  initUserId,
  initSourceType,
  initOfferId,
  pagination,
  setPagination,
  handleSubmit,
  items,
  total,
  loading,
}: Props) {
  const [userid, setUserid] = useState<string | undefined>(initUserId || undefined);
  const [sourceType, setSourceType] = useState<string | undefined>(initSourceType || undefined);
  const [offerId, setOfferId] = useState<string | undefined>(initOfferId || undefined);

  function handle(event: React.FormEvent) {
    event?.preventDefault();
    handleSubmit(userid || undefined, sourceType || undefined, offerId || undefined);
  }

  const form: FormConfig = {
    fields: [
      {
        name: "source-type",
        label: "Source type:",
        helpText: "Filter the type of transaction",
        type: "search-select",
        change: (data: string) => {
          setSourceType(data || undefined);
        },
        options: Object.keys(SourceType).map((key) => ({ name: key, value: key })),
        empty: "All",
        value: sourceType || "",
      },
      {
        name: "userid",
        label: "User ID:",
        helpText: "The ID of the user that the transaction is related to.",
        type: "text",
        disabled: staticUserId,
        change: (e: React.ChangeEvent<HTMLInputElement>) => {
          setUserid(e.target.value || undefined);
        },
        value: userid || "",
      },
      {
        name: "offer-id",
        label: "Offer ID:",
        helpText: "The ID of the offer that the transaction is related to. This will only include transactions that are related to an offer.",
        type: "text",
        change: (e: React.ChangeEvent<HTMLInputElement>) => {
          setOfferId(e.target.value || undefined);
        },
        value: offerId || "",
      },
    ],
    onSubmit: handle,
    submitText: "Search Transactions",
    name: "transaction-filter",
  };

  const columns = useMemo<ColumnDef<TransactionWithAdditionalData>[]>(
    () => [
      {
        id: "userId",
        header: () => "User Id",
        cell: ({ row }) => (
          <Link target="_blank" className={buttonVariants({ variant: "ghost", size: "sm" })} to="/users/$userId" params={{ userId: row.original.transaction.userId as string }}>
            <Icon icon="Search" /> {row.original.transaction.userId}
          </Link>
        ),
      },
      {
        id: "amount",
        header: () => "Amount",
        cell: ({ row }) => {
          const hasShards = !!row.original.transaction.diamondShards && row.original.transaction.diamondShards !== 0;
          const hasAmount = !!row.original.transaction.amount && row.original.transaction.amount !== 0;

          if (!hasShards && !hasAmount) return "-";

          return (
            <div className="flex flex-col gap-2">
              {hasShards && <div className="flex items-center gap-2"><Icon className="fill-[#6fb8f1] text-[#479bdf]" icon="Diamond" /> {(row.original.transaction.diamondShards ?? 0) / import.meta.env.VITE_REACT_DIAMOND_SHARDING}</div>}
              {hasAmount && <div className="flex items-center gap-2"><Icon icon="Coin" /> {row.original.transaction.amount}</div>}
            </div>
          );
        }
      },
      {
        id: "type",
        header: () => "Type",
        cell: ({ row }) => row.original.transaction.source.type,
      },
      {
        id: "moreInfo",
        header: () => "More Info",
        cell: ({ row }) => {
          if (row.original.order) {
            return (
              <>
                {row.original.order.offerSnapshot?.bonus ? (
                  <Link
                    className={buttonVariants({ variant: "ghost", size: "sm" })}
                    to="/$market/marketplace/rewards/bonus/$rewardId"
                    params={{ market: row.original.order.offerSnapshot?.country || "SE", rewardId: row.original.order.offerId }}
                    target="_blank"
                  >
                    {row.original.order.offerSnapshot?.titleV2 || "No title found"}
                  </Link>
                ) : (
                  <Link
                    to="/$market/marketplace/rewards/$rewardId"
                    params={{ market: row.original.order.offerSnapshot?.country || "SE", rewardId: row.original.order.offerId }}
                    target="_blank"
                    className={buttonVariants({ variant: "ghost", size: "sm" })}
                  >
                    {row.original.order.offerSnapshot?.titleV2 || "No title found"}
                  </Link>
                )}
                <br />
                {row.original.coupon?.code && (
                  <div className="text-xs text-muted-foreground">
                    Coupon: {row.original.coupon?.code || row.original.coupon?.url || ""}
                  </div>
                )}
              </>
            );
          } else if (row.original.step) {
            return `${row.original.step.trackedAmount?.toLocaleString() || row.original.step.amount?.toLocaleString() || 0} Steps`;
          } else if (row.original.admin) {
            return (
              <Link target="_blank" className={buttonVariants({ variant: "ghost", size: "sm" })} to="/users/$userId" params={{ userId: row.original.admin._id as string }}>
                <Icon icon="Search" /> {row.original.admin.email || row.original.admin.profile?.email}
              </Link>
            );
          } else if (row.original.user_invite) {
            const isInviter = row.original.transaction.source.type === SourceType.UserInvite;
            const inviteCreatedByAdmin = !!row.original.user_invite?.inviteLinkId;
            const isInviteDeleted = !row.original.user_invite?.inviterId && !row.original.user_invite?.inviteeId && !row.original.user_invite?.inviteLinkId;

            const getInviteLabel = (isDeleted: boolean, isInviter: boolean) => {
              if (isDeleted) {
                return "-";
              }
              return isInviter ? "Invitee:" : "Inviter:";
            };

            return (
              <>
                {inviteCreatedByAdmin ? (
                  <>
                    <p>Invite link:</p>
                    <Link to="/invite-links/$inviteLinkId" params={{ inviteLinkId: row.original.user_invite?.inviteLinkId as string }} target="_blank" className={buttonVariants({ variant: "ghost", size: "sm" })}>
                      {row.original.user_invite?.inviteLinkId}
                    </Link>
                  </>
                ) : (
                  <>
                    <p>{getInviteLabel(isInviteDeleted, isInviter)}</p>
                    <Link to="/users/$userId" params={{ userId: isInviter ? row.original.user_invite?.inviteeId as string : row.original.user_invite?.inviterId as string }} target="_blank" className={buttonVariants({ variant: "ghost", size: "sm" })}>
                      {isInviter ? row.original.user_invite?.inviteeId : row.original.user_invite?.inviterId}
                    </Link>
                  </>
                )}
              </>
            );
          }

          return "-";
        },
      },
      {
        id: "actionDate",
        header: () => "Action Date",
        cell: ({ row }) => formatDate(row.original.transaction.actionDate || ""),
      },
      {
        id: "createdAt",
        header: () => "Created At",
        cell: ({ row }) => formatDate(row.original.transaction.createdAt || ""),
      }
    ],
    [],
  );

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    rowCount: total,
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  return <section className="flex flex-col gap-10 bg-background px-8 py-10">
    <section className="flex items-start justify-between gap-4">
      <div className="header-titles_wrapper gap-4">
        <h2 className="text-foreground">Transactions</h2>
        {staticUserId
          ? "Find all transactions of the user"
          : "Find all transactions in the system. Here you can search for a specific transaction"}
      </div>
      <div className="flex flex-col items-end gap-2">
        {!!items.length && (
          <CSVLink className="button" data={flattenNestedObjects(items)} target="_blank" filename="transactions">
            Download as CSV
          </CSVLink>
        )}
      </div>
    </section>
    <div>
      <section className="rounded-lg bg-card p-8 text-card-foreground">
        <Form name={form.name} fields={form.fields} onSubmit={form.onSubmit} submitText={form.submitText} showStayOnPage={form.showStayOnPage} />
        <DataTable
          table={table}
          columns={columns}
          emptyState={loading ? "Searching...." : "No results found. Please ajust the filters"}
        />
      </section>
    </div>
  </section>
});
