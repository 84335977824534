import React from "react";
import { useEffect, useMemo, useState } from "react";
import { Link, ReactNode, useNavigate } from "@tanstack/react-router";
import { UseNavigateResult } from "@tanstack/react-router";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { addMonths, subMinutes } from "date-fns";
import { ArrowUpDown, MoreHorizontal, RefreshCw } from "lucide-react";
import { toast } from "sonner";

import { ConfirmDialog } from "@/confirm_dialog";
import { Icon } from "@/icon/icon";
import MultiSelectFormField from "@/multi_select";
import { Button } from "@/ui/button";
import { Checkbox } from "@/ui/checkbox";
import DisabledComponentTooltip from "@/ui/disable-component-tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/ui/dropdown-menu";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";

import { RewardsFilter } from "./RewardsFilter";

import {
  useAddTagsToOffersV1MarketplaceBulkOffersTagsPost,
  useChangeOffersStatusV1MarketplaceBulkOffersStatusPut,
  useCreateTagV1MarketplaceTagsPost,
} from "~/api/marketplace/marketplace.gen";
import { CouponCodeStatus, OfferFilterStatus, OfferStatus, OfferType, PlatformSubModel, TagModel } from "~/api/model";
import {
  useDeleteOffersByIdsV1OffersDelete,
  useUpdateBonusOfferV1OffersBonusOfferOfferIdPut,
  useUpdateOfferV1OffersOfferIdPut,
} from "~/api/offers/offers.gen";
import { RewardsTable } from "~/components/marketplace/rewards-table";
import { getCategoryIcon } from "~/helpers/category";
import formatDate from "~/helpers/date-formatting";
import { prepareBonusOfferDuplication, prepareBonusOfferUpdate, prepareOfferDuplication, prepareOfferUpdate } from "~/helpers/offer";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { cn } from "~/lib/utils";
import { OfferWithCategoriesAndTags, TagWithStatusAndRewardCount, useMarketplace } from "~/providers/marketplace";
import { useAuthStore } from "~/store/authStore";

export type RewardSearch = {
  search?: string;
  status?: OfferFilterStatus;
  rewardType?: string;
  platform?: string;
  categories?: string[];
  tags?: string[];
  page?: number;
  limit?: number;
};

interface RewardProps {
  search: RewardSearch;
}

const Rewards = ({ search }: RewardProps) => {
  const navigate: UseNavigateResult<"/$market/marketplace/"> = useNavigate();
  // Wrap the component with memo
  const isAdminOwner = useAuthStore((state) => state.isAdminOwner);
  const { rewards, refetchMarketplace, marketplaceLoading, tags, market } = useMarketplace();
  const [statusFilter, setStatusFilter] = useState<OfferFilterStatus>(OfferFilterStatus.Active);

  const [mappedOffers, setMappedOffers] = useState<OfferWithCategoriesAndTags[]>([]);

  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [changeStatusDialogText, setChangeStatusDialogText] = useState("");
  const [changeStatusOffer, setChangeStatusOffer] = useState<OfferWithCategoriesAndTags | null>(null);
  const [changeStatusStatus, setChangeStatusStatus] = useState<OfferStatus | null>(null);

  const [bulkActionAlertDialog, setBulkActionAlertDialog] = useState({
    open: false,
    title: "",
    confirmText: "",
    confirmAction: () => { },
  });
  const [rowSelection, setRowSelection] = useState({});
  const [newTags, setNewTags] = useState<{ label: string; value: string }[]>([]);

  const [columnVisibility, _setColumnVisibility] = useState({
    rewardType: true,
    platformString: false,
    categoryIdsString: false,
    tagIdStrings: true,
  });
  const [sorting, setSorting] = useState([
    {
      id: "order",
      desc: false,
    }
  ]);

  const updateOfferMutation = useUpdateOfferV1OffersOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
        refetchMarketplace();
      },
      onSuccess: () => {
        toast("Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  const updateBonusOfferMutation = useUpdateBonusOfferV1OffersBonusOfferOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
        refetchMarketplace();
      },
      onSuccess: () => {
        toast("Bonus Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  const createTagMutation = useCreateTagV1MarketplaceTagsPost({
    mutation: {
      onSuccess: (tag: TagModel) => {
        setNewTags([{ label: tag.name, value: tag._id || "" }, ...newTags]);
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const bulkDeleteRewardsMutation = useDeleteOffersByIdsV1OffersDelete();

  function deleteSelectedRewards() {
    if (!isAdminOwner) {
      toast.error("Error", { description: "Only Admin Owners can delete rewards." });
      return;
    }
    const selectedRows = table.getSelectedRowModel().rows;
    if (selectedRows.some((row) => row.original.status !== OfferStatus.Archived)) {
      toast.error("Error", { description: "Only archived rewards can be deleted." });
      return;
    }
    const selectedIds: string[] = selectedRows.map((row) => row.original.id as string);
    bulkDeleteRewardsMutation.mutate(
      { data: selectedIds },
      {
        onSuccess: () => {
          setMappedOffers((offers) => offers.filter((offer) => !selectedIds.includes(offer.id as string)));
          setRowSelection({});
          refetchMarketplace();
          toast("Rewards are now deleted.");
        },
        onError: (error: AxiosError) => {
          toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
        },
      },
    );
  }

  const updateStatusBulk = useChangeOffersStatusV1MarketplaceBulkOffersStatusPut();

  function updateRewardsStatusBulk(status: OfferStatus, fromStatus: OfferStatus) {
    if (status === OfferStatus.Published) {
      throw new Error("Publish is not supported now.");
    }

    const selectedRows = table.getSelectedRowModel().rows;
    if (status === OfferStatus.Archived && selectedRows.some((row) => row.original.status !== OfferStatus.Unpublished)) {
      toast.error("Error", { description: "Only unpublished rewards can be archived." });
      return;
    }

    if (
      status === OfferStatus.Unpublished &&
      fromStatus === OfferStatus.Published &&
      selectedRows.some((row) => row.original.status !== OfferStatus.Published)
    ) {
      toast.error("Error", { description: "Only published rewards can be unpublished." });
      return;
    }

    if (
      status === OfferStatus.Unpublished &&
      fromStatus === OfferStatus.Archived &&
      selectedRows.some((row) => row.original.status !== OfferStatus.Archived)
    ) {
      toast.error("Error", { description: "Only archived rewards can be unarchived." });
      return;
    }

    const selectedIds: string[] = selectedRows.map((row) => row.original.id as string);
    updateStatusBulk.mutate(
      {
        data: {
          offerIds: selectedIds,
          status,
          fromStatus,
        },
      },
      {
        onSuccess: (now) => {
          setRowSelection({});
          setMappedOffers((offers) =>
            offers.filter((offer) => {
              if (!selectedIds.includes(offer.id as string)) return offer;
              const resultOffer = {
                ...offer,
                status,
              };
              if (status === OfferStatus.Archived) {
                resultOffer["publishDate"] = now as string;
                resultOffer["unPublishDate"] = now as string;
              } else if (status === OfferStatus.Unpublished) {
                resultOffer["unPublishDate"] = now as string;
              }
              return resultOffer;
            }),
          );
          refetchMarketplace();
          toast("Rewards status are updated.");
        },
        onError: (error: AxiosError) => {
          toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
        },
      },
    );
  }

  useEffect(() => {
    if (rewards) {
      const getPlatformType = (platform: PlatformSubModel) => {
        if (platform.android && platform.ios) {
          return "both";
        }
        if (platform.android) {
          return "android";
        }
        return "ios";
      };

      const rewardsWithRewardTypeAndPlatformString = rewards.map((reward) => {
        return {
          ...reward,
          rewardType: reward.type || OfferType.Regular,
          platformString: getPlatformType(reward.platform),
        };
      });
      setMappedOffers(rewardsWithRewardTypeAndPlatformString);
    }
  }, [rewards]);

  useEffect(() => {
    if (market) {
      setRowSelection({});
    }
  }, [market]);

  const prepareStatusUpdate = React.useCallback((offer: OfferWithCategoriesAndTags, status: OfferStatus) => {
    if (offer.status === status) return;

    let statusText = `Are you sure you want to change the status of the reward "${offer.titleV2}" from <br/>"${offer.status}" to "${status}"?`;

    const now = new Date();
    let unpublishDate = new Date(offer.unPublishDate || now);
    if (offer.status === OfferStatus.Unpublished && status === OfferStatus.Published) {
      if (unpublishDate < now) {
        unpublishDate = addMonths(now, 3);
      }
      statusText += `|We will also set the publishDate to "${formatDate(now)}" and we will set the unpublishDate to "${formatDate(unpublishDate)}".`;
    }

    if (offer.status === OfferStatus.Published && status === OfferStatus.Unpublished) {
      if (unpublishDate > now) {
        unpublishDate = subMinutes(now, 5);
      }
      statusText += `|We will also set the unpublishDate to "${formatDate(unpublishDate)}".`;
    }

    if (status === OfferStatus.Archived) {
      statusText += `|This will remove the reward from the marketplace. This will set the publish and unpublish date to "${formatDate(now)}".`;
    }

    setChangeStatusDialogText(statusText);
    setChangeStatusOffer(offer);
    setChangeStatusStatus(status);
    setShowChangeStatusDialog(true);
  }, []);

  const handleHideInListUpdate = React.useCallback((offer: OfferWithCategoriesAndTags, hideInList: boolean) => {
    if (!offer._id) return;

    setMappedOffers((offers) => {
      return offers.map((lOffer: OfferWithCategoriesAndTags) => {
        if (lOffer.id === offer._id) return { ...lOffer, hideInList };
        return lOffer;
      });
    });

    if (offer.isBonus) {
      updateBonusOfferMutation.mutateAsync({
        offerId: offer._id,
        data: {
          ...prepareBonusOfferUpdate(offer),
          hideInList,
        },
      });
    } else {
      updateOfferMutation.mutateAsync({
        offerId: offer._id,
        data: {
          ...prepareOfferUpdate(offer),
          hideInList,
        },
      });
    }
  }, [updateBonusOfferMutation, updateOfferMutation]);

  const handleStatusUpdate = React.useCallback((reward: OfferWithCategoriesAndTags, value: OfferStatus) => {
    prepareStatusUpdate(reward, value);
  }, [prepareStatusUpdate]);

  const columns = useMemo<ColumnDef<OfferWithCategoriesAndTags>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const reward = row.original;

          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="p-0 w-8 h-8">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="w-4 h-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {reward.deeplink && (
                  <DropdownMenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      navigator.clipboard.writeText(reward.deeplink as string);
                      toast("Link was successfully copied to clipboard!");
                    }}
                  >
                    Copy Deeplink
                  </DropdownMenuItem>
                )}
                {!reward.isBonus && (
                  <>
                    <DropdownMenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate({
                          to: "/$market/marketplace/rewards/$rewardId/coupons",
                          search: { status: CouponCodeStatus.Unused },
                          params: { rewardId: reward.id as string, market: reward.country },
                        });
                      }}
                    >
                      Show coupon codes
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate({
                          to: "/$market/marketplace/rewards/create",
                          params: { market: reward.country },
                          search: prepareOfferDuplication(reward),
                        });
                      }}
                    >
                      Duplicate reward
                    </DropdownMenuItem>
                  </>
                )}
                {reward.isBonus && (
                  <DropdownMenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate({
                        to: "/$market/marketplace/rewards/bonus/create",
                        params: { market: reward.country },
                        search: prepareBonusOfferDuplication(reward),
                      });
                    }}
                  >
                    Duplicate bonus reward
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
      {
        accessorKey: "order",
        header: ({ column }) => {
          return (
            <Button variant="ghost" className="px-0" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              Order
              <ArrowUpDown className="ml-2 w-4 h-4" />
            </Button>
          );
        },
      },
      {
        accessorKey: "logoUrl",
        header: "",
        cell: ({ row }) => {
          const logo: string = row.getValue("logoUrl");
          if (logo) {
            if (row.original.isBonus) {
              return (
                <Link
                  to="/$market/marketplace/rewards/bonus/$rewardId"
                  params={{ market: row.original.country, rewardId: row.original.id as string }}
                >
                  <img src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${logo}`} className="w-10 h-10 rounded-full cursor-pointer min-h-10 min-w-10" />
                </Link>
              );
            }
            return (
              <Link to="/$market/marketplace/rewards/$rewardId" params={{ market: row.original.country, rewardId: row.original.id as string }}>
                <img src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${logo}`} className="w-10 h-10 rounded-full cursor-pointer min-h-10 min-w-10" />
              </Link>
            );
          }
        },
      },
      {
        accessorKey: "fullTitle",
        header: ({ column }) => {
          return (
            <Button variant="ghost" className="px-0" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              Reward
              <ArrowUpDown className="ml-2 w-4 h-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          if (row.original.isBonus) {
            return (
              <Link to="/$market/marketplace/rewards/bonus/$rewardId" params={{ market: row.original.country, rewardId: row.original.id as string }}>
                {row.original.fullTitle}
              </Link>
            );
          }
          return (
            <Link to="/$market/marketplace/rewards/$rewardId" params={{ market: row.original.country, rewardId: row.original.id as string }}>
              {row.original.fullTitle}
            </Link>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => (
          <Select onValueChange={(value: OfferStatus) => handleStatusUpdate(row.original, value)} value={row.original.status}>
            <SelectTrigger
              className={cn(
                "justify-center gap-1 rounded-full bg-archived p-2 text-archived-foreground",
                row.original.status === "Published" && "bg-published text-published-foreground",
                row.original.status === "Unpublished" && "bg-unpublished text-unpublished-foreground",
              )}
            >
              <SelectValue placeholder="Select a status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={OfferStatus.Published}>Published</SelectItem>
              <SelectItem value={OfferStatus.Unpublished}>Unpublished</SelectItem>
              <SelectItem value={OfferStatus.Archived}>Archived</SelectItem>
            </SelectContent>
          </Select>
        ),
        filterFn: (row, columnId, filterValue) => {
          if (filterValue === OfferFilterStatus.Active) {
            return row.original.status === OfferStatus.Published || row.original.status === OfferStatus.Unpublished;
          }
          return row.original.status === filterValue;
        },
      },
      {
        accessorKey: "hideInList",
        header: "Hidden",
        cell: ({ row }) => (
          <Checkbox checked={row.getValue("hideInList")} onCheckedChange={(value) => handleHideInListUpdate(row.original, !!value)} />
        ),
      },
      {
        id: "price",
        header: ({ column }) => {
          return (
            <Button variant="ghost" className="px-0" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              Currency
              <ArrowUpDown className="ml-2 w-4 h-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          const hasShards = !!row.original.diamonds && row.original.diamonds !== 0;
          const hasAmount = !!row.original.price && row.original.price !== 0;

          if (!hasShards && !hasAmount) return "-";

          return (
            <div className="flex flex-col gap-2">
              {hasShards && <div className="flex gap-2 items-center"><Icon className="fill-[#6fb8f1] text-[#479bdf]" icon="Diamond" /> {row.original.diamonds}</div>}
              {hasAmount && <div className="flex gap-2 items-center"><Icon icon="Coin" /> {row.original.price}</div>}
            </div>
          );
        },
        sortingFn: (rowA, rowB) => {
          const hasDiamondsA = !!rowA.original.diamonds && rowA.original.diamonds !== 0;
          const hasDiamondsB = !!rowB.original.diamonds && rowB.original.diamonds !== 0;

          // If one has diamonds and the other doesn't
          if (hasDiamondsA && !hasDiamondsB) return -1;
          if (!hasDiamondsA && hasDiamondsB) return 1;

          // If both have diamonds, compare diamond amounts
          if (hasDiamondsA && hasDiamondsB) {
            const diamondDiff = (rowB.original.diamonds || 0) - (rowA.original.diamonds || 0);
            if (diamondDiff !== 0) return diamondDiff;
          }

          // If diamond amounts are equal or neither has diamonds, sort by price
          return (rowB.original.price || 0) - (rowA.original.price || 0);
        },
      },
      {
        id: "available",
        header: () => {
          return (
            <div>
              Total Inventory
              <br />
              <small>Available/Claimed</small>
            </div>
          );
        },
        cell: ({ row }) => {
          return (
            <div>
              {row.original.amount}
              <br />
              <small>
                {row.original.amount - (row.original.verified || 0)}/{row.original.verified}
              </small>
            </div>
          );
        },
      },
      {
        id: "codes",
        header: () => {
          return (
            <div>
              Codes
              <br />
              <small>Unused/Used</small>
            </div>
          );
        },
        cell: ({ row }) => (
          <div>
            {row.original.unused_coupon_count}/{(row.original.coupon_count || 0) - (row.original.unused_coupon_count || 0)}
          </div>
        ),
      },
      {
        accessorKey: "categories",
        header: () => "Categories",
        cell: ({ row }) => {
          const categories = row.original.categories;
          // TODO: Make the categories into a string that should be displayed.
          return (
            <div className="flex gap-4 items-center">
              {categories.map((category) => (
                <div key={category.category} className="p-2 rounded-md bg-accent text-accent-foreground">
                  <Icon className="w-5 h-5" icon={getCategoryIcon(category.category)} />
                </div>
              ))}
            </div>
          );
        },
        filterFn: (row, _columnId, filterValue) => {
          if (filterValue && filterValue.length > 0) {
            return row.original.categories.some((category) => filterValue.includes(category.category));
          }
          return true;
        },
      },
      {
        accessorKey: "tags",
        header: () => "Tags",
        cell: ({ row }) => {
          const tags = row.original.tags;
          return tags.map((tag) => <div key={tag._id as string}>{tag.name}</div>);
        },
        filterFn: (row, _columnId, filterValue) => {
          if (filterValue && filterValue.length > 0) {
            return row.original.tags.some((tag) => filterValue.includes(tag._id));
          }
          return true;
        },
      },
      {
        id: "schedule",
        header: () => "Schedule",
        cell: ({ row }) => {
          return (
            <div className="flex flex-col gap-2">
              <div className="whitespace-nowrap">Start: {formatDate(row.original.publishDate || "") as ReactNode}</div>
              <div className="whitespace-nowrap">End: {formatDate(row.original.unPublishDate || "") as ReactNode}</div>
            </div>
          );
        },
      },
      {
        accessorKey: "rewardType",
        header: () => "Reward type",
      },
      {
        accessorKey: "platformString",
        header: () => "Platform",
      },
      {
        accessorKey: "categoryIdsString",
        header: () => "Categories",
      },
      {
        accessorKey: "tagIdStrings",
        header: () => "Tags",
      },
    ],
    [handleStatusUpdate, handleHideInListUpdate, navigate]
  );

  const tableConfig = useMemo(
    () => ({
      data: mappedOffers,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      initialState: {
        columnFilters: [
          {
            id: "status",
            value: OfferFilterStatus.Active,
          },
        ],
        sorting: [{ desc: false, id: "order" }],
      },
      state: {
        columnVisibility,
        rowSelection,
        sorting,
      },
      onRowSelectionChange: setRowSelection,
      onSortingChange: setSorting,
    }),
    [
      mappedOffers,
      columns,
      columnVisibility,
      rowSelection,
      sorting,
      setRowSelection,
      setSorting,
    ]
  );

  const table = useReactTable(tableConfig);

  useEffect(() => {
    table.getColumn("fullTitle")?.setFilterValue(search.search);
    table.getColumn("rewardType")?.setFilterValue(search.rewardType);
    table.getColumn("platformString")?.setFilterValue(search.platform);
    table.getColumn("categoryIdsString")?.setFilterValue(search.categories);
    if (search.tags && search.tags.length > 0) {
      table.getColumn("tagIdStrings")?.setFilterValue(search.tags);
    } else {
      table.getColumn("tagIdStrings")?.setFilterValue(undefined);
    }
  }, [search.categories, search.platform, search.rewardType, search.search, search.tags, table]);

  const getSelectedOffersCommonTagIds = () => {
    const selectedRowsTags = table.getSelectedRowModel().rows.map((row) => row.original.tags);
    const commonTags = selectedRowsTags.reduce((acc, tags) => {
      return acc.filter((tag) => tags.some((t) => t._id === tag._id));
    }, selectedRowsTags[0]);
    return commonTags ? commonTags.map((tag) => tag._id as string) : [];
  };

  const bulkAddOffersTagsMutation = useAddTagsToOffersV1MarketplaceBulkOffersTagsPost();

  const addTagSaveBtn = (selectedValues: string[], closePopover: () => void, clearSearch: () => void) => {
    const defaultSelectedTagIds = getSelectedOffersCommonTagIds();
    const newSelectedTagIds = selectedValues.filter((value) => !defaultSelectedTagIds.includes(value));
    const allTagIds = [...defaultSelectedTagIds, ...newSelectedTagIds];
    const offerIds = table.getSelectedRowModel().rows.map((row) => row.original.id as string);
    if (newSelectedTagIds.length > 0) {
      bulkAddOffersTagsMutation.mutate(
        {
          data: {
            market,
            offerIds,
            tagIds: newSelectedTagIds,
          },
        },
        {
          onError: (error: AxiosError) => {
            toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
          },
          onSuccess: async () => {
            toast("Tag(s) added successfully!");
            const newCommonTags = [...newTags.map((t) => ({ _id: t.value, name: t.label }) as TagWithStatusAndRewardCount), ...tags].filter((tag) =>
              allTagIds.includes(tag._id as string),
            );
            const updatedRewards = mappedOffers.map((offer) => {
              if (!offerIds.includes(offer.id as string)) return offer;
              const newTags = [...offer.tags, ...newCommonTags.filter((tag) => !offer.tags.some((t) => t._id === tag._id))].sort((a, b) =>
                a.name.localeCompare(b.name),
              );
              const newTagIds = newTags.map((tag) => tag._id as string);
              const newTagNames = newTags.map((tag) => tag.name).join(",");
              return {
                ...offer,
                tags: newTags,
                tagIds: newTagIds,
                tagNames: newTagNames,
              };
            });

            clearSearch();

            setMappedOffers(updatedRewards);

            setNewTags([]);

            refetchMarketplace();
          },
        },
      );
      closePopover();
    }
  };

  const handleStatusChange = React.useCallback((value: string) => {
    table.getColumn("status")?.setFilterValue(value);
    setRowSelection({});
    setStatusFilter(value as OfferFilterStatus);
  }, [table, setRowSelection]);

  return (
    <div className="flex relative flex-col gap-6">
      <RewardsFilter
        searchText={search.search}
        status={search.status}
        rewardType={search.rewardType}
        platform={search.platform}
        categories={search.categories}
        tags={search.tags}
        onStatusChange={handleStatusChange}
      />
      <section className="flex justify-between items-center">
        <h3 className="leading-[2.75rem]">Rewards list</h3>
        <div className="flex gap-6 items-center ml-auto">
          {Object.keys(rowSelection).length > 0 && (
            <DisabledComponentTooltip
              tooltipText="Tags cannot be added to archived rewards."
              disabled={table.getSelectedRowModel().rows.some((row) => row.original.status === OfferStatus.Archived)}
            >
              <MultiSelectFormField
                placeholder="+  Add tag"
                options={[
                  ...newTags.sort((a, b) => a.label.localeCompare(b.label)),
                  ...tags.map((tag) => ({ label: tag.name, value: tag._id || "" })),
                ]}
                defaultValue={[...getSelectedOffersCommonTagIds(), ...newTags.map((tag) => tag.value)]}
                newItems={newTags.map((tag) => tag.value)}
                variant="reverse"
                fixedDefaultValue
                onValueChange={() => { }}
                showBadge={false}
                buttonOptions={{
                  showClearButton: false,
                  showCloseButton: false,
                }}
                customFooter={(options, searchValue, selectedValues, closePopover, clearSearch) => {
                  return (
                    getSelectedOffersCommonTagIds().length !== selectedValues.length && (
                      <div className="p-2">
                        <Button variant="default" className="w-full h-3" onClick={() => addTagSaveBtn(selectedValues, closePopover, clearSearch)}>
                          Save
                        </Button>
                      </div>
                    )
                  );
                }}
                searchOptions={{
                  emptyState: "add",
                  addEvent: (value) => {
                    createTagMutation.mutate({ data: { name: value, market } });
                  },
                }}
              />
            </DisabledComponentTooltip>
          )}
          {Object.keys(rowSelection).length > 0 && (
            <DisabledComponentTooltip
              tooltipText="Published rewards can be unpublished."
              disabled={table.getSelectedRowModel().rows.some((row) => row.original.status !== OfferStatus.Published)}
            >
              <Button
                size="lg"
                variant="outline"
                onClick={() =>
                  setBulkActionAlertDialog({
                    open: true,
                    title: "Are you sure you want to unpublish the selected rewards?",
                    confirmText: "Unpublish rewards",
                    confirmAction: () => updateRewardsStatusBulk(OfferStatus.Unpublished, OfferStatus.Published),
                  })
                }
              >
                Unpublish Rewards
              </Button>
            </DisabledComponentTooltip>
          )}
          {Object.keys(rowSelection).length > 0 && (
            <DisabledComponentTooltip
              tooltipText={`${statusFilter === OfferFilterStatus.Archived ? "Archived" : "Unpublished"} rewards can be ${statusFilter === OfferFilterStatus.Archived ? "unarchived" : "archived"}.`}
              disabled={table
                .getSelectedRowModel()
                .rows.some((row) =>
                  statusFilter === OfferFilterStatus.Archived
                    ? row.original.status !== OfferStatus.Archived
                    : row.original.status !== OfferStatus.Unpublished,
                )}
            >
              <Button
                size="lg"
                variant="default"
                onClick={() =>
                  setBulkActionAlertDialog({
                    open: true,
                    title: `Are you sure you want to ${statusFilter === OfferFilterStatus.Archived ? "unarchive" : "archive"} the selected rewards?`,
                    confirmText: `${statusFilter === OfferFilterStatus.Archived ? "unarchive" : "archive"} rewards`,
                    confirmAction:
                      statusFilter === OfferFilterStatus.Archived
                        ? () => updateRewardsStatusBulk(OfferStatus.Unpublished, OfferStatus.Archived)
                        : () => updateRewardsStatusBulk(OfferStatus.Archived, OfferStatus.Unpublished),
                  })
                }
              >
                {`${statusFilter === OfferFilterStatus.Archived ? "unarchive" : "archive"} rewards`}
              </Button>
            </DisabledComponentTooltip>
          )}
          {isAdminOwner && Object.keys(rowSelection).length > 0 && (
            <DisabledComponentTooltip
              tooltipText="Active rewards cannot be deleted."
              disabled={table.getSelectedRowModel().rows.some((row) => row.original.status !== OfferStatus.Archived)}
            >
              <Button
                size="lg"
                variant="destructive"
                onClick={() =>
                  setBulkActionAlertDialog({
                    open: true,
                    title: "Are you sure you want to delete the selected rewards?",
                    confirmText: "Delete",
                    confirmAction: deleteSelectedRewards,
                  })
                }
              >
                <Icon icon="Trash" /> Delete Rewards
              </Button>
            </DisabledComponentTooltip>
          )}
        </div>
      </section>
      <div className="w-full">
        <div className="rounded-md">
          <RewardsTable table={table} columns={columns} isLoading={marketplaceLoading} />
          <ConfirmDialog
            openDialog={bulkActionAlertDialog.open}
            setOpenDialog={(value: boolean) => setBulkActionAlertDialog({ ...bulkActionAlertDialog, open: value })}
            title={bulkActionAlertDialog.title}
            confirmAction={bulkActionAlertDialog.confirmAction}
            confirmText={bulkActionAlertDialog.confirmText}
          />
          <ConfirmDialog
            openDialog={showChangeStatusDialog}
            setOpenDialog={setShowChangeStatusDialog}
            title={changeStatusDialogText}
            confirmAction={() => {
              if (changeStatusOffer && changeStatusStatus) {
                updateOfferMutation.mutateAsync({
                  offerId: changeStatusOffer._id as string,
                  data: {
                    ...prepareOfferUpdate(changeStatusOffer),
                    status: changeStatusStatus,
                  },
                });
              }
            }}
            confirmText={`set to ${changeStatusStatus}`}
          />
        </div>
      </div>
      <Button
        variant="default"
        size="icon"
        className="fixed right-6 bottom-6 rounded-full shadow-lg hover:shadow-xl"
        onClick={() => {
          toast("Refreshing marketplace data...");
          refetchMarketplace();
        }}
      >
        <RefreshCw className="w-4 h-4" />
      </Button>
    </div>
  );
};

Rewards.displayName = "MarketplaceRewards";

export default Rewards;
