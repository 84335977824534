import React, { memo, ReactNode, useCallback, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { z } from "zod";

import { Button } from "@/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/ui/card";
import { Input } from "@/ui/input";
import { Label } from "@/ui/label";

import { sendLoginEmail } from "~/api/auth";
import { parseFastAPIError } from "~/helpers/parse-errors";

export const Route = createFileRoute("/login")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  component: () => <LoginPage />,
});

// Memoized card components for better performance
const LoginCardHeader = memo(() => (
  <CardHeader>
    <CardTitle className="text-2xl">Login</CardTitle>
    <CardDescription>Enter your email below to login to your account.</CardDescription>
  </CardHeader>
));
LoginCardHeader.displayName = "LoginCardHeader";

const EmailSentContent = memo(() => (
  <CardContent>
    <p className="text-foreground">We have sent you an email with a link to login. You can close this tab</p>
  </CardContent>
));
EmailSentContent.displayName = "EmailSentContent";

interface LoginFormProps {
  email: string;
  isLoading: boolean;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const LoginForm = memo(({ email, isLoading, onEmailChange, onSubmit, onKeyDown }: LoginFormProps) => (
  <>
    <CardContent className="grid gap-4">
      <div className="grid gap-2">
        <Label htmlFor="email">Email</Label>
        <Input id="email" type="email" placeholder="email@stepler.io" value={email} onChange={onEmailChange} required onKeyDown={onKeyDown} />
      </div>
    </CardContent>
    <CardFooter>
      <Button className="w-full" onClick={onSubmit} disabled={isLoading}>
        {isLoading ? "Sending..." : "Login"}
      </Button>
    </CardFooter>
  </>
));
LoginForm.displayName = "LoginForm";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [emailSend, setEmailSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const search = Route.useSearch();

  const requestLoginEmail = useMutation({
    mutationFn: sendLoginEmail,
    onSuccess: () => {
      setIsLoading(false);
      setEmailSend(true);
    },
    onError(error: AxiosError) {
      setIsLoading(false);
      toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
    },
  });

  const { mutate } = requestLoginEmail;

  const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    let localEmail = email;
    if ("@" !== email.match(/@/)?.[0]) {
      localEmail = `${email}@stepler.io`;
      setEmail(localEmail);
    }

    setIsLoading(true);
    mutate({
      email: localEmail,
      login_url: search.redirect || "/dashboard",
    });
  }, [email, search.redirect, mutate]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  return (
    <section className="flex h-screen w-full items-center justify-center">
      <Card className="w-full max-w-sm">
        <LoginCardHeader />
        {emailSend ? (
          <EmailSentContent />
        ) : (
          <LoginForm email={email} isLoading={isLoading} onEmailChange={handleEmailChange} onSubmit={handleSubmit} onKeyDown={handleKeyDown} />
        )}
      </Card>
    </section>
  );
};
