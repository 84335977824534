import { ReactNode, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { formatDate as fnsFormetDate } from "date-fns";
import { toast } from "sonner";

import { UserModelOutputStreak } from "~/api/model";
import { useUpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch } from "~/api/users/users.gen";
import { convertToUsableDate } from "~/helpers/date-formatting";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Form } from "~/oldComponents/form/Form";
import type { Field } from "~/oldComponents/form/types";

interface StreakSectionProps {
  userId: string;
  streak: UserModelOutputStreak;
}

export function StreakSection({ userId, streak }: StreakSectionProps) {
  const queryClient = useQueryClient();

  const [lastStreakDate, setLastStreakDate] = useState(convertToUsableDate(streak?.lastStreakDate) || new Date());
  const [longestStreak, setLongestStreak] = useState(streak?.longestStreak || 0);
  const [currentStreak, setCurrentStreak] = useState(streak?.currentStreak || 0);
  const [weekStreak, setWeekStreak] = useState(streak?.claimableStreaks?.weekStreak || 0);

  const updateUserStreakMutation = useUpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
        toast("User streak was successfully updated!");
      },
      onError: (error: AxiosError) => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onStreakSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    updateUserStreakMutation.mutateAsync({
      userId,
      data: {
        last_streak_date: lastStreakDate.toISOString(),
        longest_streak: longestStreak,
        current_streak: currentStreak,
        week_streak: weekStreak,
      },
    });
  };

  const streakFields: Field[] = [
    {
      name: "lastStreakDate",
      label: "Last Streak Date",
      helpText: "Last date that the streak was synced. Needs to be set to today, if you change the streak",
      type: "date",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastStreakDate(convertToUsableDate(e.target.value) || new Date());
      },
      value: fnsFormetDate(lastStreakDate, "yyyy-MM-dd"),
      custom: true,
    },
    {
      name: "longestStreak",
      label: "Longest Streak",
      helpText: "The longest streak the user had",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setLongestStreak(+event.target.value);
      },
      value: longestStreak,
      min: 0,
    },
    {
      name: "currentStreak",
      label: "Current Streak",
      helpText: "The current streak of the user",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setCurrentStreak(+event.target.value);
      },
      value: currentStreak,
      min: 0,
    },
    {
      name: "weekStreak",
      label: "7 day streak",
      helpText: "The amount of days claimed in a row to be able to claim the 7 day streak",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setWeekStreak(+event.target.value);
      },
      value: weekStreak,
      min: 0,
      max: 7,
    },
  ];

  return (
    <section className="user-data_section">
      <h5 className="data_section-header">Streaks</h5>
      <Form
        vertical
        name="user-streak"
        fields={streakFields}
        onSubmit={onStreakSubmit}
        submitText="Update streak"
      />
    </section>
  );
}
