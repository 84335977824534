import React, { useEffect, useState } from 'react'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { z } from 'zod'

import { useGetAllTransactionsV1TransactionsGet } from '~/api/transactions/transactions.gen'
import { TransactionTable } from '~/pages/transaction'

export const Route = createFileRoute('/_auth/transactions')({
  validateSearch: z.object({
    userid: z.string().optional(),
    sourcetype: z.string().optional(),
    offerid: z.string().optional(),
    page: z.number().default(1),
    limit: z.number().default(100),
  }),
  component: () => <TransactionsPage />,
})

function TransactionsPage() {
  const navigate = useNavigate()
  const searchParams = Route.useSearch()

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  useEffect(() => {
    if (pagination.pageIndex + 1 !== searchParams.page || pagination.pageSize !== searchParams.limit) {
      navigate({
        to: '/transactions',
        search: (prev) => ({
          ...prev,
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
        }),
      })
    }
  }, [pagination, navigate, searchParams])

  const {
    data: { items, total } = {
      items: [],
      total: 0,
    },
    isFetching,
  } = useGetAllTransactionsV1TransactionsGet(
    {
      user_id: searchParams.userid || undefined,
      source_type: searchParams.sourcetype || undefined,
      offer_id: searchParams.offerid || undefined,
      page: searchParams.page,
      limit: searchParams.limit,
    },
    {
      query: {
        enabled:
          !!searchParams.userid ||
          !!searchParams.sourcetype ||
          !!searchParams.offerid,
        initialData: {
          items: [],
          total: 0,
          has_next: false,
          has_prev: false,
        },
      },
    },
  )

  const handleSubmit = (
    userid: string | undefined,
    sourcetype: string | undefined,
    offerid: string | undefined,
  ) => {
    navigate({
      to: '/transactions',
      search: (prev) => ({
        ...prev,
        userid,
        sourcetype,
        offerid,
        limit: searchParams.limit,
        page: searchParams.page,
      }),
    })
  }

  return (
    <TransactionTable
      initUserId={searchParams.userid}
      initSourceType={searchParams.sourcetype}
      initOfferId={searchParams.offerid}
      pagination={pagination}
      setPagination={setPagination}
      handleSubmit={handleSubmit}
      items={items}
      total={total || 0}
      loading={isFetching}
    />
  )
}
