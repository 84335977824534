import React from "react";
import { createFileRoute, Link } from "@tanstack/react-router";

import Tab from "@/tab";
import { buttonVariants } from "@/ui/button";
import { Card } from "@/ui/card";

import { prepareBonusOfferEmpty, prepareOfferEmpty } from "~/helpers/offer";
import BusinessRulesPart from "~/pages/Marketplace/CollectionsParts/BusinessRules";
import CategoryCollectionsPart from "~/pages/Marketplace/CollectionsParts/Categories";
import TagsCollectionsPart from "~/pages/Marketplace/CollectionsParts/Tags";
import { useMarketplace } from "~/providers/marketplace";

export const Route = createFileRoute("/_auth/$market/marketplace/collections")({
  component: () => <MarketplacePage />,
});

function MarketplacePage() {
  const { market } = Route.useParams();
  const { almostSoldOutRewards } = useMarketplace();

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex gap-6 items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Marketplace - Collections</h2>
          <p className="text-foreground">
            In here you will find a list of categories and tags. Categories are limited to only allowing you to change the reward order inside.
          </p>
        </div>
        <div className="flex flex-col-reverse gap-2">
          <Link
            to="/$market/marketplace/rewards/bonus/create"
            params={{ market }}
            search={prepareBonusOfferEmpty(market)}
            className={buttonVariants({ variant: "outline" })}
          >
            Create bonus reward
          </Link>
          <Link
            to="/$market/marketplace/rewards/create"
            params={{ market }}
            search={prepareOfferEmpty(market)}
            className={buttonVariants({ variant: "default" })}
          >
            Create reward
          </Link>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={false} to={`/${market}/marketplace`} title="Rewards" />
          <Tab active to={`/${market}/marketplace/collections`} title="Collections" />
          <Tab active={false} to={`/${market}/marketplace/liveview`} title="Live View" />
          <Tab active={false} to={`/${market}/marketplace/soldout`} title={`Sold out | ${almostSoldOutRewards.length}`} />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <div className="flex flex-col gap-6">
            <CategoryCollectionsPart />
            <TagsCollectionsPart />
            <BusinessRulesPart />
          </div>
        </Card>
      </div>
    </section>
  );
}
