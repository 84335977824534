import React, { ReactNode } from "react";
import { useState } from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { useCreateMarketConfigV1MarketConfigPost } from "~/api/market-config/market-config.gen";
import { ImageType, MarketConfigModelInput, SocialMediaSubModel } from "~/api/model";
import { useGetCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Form } from "~/oldComponents/form/Form";
import { Field } from "~/oldComponents/form/types";
import PageLayout from "~/oldComponents/layout/PageLayout";

export const Route = createFileRoute("/_auth/markets/create")({
  component: () => <CreateMarketPage />,
});

function CreateMarketPage() {
  const navigate = useNavigate();

  const [market, setMarket] = useState<MarketConfigModelInput>({
    countryCode: "",
    supportEmails: ["order@stepler.io"] as string[],
    bannerUrl: null,
    leaderboard: {
      lengthInDays: 7,
      maxActiveLeaderboards: 3,
      maxParticipants: 25,
    },
    premiumLeaderboard: {
      lengthInDays: 7,
      maxActiveLeaderboards: 3,
      maxParticipants: 25,
    },
    readyForRelease: false,
    rewards: [
      {
        source: "WatchingVideo",
        amount: 10,
      },
      {
        source: "FiveKReward",
        amount: 5,
      },
      {
        source: "TenKReward",
        amount: 5,
      },
      {
        source: "FifteenKReward",
        amount: 5,
      },
      {
        source: "TwentyKReward",
        amount: 10,
      },
      {
        source: "ThirtyKReward",
        amount: 10,
      },
      {
        source: "FourtyKReward",
        amount: 10,
      },
      {
        source: "InstagramVisitReward",
        amount: 10,
      },
      {
        source: "FacebookVisitReward",
        amount: 10,
      },
      {
        source: "TikTokVisitReward",
        amount: 10,
      },
      {
        source: "FacebookAttachment",
        amount: 10,
      },
      {
        source: "AppleAttachment",
        amount: 10,
      },
      {
        source: "GoogleAttachment",
        amount: 10,
      },
      {
        source: "UserInvite",
        amount: 10,
      },
      {
        source: "AcceptInvite",
        amount: 10,
      },
      {
        source: "ProfileCompletion",
        amount: 20,
      },
    ],
    socialMedia: [
      {
        source: "Facebook",
        url: "https://www.facebook.com/stepleruk",
      },
      {
        source: "Instagram",
        url: "https://instagram.com/steplerapp_uk?igshid=YmMyMTA2M2Y=",
      },
      {
        source: "TikTok",
        url: "https://www.tiktok.com/@steplerapp?_t=8Z7rZcApbew&_r=1",
      },
    ],
  });

  const createMarketMutation = useCreateMarketConfigV1MarketConfigPost({
    mutation: {
      onSuccess: () => {
        toast("market successfully created!");
        navigate({ to: "/markets" });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    if (!market) return;
    await createMarketMutation.mutateAsync({
      data: market,
    });
  };

  const rewardFields: Field[] = [
    {
      name: "country",
      label: "Country:",
      type: "search-select",
      helpText: "Specify market country.",
      change: (data: string) => setMarket({ ...market, countryCode: data }),
      value: market?.countryCode,
      options: useGetCountryOptions(),
      row: true,
      required: true,
    },
    {
      name: "releaseStatus",
      label: "Release status:",
      helpText: "Flag that indicates availability of the market for mobile apps.",
      type: "checkbox",
      change: (event: React.ChangeEvent<HTMLInputElement>) => setMarket({ ...market, readyForRelease: event.currentTarget.checked }),
      value: market?.readyForRelease,
      row: true,
    },
    {
      name: "supportEmails",
      label: "Support emails:",
      helpText:
        "Support emails block is responsible for service emails. Users specified in this list will receive emails about:\n1) offers that are scheduled for unpublishing in the next 24h;\n2) offers which coupon code warning limit was reached;\n3) offers which coupon codes are run out.",
      required: true,
      type: "text-array",
      change: (newArray: string[]) => setMarket({ ...market, supportEmails: newArray }),
      value: market?.supportEmails || [],
      row: true,
      custom: true,
    },
    {
      name: "instagram",
      label: "Instagram:",
      type: "text",
      helpText: "Stepler Instagram account represented in specified country.",
      value: market?.socialMedia.find((social: SocialMediaSubModel) => social.source === "Instagram")?.url,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSocialMedia = [...market.socialMedia];
        const index = newSocialMedia.findIndex((social: SocialMediaSubModel) => social.source === "Instagram");
        if (index !== -1) {
          newSocialMedia[index] = { ...newSocialMedia[index], url: event.target.value };
        } else {
          newSocialMedia.push({ source: "Instagram", url: event.target.value });
        }
        setMarket({ ...market, socialMedia: newSocialMedia });
      },
      row: true,
    },
    {
      name: "facebook",
      label: "Facebook:",
      type: "text",
      helpText: "Stepler Facebook account represented in specified country.",
      value: market?.socialMedia.find((social: SocialMediaSubModel) => social.source === "Facebook")?.url,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSocialMedia = [...market.socialMedia];
        const index = newSocialMedia.findIndex((social: SocialMediaSubModel) => social.source === "Facebook");
        if (index !== -1) {
          newSocialMedia[index] = { ...newSocialMedia[index], url: event.target.value };
        } else {
          newSocialMedia.push({ source: "Facebook", url: event.target.value });
        }
        setMarket({ ...market, socialMedia: newSocialMedia });
      },
      row: true,
    },
    {
      name: "tiktok",
      label: "TikTok:",
      type: "text",
      helpText: "Stepler TikTok account represented in specified country.",
      value: market?.socialMedia.find((social: SocialMediaSubModel) => social.source === "TikTok")?.url,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedSocialMedia = [...market.socialMedia];
        const index = updatedSocialMedia.findIndex((social: SocialMediaSubModel) => social.source === "TikTok");
        if (index !== -1) {
          updatedSocialMedia[index] = { ...updatedSocialMedia[index], url: event.target.value };
        } else {
          updatedSocialMedia.push({ source: "TikTok", url: event.target.value });
        }
        setMarket({ ...market, socialMedia: updatedSocialMedia });
      },
      row: true,
    },
    {
      name: "banner",
      label: "Banner:",
      helpText: "Market banner. Displayed on app dashboard.",
      type: "image-cropper",
      imageType: ImageType.market_banner,
      value: market?.bannerUrl,
      change: (imageUrl: string | null) => {
        setMarket((prev) => ({
          ...prev,
          bannerUrl: imageUrl
        }));
      },
      row: true,
      custom: true,
    },
  ];

  return (
    <PageLayout
      title="Create a new Market"
      description="Markets can be created and filled with offers,<br>before they are released to the public."
      loading={false}
    >
      <Form vertical name="rewards" onSubmit={onSubmit} submitText="Save market" fields={rewardFields} />
    </PageLayout>
  );
}
