import * as Sentry from "@sentry/react";
import { jwtDecode } from "jwt-decode";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { AXIOS_INSTANCE } from "~/api/base";
import { UserRole } from "~/api/model";
import { AuthState, ExtendedJwtPayload } from "~/types/auth";
import { User } from "~/types/user";

interface AuthStore extends AuthState {
  setTokens: (tokens: { accessToken: string; refreshToken: string }) => void;
  setUser: (user: User) => void;
  logout: () => Promise<void>;
}

const initialState: AuthState = {
  accessToken: "",
  refreshToken: "",
  user: null,
  isAuthenticated: false,
  isAdminOwner: false,
};

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      ...initialState,

      setTokens: ({ accessToken, refreshToken }) => {
        AXIOS_INSTANCE.defaults.headers.Authorization = `Bearer ${accessToken}`;
        const { permissions }: ExtendedJwtPayload = jwtDecode(accessToken);
        set({
          accessToken,
          refreshToken,
          isAuthenticated: true,
          isAdminOwner: permissions.includes(UserRole.Admin_Owner)
        });
      },

      setUser: (user: User) => {
        AXIOS_INSTANCE.defaults.headers.userid = user.user_id;
        Sentry.setUser(user);
        set({ user });
      },

      logout: async () => {
        AXIOS_INSTANCE.defaults.headers.Authorization = null;
        AXIOS_INSTANCE.defaults.headers.userid = null;
        Sentry.setUser(null);
        set({ ...initialState });
      },
    }),
    {
      name: "auth",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        isAdminOwner: state.isAdminOwner,
      }),
    },
  ),
);
