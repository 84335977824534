import React, { ReactNode } from "react";
import { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import Tab from "@/tab";
import { Card } from "@/ui/card";

import {
  getGetGeneralConfigV1GeneralConfigGetSuspenseQueryOptions,
  useUpdateGeneralConfigV1GeneralConfigPatch,
} from "~/api/general-config/general-config.gen";
import { RewardsConfigSubModel, StreakConfigSubModel } from "~/api/model";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Form } from "~/oldComponents/form/Form";
import { Field } from "~/oldComponents/form/types";
import { useAuthStore } from "~/store/authStore";

export const Route = createFileRoute("/_auth/general-config/")({
  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(getGetGeneralConfigV1GeneralConfigGetSuspenseQueryOptions());
  },
  component: () => <GeneralConfigPage />,
});

function GeneralConfigPage() {
  const isAdminOwner = useAuthStore((state) => state.isAdminOwner);

  const { data: generalConfig } = useSuspenseQuery(getGetGeneralConfigV1GeneralConfigGetSuspenseQueryOptions());

  const [rewards, setRewards] = useState<RewardsConfigSubModel[]>(generalConfig.rewards || []);
  const [weekStreak, setWeekStreak] = useState<StreakConfigSubModel>(generalConfig.weekStreak);

  const updateGeneralConfigMutation = useUpdateGeneralConfigV1GeneralConfigPatch({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
      onSuccess: () => {
        toast("Config was successfully updated!");
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await updateGeneralConfigMutation.mutateAsync({
      data: { ...generalConfig, weekStreak, rewards },
    });
  };

  const rewardFields: Field[] = [
    {
      name: "UserInvite",
      label: "UserInvite",
      helpText:
        "Reward for UserInvite. These are the points that will be rewarded to the user that sends the invite. Since the reward can not be set per country yet.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setRewards((rewards) =>
          rewards.map((reward) => {
            if (reward.source === "UserInvite") {
              reward.amount = +event.target.value;
            }
            return reward;
          }),
        );
      },
      value: rewards.find((r: RewardsConfigSubModel) => r.source === "UserInvite")?.amount,
      min: 0,
    },
    {
      name: "AcceptInvite",
      label: "AcceptInvite",
      helpText:
        "Reward for AcceptInvite. These are the points that will be rewarded to the user that is invited. Since the reward can not be set per country yet.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setRewards((rewards) =>
          rewards.map((reward) => {
            if (reward.source === "AcceptInvite") {
              reward.amount = +event.target.value;
            }
            return reward;
          }),
        );
      },
      value: rewards.find((r: RewardsConfigSubModel) => r.source === "AcceptInvite")?.amount,
      min: 0,
    },
    {
      name: "AppleAttachment",
      label: "AppleAttachment",
      helpText: "Reward for AppleAttachment. Since the reward can not be set per country yet.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setRewards((rewards) =>
          rewards.map((reward) => {
            if (reward.source === "AppleAttachment") {
              reward.amount = +event.target.value;
            }
            return reward;
          }),
        );
      },
      value: rewards.find((r: RewardsConfigSubModel) => r.source === "AppleAttachment")?.amount,
      min: 0,
    },
    {
      name: "GoogleAttachment",
      label: "GoogleAttachment",
      helpText: "Reward for GoogleAttachment. Since the reward can not be set per country yet.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setRewards((rewards) =>
          rewards.map((reward) => {
            if (reward.source === "GoogleAttachment") {
              reward.amount = +event.target.value;
            }
            return reward;
          }),
        );
      },
      value: rewards.find((r: RewardsConfigSubModel) => r.source === "GoogleAttachment")?.amount,
      min: 0,
    },
    {
      name: "FacebookAttachment",
      label: "FacebookAttachment",
      helpText: "Reward for FacebookAttachment. Since the reward can not be set per country yet.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setRewards((rewards) =>
          rewards.map((reward) => {
            if (reward.source === "FacebookAttachment") {
              reward.amount = +event.target.value;
            }
            return reward;
          }),
        );
      },
      value: rewards.find((r: RewardsConfigSubModel) => r.source === "FacebookAttachment")?.amount,
      min: 0,
    },
  ];

  const weeklyStreakFields: Field[] = [
    {
      name: "weeklySteps",
      label: "Reward amount",
      helpText: "The amount of points rewarded when the user completes the streak",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => setWeekStreak({ ...weekStreak, amount: +event.currentTarget.value }),
      value: weekStreak.amount,
      min: 1,
    },
  ];

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Edit General Config</h2>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active to="/general-config" title="Rewards" />
          <Tab active={false} to="/general-config/offer" title="Offer" />
          <Tab active={false} to="/general-config/anti-cheat" title="Anti Cheat" />
          <Tab active={false} to="/general-config/auto-claim" title="Auto claim" />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <Form vertical name="rewards" onSubmit={onSubmit} submitText="Save market" fields={rewardFields} />
          <Form
            readonly={!isAdminOwner}
            vertical
            name="weekly-straeaks"
            fields={weeklyStreakFields}
            onSubmit={onSubmit}
            submitText="Save Weeksteak config"
          />
        </Card>
      </div>
    </section>
  );
}
