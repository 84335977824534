import { ReactNode } from "react";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import MDEditor from "@uiw/react-md-editor/nohighlight";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { z } from "zod";

import { Combobox, ComboboxOption } from "@/combobox";
import ImagePreviewUploader from "@/image_preview_upload";
import { Button } from "@/ui/button";
import { Card, CardContent } from "@/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";
import { Switch } from "@/ui/switch";

import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";
import { ImageType } from "~/api/model";
import { useCreatePartnerAppV1PartnerAppsPost } from "~/api/partner-app/partner-app.gen";
import { DeeplinkExplanation } from "~/components/partner-apps/DeeplinkExplanation";
import { parseMarketCountries } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";

export const Route = createFileRoute("/_auth/partner-apps/create")({
  component: () => <CreatePartnerAppPage />,
});

const formSchema = z.object({
  type: z.enum(["Points", "Diamonds"]),
  country: z.string(),
  title: z.string().min(1).max(50),
  description: z.string().min(1).max(3000),
  deeplink: z.string().min(1),
  reward: z.number().min(1).optional(),
  diamonds: z.number().optional(),
  maxDownloads: z.number().min(1),
  ios: z.boolean(),
  android: z.boolean(),
  appLogoUrl: z.string().min(1, "Please upload a logo"),
  appImageUrl: z.string().min(1, "Please upload a thumbnail"),
}).refine((data) => data.ios || data.android, {
  message: "At least one platform must be selected",
  path: ["platform"],
});

export type PartnerAppEdit = z.infer<typeof formSchema> & { _id?: string };

const CreatePartnerAppPage = () => {
  const navigate = useNavigate();

  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      initialData: [],
    },
  });

  const form = useForm<PartnerAppEdit>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: "Diamonds",
      ios: true,
      android: true,
      maxDownloads: 10,
    },
  });

  const createPartnerAppMutation = useCreatePartnerAppV1PartnerAppsPost({
    mutation: {
      onSuccess: (data) => {
        toast("Partner app campaign was successfully created!");
        navigate({
          to: `/partner-apps?status=Unpublished&country=${data.country}`,
        });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
    },
  });

  async function onSubmit(values: PartnerAppEdit) {
    await createPartnerAppMutation.mutateAsync({
      data: {
        country: values.country,
        title: values.title,
        description: values.description,
        deepLink: values.deeplink,
        reward: values.reward ?? 10,
        maximumDownloads: values.maxDownloads,
        downloadsCount: 0,
        platform: {
          ios: values.ios,
          android: values.android,
        },
        appLogoUrl: values.appLogoUrl,
        appImageUrl: values.appImageUrl,
        diamondShards: values.diamonds ? values.diamonds * import.meta.env.VITE_REACT_DIAMOND_SHARDING : undefined,
      },
    });
  }

  const formData = useWatch({
    control: form.control,
  });

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section>
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Create new Partner App</h2>
        </div>
        <div className="button-group" />
      </section>
      <Card>
        <CardContent className="p-8">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel className="flex justify-between">Reward Type</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={(value) => {
                          form.setValue("type", value as "Points" | "Diamonds");
                        }}
                        defaultValue={field.value}
                      >
                        <SelectTrigger ref={field.ref}>
                          <SelectValue placeholder="Select a type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="Points">Points</SelectItem>
                          <SelectItem value="Diamonds">Diamonds</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel className="flex justify-between">Market</FormLabel>
                    <FormControl>
                      <Combobox
                        searchName="Country"
                        placeholder="Country"
                        options={parseMarketCountries(countries)}
                        onChange={(value: ComboboxOption) => {
                          field.onChange(value.value);
                        }}
                        value={field.value}
                        showFlag
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel className="flex justify-between">
                      Title
                      <span className="text-xs">Max characters: 50 / {field.value?.split("").length || 0}</span>
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} maxLength={50} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel className="flex justify-between">
                      Description
                      <span className="text-xs">Max characters: 3000 / {field.value?.split("").length || 0}</span>
                    </FormLabel>
                    <FormControl>
                      <MDEditor {...field} preview="edit" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="deeplink"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel className="flex justify-between">Deeplink</FormLabel>
                    <FormControl>
                      <Input placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DeeplinkExplanation />
              {formData.type === "Points" && (
                <FormField
                  control={form.control}
                  name="reward"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Points</FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...field}
                          step={1}
                          onChange={(e) => {
                            const value = e.target.value ? Number.parseInt(e.target.value) : undefined;
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              {formData.type === "Diamonds" && (
                <FormField
                  control={form.control}
                  name="diamonds"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Diamonds</FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          type="number"
                          {...field}
                          step={1 / import.meta.env.VITE_REACT_DIAMOND_SHARDING}
                          onChange={(e) => {
                            const value = e.target.value ? Number.parseFloat(e.target.value) : undefined;
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              <FormField
                control={form.control}
                name="maxDownloads"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel className="flex justify-between">Max Downloads</FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value ? Number.parseInt(e.target.value) : undefined;
                          field.onChange(value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex items-center gap-8">
                <FormField
                  control={form.control}
                  name="ios"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">IOS</FormLabel>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} ref={field.ref} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="android"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel className="flex justify-between">Android</FormLabel>
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} ref={field.ref} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="appLogoUrl"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel className="flex justify-between">App Logo</FormLabel>
                    <FormControl>
                      <ImagePreviewUploader
                        className="aspect-square w-48"
                        imageType={ImageType.partner_app_logo}
                        onChange={(image: string | null) => {
                          form.setValue("appLogoUrl", image ?? "");
                        }}
                        defaultImage={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="appImageUrl"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel className="flex justify-between">App Image URL</FormLabel>
                    <FormControl>
                      <ImagePreviewUploader
                        className="aspect-square w-48"
                        imageType={ImageType.partner_app_image}
                        onChange={(image: string | null) => {
                          form.setValue("appImageUrl", image ?? "");
                        }}
                        defaultImage={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex w-full justify-end">
                <Button type="submit" variant="default">
                  Save
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </section>
  );
};
