import React from "react";
import DOMPurify from "dompurify";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import { buttonVariants } from "./ui/button";

export function ConfirmDialog({
  openDialog,
  setOpenDialog,
  title,
  confirmAction,
  confirmText = "Continue",
}: {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>> | ((value: boolean) => void);
  title: string;
  confirmAction: () => void;
  confirmText?: string;
}) {
  const splitContent = title.split("|");

  return (
    <AlertDialog open={openDialog} onOpenChange={setOpenDialog}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle
            className="text-bold flex items-center justify-between"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(splitContent[0]) }}
           />
          {splitContent[1] && (
            <AlertDialogDescription dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(splitContent[1]) }} />
          )}
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction className={buttonVariants({ variant: "destructive" })} onClick={() => confirmAction()}>
            {confirmText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
