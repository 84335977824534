import React, { ReactNode } from "react";
import { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import Tab from "@/tab";
import { Card } from "@/ui/card";

import {
  getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetSuspenseQueryOptions,
  useDeleteMarketConfigV1MarketConfigMarketConfigIdDelete,
  useUpdateMarketConfigV1MarketConfigMarketConfigIdPatch,
} from "~/api/market-config/market-config.gen";
import { LeaderboardConfigSubModel } from "~/api/model";
import { getCountryName } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Form } from "~/oldComponents/form/Form";
import { Field } from "~/oldComponents/form/types";

export const Route = createFileRoute("/_auth/markets/$marketId/leaderboards")({
  loader: ({ context: { queryClient }, params: { marketId } }) => {
    return queryClient.ensureQueryData(getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetSuspenseQueryOptions(marketId));
  },
  component: () => <UpdateMarketPage />,
});

function UpdateMarketPage() {
  const navigate = useNavigate();

  const marketId = Route.useParams().marketId;

  const { data: market } = useSuspenseQuery(getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetSuspenseQueryOptions(marketId));

  const [leaderboards, setLeaderboards] = useState<LeaderboardConfigSubModel>({
    lengthInDays: market.leaderboard.lengthInDays,
    maxActiveLeaderboards: market.leaderboard.maxActiveLeaderboards,
    maxParticipants: market.leaderboard.maxParticipants,
  });
  const [premiumLeaderboards, setPremiumLeaderboards] = useState<LeaderboardConfigSubModel>({
    lengthInDays: market.premiumLeaderboard.lengthInDays,
    maxActiveLeaderboards: market.premiumLeaderboard.maxActiveLeaderboards,
    maxParticipants: market.premiumLeaderboard.maxParticipants,
  });

  const updateMarketMutation = useUpdateMarketConfigV1MarketConfigMarketConfigIdPatch({
    mutation: {
      onSuccess: () => {
        toast("market successfully updated!");
        navigate({ to: "/markets" });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
    },
  });

  const deleteMarketMutation = useDeleteMarketConfigV1MarketConfigMarketConfigIdDelete({
    mutation: {
      onSuccess: () => {
        toast("market successfully deleted!");
        navigate({ to: "/markets" });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
    },
  });

  const onMarketDelete = async () => {
    await deleteMarketMutation.mutateAsync({ marketConfigId: marketId });
  };

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    if (!market) return;

    await updateMarketMutation.mutateAsync({
      marketConfigId: marketId,
      data: {
        ...market,
        leaderboard: leaderboards,
        premiumLeaderboard: premiumLeaderboards,
      },
    });
  };

  const rewardFields: Field[] = [
    {
      name: "length-in-days",
      label: "Length in days:",
      type: "number",
      helpText: "The length of the leaderboard in days.",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setLeaderboards((leaderboards) => {
          leaderboards.lengthInDays = +event.currentTarget.value;
          return leaderboards;
        });
      },
      value: leaderboards.lengthInDays || 7,
      row: true,
      required: true,
    },
    {
      name: "max-participants",
      label: "Maximum participants:",
      helpText: "The maximum number of participants in the leaderboard.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setLeaderboards((leaderboards) => {
          leaderboards.maxParticipants = +event.currentTarget.value;
          return leaderboards;
        });
      },
      value: leaderboards.maxParticipants || 25,
      row: true,
    },
    {
      name: "max-active-leaderboards-per-user",
      label: "Maximum active leaderboards per user:",
      helpText: "The maximum number of active leaderboards per user.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setLeaderboards((leaderboards) => {
          leaderboards.maxActiveLeaderboards = +event.currentTarget.value;
          return leaderboards;
        });
      },
      value: leaderboards.maxActiveLeaderboards || 3,
      row: true,
    },
    {
      name: "divider",
      helpText: "",
      value: "",
      label: "",
      type: "divider",
    },
    {
      name: "length-in-days-premium",
      label: "Length in days (Premium):",
      type: "number",
      helpText: "The length of the leaderboard in days. Only for premium users.",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setPremiumLeaderboards((premiumLeaderboards) => {
          premiumLeaderboards.lengthInDays = +event.currentTarget.value;
          return premiumLeaderboards;
        });
      },
      value: premiumLeaderboards.lengthInDays || 7,
      row: true,
      required: true,
    },
    {
      name: "max-participants-premium",
      label: "Maximum participants (Premium):",
      helpText: "The maximum number of participants in the leaderboard. Only for premium users.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setPremiumLeaderboards((premiumLeaderboards) => {
          premiumLeaderboards.maxParticipants = +event.currentTarget.value;
          return premiumLeaderboards;
        });
      },
      value: premiumLeaderboards.maxParticipants || 25,
      row: true,
    },
    {
      name: "max-active-leaderboards-per-user-premium",
      label: "Maximum active leaderboards per user (Premium):",
      helpText: "The maximum number of active leaderboards per user. Only for premium users.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setPremiumLeaderboards((premiumLeaderboards) => {
          premiumLeaderboards.maxActiveLeaderboards = +event.currentTarget.value;
          return premiumLeaderboards;
        });
      },
      value: premiumLeaderboards.maxActiveLeaderboards || 3,
      row: true,
    },
  ];

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Edit Market {getCountryName(market.countryCode)}</h2>
        </div>
        <div>
          <button onClick={onMarketDelete} className="button button--danger">
            Delete market
          </button>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={false} to={`/markets/${market._id}`} title="General" />
          <Tab active={false} to={`/markets/${market._id}/rewards`} title="Rewards" />
          <Tab active to={`/markets/${market._id}/leaderboards`} title="Leaderboard" />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <Form vertical name="rewards" onSubmit={onSubmit} submitText="Save market" fields={rewardFields} />
        </Card>
      </div>
    </section>
  );
}
