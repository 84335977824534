/** eslint-disable camelcase */
/** eslint-disable @typescript-eslint/no-unused-vars */

import * as React from "react";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import PropTypes from "prop-types";

import "react-day-picker/style.css";

import { cn } from "~/lib/utils";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, showOutsideDays = true, ...props }: CalendarProps) {
  const defaultClassNames = getDefaultClassNames();
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      endMonth={new Date(2125, 11)}
      fixedWeeks
      classNames={{
        day: `${defaultClassNames.day} hover:bg-accent`,
        selected: `bg-primary text-lg font-bold text-white`,
        today: "bg-card text-card-foreground",
        chevron: "text-foreground fill-current",
        root: `${defaultClassNames.root} bg-background text-foreground`,
      }}
      {...props}
    />
  );
}
Calendar.propTypes = {
  className: PropTypes.string,
  showOutsideDays: PropTypes.bool,
};
Calendar.displayName = "Calendar";

export { Calendar };
