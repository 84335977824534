/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  CreateTransactionInput,
  Error400Response,
  GetAllTransactionsV1TransactionsGetParams,
  HTTPValidationError,
  PaginatedTransactions
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get all user transactions with filters and pagination
 * @summary Get All Transactions
 */
export const getAllTransactionsV1TransactionsGet = (
    params?: GetAllTransactionsV1TransactionsGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PaginatedTransactions>(
      {url: `/v1/transactions/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAllTransactionsV1TransactionsGetQueryKey = (params?: GetAllTransactionsV1TransactionsGetParams,) => {
    return [`/v1/transactions/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAllTransactionsV1TransactionsGetQueryOptions = <TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(params?: GetAllTransactionsV1TransactionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllTransactionsV1TransactionsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>> = ({ signal }) => getAllTransactionsV1TransactionsGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetAllTransactionsV1TransactionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>>
export type GetAllTransactionsV1TransactionsGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetAllTransactionsV1TransactionsGet<TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params: undefined |  GetAllTransactionsV1TransactionsGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetAllTransactionsV1TransactionsGet<TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetAllTransactionsV1TransactionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetAllTransactionsV1TransactionsGet<TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetAllTransactionsV1TransactionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get All Transactions
 */

export function useGetAllTransactionsV1TransactionsGet<TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetAllTransactionsV1TransactionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetAllTransactionsV1TransactionsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAllTransactionsV1TransactionsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(params?: GetAllTransactionsV1TransactionsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllTransactionsV1TransactionsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>> = ({ signal }) => getAllTransactionsV1TransactionsGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetAllTransactionsV1TransactionsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>>
export type GetAllTransactionsV1TransactionsGetSuspenseQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetAllTransactionsV1TransactionsGetSuspense<TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params: undefined |  GetAllTransactionsV1TransactionsGetParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetAllTransactionsV1TransactionsGetSuspense<TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetAllTransactionsV1TransactionsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetAllTransactionsV1TransactionsGetSuspense<TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetAllTransactionsV1TransactionsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get All Transactions
 */

export function useGetAllTransactionsV1TransactionsGetSuspense<TData = Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetAllTransactionsV1TransactionsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllTransactionsV1TransactionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetAllTransactionsV1TransactionsGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a transaction that rewards a user with admin points and/or diamond shards
 * @summary Create Transaction
 */
export const createTransactionV1TransactionsPost = (
    createTransactionInput: BodyType<CreateTransactionInput>,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/v1/transactions/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createTransactionInput, signal
    },
      options);
    }
  


export const getCreateTransactionV1TransactionsPostMutationOptions = <TData = Awaited<ReturnType<typeof createTransactionV1TransactionsPost>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<CreateTransactionInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['createTransactionV1TransactionsPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTransactionV1TransactionsPost>>, {data: BodyType<CreateTransactionInput>}> = (props) => {
          const {data} = props ?? {};

          return  createTransactionV1TransactionsPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: BodyType<CreateTransactionInput>}, TContext>}

    export type CreateTransactionV1TransactionsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createTransactionV1TransactionsPost>>>
    export type CreateTransactionV1TransactionsPostMutationBody = BodyType<CreateTransactionInput>
    export type CreateTransactionV1TransactionsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Transaction
 */
export const useCreateTransactionV1TransactionsPost = <TData = Awaited<ReturnType<typeof createTransactionV1TransactionsPost>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<CreateTransactionInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {data: BodyType<CreateTransactionInput>},
        TContext
      > => {

      const mutationOptions = getCreateTransactionV1TransactionsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    