import React from "react";
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";

import { RecommendedList } from "../CollectionsParts/types";

import RecommendedForm from "./RecommendedForm";

import { RecommendedModelOutput } from "~/api/model";

const RecommendedPart = ({
  recommended,
  updateRecommended,
}: {
  recommended: RecommendedList;
  updateRecommended: React.Dispatch<React.SetStateAction<RecommendedList>>;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      const newOrder = arrayMove(recommended, active.data.current?.sortable.index, over.data.current?.sortable.index);
      newOrder.map((item) => {
        if (item) item.order = newOrder.indexOf(item) + 1;
        return item;
      });
      updateRecommended(newOrder as RecommendedList);
    }
  }

  return (
    <div className="flex flex-col gap-6">
      <h3>Recommended</h3>
      <div className="grid grid-cols-5 gap-10">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext
            items={recommended.map((_item: RecommendedModelOutput | undefined, index: number) => index + 1)}
            strategy={horizontalListSortingStrategy}
          >
            {recommended.map((item: RecommendedModelOutput | undefined, index: number) => {
              return (
                <RecommendedForm
                  key={`recommendedForm-${index}-${item?.data}`}
                  order={index + 1}
                  recommended={item}
                  allRecommended={recommended}
                  updateRecommended={updateRecommended}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default RecommendedPart;
