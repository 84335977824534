/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SetauthImport } from './routes/setauth'
import { Route as LoginImport } from './routes/login'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as AuthTransactionsImport } from './routes/_auth/transactions'
import { Route as AuthTicketsImport } from './routes/_auth/tickets'
import { Route as AuthDashboardImport } from './routes/_auth/dashboard'
import { Route as AuthUsersIndexImport } from './routes/_auth/users/index'
import { Route as AuthStepChallengesIndexImport } from './routes/_auth/step-challenges/index'
import { Route as AuthStepCampaignsIndexImport } from './routes/_auth/step-campaigns/index'
import { Route as AuthPointsLinksIndexImport } from './routes/_auth/points-links/index'
import { Route as AuthPartnerAppsIndexImport } from './routes/_auth/partner-apps/index'
import { Route as AuthMarketsIndexImport } from './routes/_auth/markets/index'
import { Route as AuthInviteLinksIndexImport } from './routes/_auth/invite-links/index'
import { Route as AuthInviteCampaignsIndexImport } from './routes/_auth/invite-campaigns/index'
import { Route as AuthGeneralConfigIndexImport } from './routes/_auth/general-config/index'
import { Route as AuthUsersTestImport } from './routes/_auth/users/test'
import { Route as AuthUsersUserIdImport } from './routes/_auth/users/$userId'
import { Route as AuthStepCampaignsCreateImport } from './routes/_auth/step-campaigns/create'
import { Route as AuthStepCampaignsCampaignIdImport } from './routes/_auth/step-campaigns/$campaignId'
import { Route as AuthPointsLinksCreateImport } from './routes/_auth/points-links/create'
import { Route as AuthPointsLinksLinkIdImport } from './routes/_auth/points-links/$linkId'
import { Route as AuthPartnerAppsCreateImport } from './routes/_auth/partner-apps/create'
import { Route as AuthPartnerAppsPartnerAppIdImport } from './routes/_auth/partner-apps/$partnerAppId'
import { Route as AuthMarketsCreateImport } from './routes/_auth/markets/create'
import { Route as AuthInviteLinksCreateImport } from './routes/_auth/invite-links/create'
import { Route as AuthInviteLinksInviteLinkIdImport } from './routes/_auth/invite-links/$inviteLinkId'
import { Route as AuthInviteCampaignsCreateImport } from './routes/_auth/invite-campaigns/create'
import { Route as AuthInviteCampaignsCampaignIdImport } from './routes/_auth/invite-campaigns/$campaignId'
import { Route as AuthGeneralConfigOfferImport } from './routes/_auth/general-config/offer'
import { Route as AuthGeneralConfigAutoClaimImport } from './routes/_auth/general-config/auto-claim'
import { Route as AuthGeneralConfigAntiCheatImport } from './routes/_auth/general-config/anti-cheat'
import { Route as AuthMarketMarketplaceImport } from './routes/_auth/$market/marketplace'
import { Route as AuthUsersAdminIndexImport } from './routes/_auth/users/admin/index'
import { Route as AuthMarketsMarketIdIndexImport } from './routes/_auth/markets/$marketId/index'
import { Route as AuthMarketMarketplaceIndexImport } from './routes/_auth/$market/marketplace/index'
import { Route as AuthUsersAdminCreateImport } from './routes/_auth/users/admin/create'
import { Route as AuthMarketsMarketIdRewardsImport } from './routes/_auth/markets/$marketId/rewards'
import { Route as AuthMarketsMarketIdLeaderboardsImport } from './routes/_auth/markets/$marketId/leaderboards'
import { Route as AuthMarketMarketplaceSoldoutImport } from './routes/_auth/$market/marketplace/soldout'
import { Route as AuthMarketMarketplaceLiveviewImport } from './routes/_auth/$market/marketplace/liveview'
import { Route as AuthMarketMarketplaceCollectionsImport } from './routes/_auth/$market/marketplace/collections'
import { Route as AuthMarketMarketplaceRewardsCreateImport } from './routes/_auth/$market/marketplace/rewards/create'
import { Route as AuthMarketMarketplaceRewardsRewardIdIndexImport } from './routes/_auth/$market/marketplace/rewards/$rewardId/index'
import { Route as AuthMarketMarketplaceRewardsBonusCreateImport } from './routes/_auth/$market/marketplace/rewards/bonus/create'
import { Route as AuthMarketMarketplaceRewardsBonusRewardIdImport } from './routes/_auth/$market/marketplace/rewards/bonus/$rewardId'
import { Route as AuthMarketMarketplaceRewardsRewardIdCouponsImport } from './routes/_auth/$market/marketplace/rewards/$rewardId/coupons'

// Create/Update Routes

const SetauthRoute = SetauthImport.update({
  id: '/setauth',
  path: '/setauth',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthTransactionsRoute = AuthTransactionsImport.update({
  id: '/transactions',
  path: '/transactions',
  getParentRoute: () => AuthRoute,
} as any)

const AuthTicketsRoute = AuthTicketsImport.update({
  id: '/tickets',
  path: '/tickets',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardRoute = AuthDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => AuthRoute,
} as any)

const AuthUsersIndexRoute = AuthUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthStepChallengesIndexRoute = AuthStepChallengesIndexImport.update({
  id: '/step-challenges/',
  path: '/step-challenges/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthStepCampaignsIndexRoute = AuthStepCampaignsIndexImport.update({
  id: '/step-campaigns/',
  path: '/step-campaigns/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPointsLinksIndexRoute = AuthPointsLinksIndexImport.update({
  id: '/points-links/',
  path: '/points-links/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPartnerAppsIndexRoute = AuthPartnerAppsIndexImport.update({
  id: '/partner-apps/',
  path: '/partner-apps/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthMarketsIndexRoute = AuthMarketsIndexImport.update({
  id: '/markets/',
  path: '/markets/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthInviteLinksIndexRoute = AuthInviteLinksIndexImport.update({
  id: '/invite-links/',
  path: '/invite-links/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthInviteCampaignsIndexRoute = AuthInviteCampaignsIndexImport.update({
  id: '/invite-campaigns/',
  path: '/invite-campaigns/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthGeneralConfigIndexRoute = AuthGeneralConfigIndexImport.update({
  id: '/general-config/',
  path: '/general-config/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthUsersTestRoute = AuthUsersTestImport.update({
  id: '/users/test',
  path: '/users/test',
  getParentRoute: () => AuthRoute,
} as any)

const AuthUsersUserIdRoute = AuthUsersUserIdImport.update({
  id: '/users/$userId',
  path: '/users/$userId',
  getParentRoute: () => AuthRoute,
} as any)

const AuthStepCampaignsCreateRoute = AuthStepCampaignsCreateImport.update({
  id: '/step-campaigns/create',
  path: '/step-campaigns/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthStepCampaignsCampaignIdRoute =
  AuthStepCampaignsCampaignIdImport.update({
    id: '/step-campaigns/$campaignId',
    path: '/step-campaigns/$campaignId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthPointsLinksCreateRoute = AuthPointsLinksCreateImport.update({
  id: '/points-links/create',
  path: '/points-links/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPointsLinksLinkIdRoute = AuthPointsLinksLinkIdImport.update({
  id: '/points-links/$linkId',
  path: '/points-links/$linkId',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPartnerAppsCreateRoute = AuthPartnerAppsCreateImport.update({
  id: '/partner-apps/create',
  path: '/partner-apps/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPartnerAppsPartnerAppIdRoute =
  AuthPartnerAppsPartnerAppIdImport.update({
    id: '/partner-apps/$partnerAppId',
    path: '/partner-apps/$partnerAppId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthMarketsCreateRoute = AuthMarketsCreateImport.update({
  id: '/markets/create',
  path: '/markets/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthInviteLinksCreateRoute = AuthInviteLinksCreateImport.update({
  id: '/invite-links/create',
  path: '/invite-links/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthInviteLinksInviteLinkIdRoute =
  AuthInviteLinksInviteLinkIdImport.update({
    id: '/invite-links/$inviteLinkId',
    path: '/invite-links/$inviteLinkId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthInviteCampaignsCreateRoute = AuthInviteCampaignsCreateImport.update({
  id: '/invite-campaigns/create',
  path: '/invite-campaigns/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthInviteCampaignsCampaignIdRoute =
  AuthInviteCampaignsCampaignIdImport.update({
    id: '/invite-campaigns/$campaignId',
    path: '/invite-campaigns/$campaignId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthGeneralConfigOfferRoute = AuthGeneralConfigOfferImport.update({
  id: '/general-config/offer',
  path: '/general-config/offer',
  getParentRoute: () => AuthRoute,
} as any)

const AuthGeneralConfigAutoClaimRoute = AuthGeneralConfigAutoClaimImport.update(
  {
    id: '/general-config/auto-claim',
    path: '/general-config/auto-claim',
    getParentRoute: () => AuthRoute,
  } as any,
)

const AuthGeneralConfigAntiCheatRoute = AuthGeneralConfigAntiCheatImport.update(
  {
    id: '/general-config/anti-cheat',
    path: '/general-config/anti-cheat',
    getParentRoute: () => AuthRoute,
  } as any,
)

const AuthMarketMarketplaceRoute = AuthMarketMarketplaceImport.update({
  id: '/$market/marketplace',
  path: '/$market/marketplace',
  getParentRoute: () => AuthRoute,
} as any)

const AuthUsersAdminIndexRoute = AuthUsersAdminIndexImport.update({
  id: '/users/admin/',
  path: '/users/admin/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthMarketsMarketIdIndexRoute = AuthMarketsMarketIdIndexImport.update({
  id: '/markets/$marketId/',
  path: '/markets/$marketId/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthMarketMarketplaceIndexRoute = AuthMarketMarketplaceIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => AuthMarketMarketplaceRoute,
  } as any,
)

const AuthUsersAdminCreateRoute = AuthUsersAdminCreateImport.update({
  id: '/users/admin/create',
  path: '/users/admin/create',
  getParentRoute: () => AuthRoute,
} as any)

const AuthMarketsMarketIdRewardsRoute = AuthMarketsMarketIdRewardsImport.update(
  {
    id: '/markets/$marketId/rewards',
    path: '/markets/$marketId/rewards',
    getParentRoute: () => AuthRoute,
  } as any,
)

const AuthMarketsMarketIdLeaderboardsRoute =
  AuthMarketsMarketIdLeaderboardsImport.update({
    id: '/markets/$marketId/leaderboards',
    path: '/markets/$marketId/leaderboards',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthMarketMarketplaceSoldoutRoute =
  AuthMarketMarketplaceSoldoutImport.update({
    id: '/soldout',
    path: '/soldout',
    getParentRoute: () => AuthMarketMarketplaceRoute,
  } as any)

const AuthMarketMarketplaceLiveviewRoute =
  AuthMarketMarketplaceLiveviewImport.update({
    id: '/liveview',
    path: '/liveview',
    getParentRoute: () => AuthMarketMarketplaceRoute,
  } as any)

const AuthMarketMarketplaceCollectionsRoute =
  AuthMarketMarketplaceCollectionsImport.update({
    id: '/collections',
    path: '/collections',
    getParentRoute: () => AuthMarketMarketplaceRoute,
  } as any)

const AuthMarketMarketplaceRewardsCreateRoute =
  AuthMarketMarketplaceRewardsCreateImport.update({
    id: '/rewards/create',
    path: '/rewards/create',
    getParentRoute: () => AuthMarketMarketplaceRoute,
  } as any)

const AuthMarketMarketplaceRewardsRewardIdIndexRoute =
  AuthMarketMarketplaceRewardsRewardIdIndexImport.update({
    id: '/rewards/$rewardId/',
    path: '/rewards/$rewardId/',
    getParentRoute: () => AuthMarketMarketplaceRoute,
  } as any)

const AuthMarketMarketplaceRewardsBonusCreateRoute =
  AuthMarketMarketplaceRewardsBonusCreateImport.update({
    id: '/rewards/bonus/create',
    path: '/rewards/bonus/create',
    getParentRoute: () => AuthMarketMarketplaceRoute,
  } as any)

const AuthMarketMarketplaceRewardsBonusRewardIdRoute =
  AuthMarketMarketplaceRewardsBonusRewardIdImport.update({
    id: '/rewards/bonus/$rewardId',
    path: '/rewards/bonus/$rewardId',
    getParentRoute: () => AuthMarketMarketplaceRoute,
  } as any)

const AuthMarketMarketplaceRewardsRewardIdCouponsRoute =
  AuthMarketMarketplaceRewardsRewardIdCouponsImport.update({
    id: '/rewards/$rewardId/coupons',
    path: '/rewards/$rewardId/coupons',
    getParentRoute: () => AuthMarketMarketplaceRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/setauth': {
      id: '/setauth'
      path: '/setauth'
      fullPath: '/setauth'
      preLoaderRoute: typeof SetauthImport
      parentRoute: typeof rootRoute
    }
    '/_auth/dashboard': {
      id: '/_auth/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof AuthDashboardImport
      parentRoute: typeof AuthImport
    }
    '/_auth/tickets': {
      id: '/_auth/tickets'
      path: '/tickets'
      fullPath: '/tickets'
      preLoaderRoute: typeof AuthTicketsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/transactions': {
      id: '/_auth/transactions'
      path: '/transactions'
      fullPath: '/transactions'
      preLoaderRoute: typeof AuthTransactionsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/$market/marketplace': {
      id: '/_auth/$market/marketplace'
      path: '/$market/marketplace'
      fullPath: '/$market/marketplace'
      preLoaderRoute: typeof AuthMarketMarketplaceImport
      parentRoute: typeof AuthImport
    }
    '/_auth/general-config/anti-cheat': {
      id: '/_auth/general-config/anti-cheat'
      path: '/general-config/anti-cheat'
      fullPath: '/general-config/anti-cheat'
      preLoaderRoute: typeof AuthGeneralConfigAntiCheatImport
      parentRoute: typeof AuthImport
    }
    '/_auth/general-config/auto-claim': {
      id: '/_auth/general-config/auto-claim'
      path: '/general-config/auto-claim'
      fullPath: '/general-config/auto-claim'
      preLoaderRoute: typeof AuthGeneralConfigAutoClaimImport
      parentRoute: typeof AuthImport
    }
    '/_auth/general-config/offer': {
      id: '/_auth/general-config/offer'
      path: '/general-config/offer'
      fullPath: '/general-config/offer'
      preLoaderRoute: typeof AuthGeneralConfigOfferImport
      parentRoute: typeof AuthImport
    }
    '/_auth/invite-campaigns/$campaignId': {
      id: '/_auth/invite-campaigns/$campaignId'
      path: '/invite-campaigns/$campaignId'
      fullPath: '/invite-campaigns/$campaignId'
      preLoaderRoute: typeof AuthInviteCampaignsCampaignIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/invite-campaigns/create': {
      id: '/_auth/invite-campaigns/create'
      path: '/invite-campaigns/create'
      fullPath: '/invite-campaigns/create'
      preLoaderRoute: typeof AuthInviteCampaignsCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/invite-links/$inviteLinkId': {
      id: '/_auth/invite-links/$inviteLinkId'
      path: '/invite-links/$inviteLinkId'
      fullPath: '/invite-links/$inviteLinkId'
      preLoaderRoute: typeof AuthInviteLinksInviteLinkIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/invite-links/create': {
      id: '/_auth/invite-links/create'
      path: '/invite-links/create'
      fullPath: '/invite-links/create'
      preLoaderRoute: typeof AuthInviteLinksCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/markets/create': {
      id: '/_auth/markets/create'
      path: '/markets/create'
      fullPath: '/markets/create'
      preLoaderRoute: typeof AuthMarketsCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partner-apps/$partnerAppId': {
      id: '/_auth/partner-apps/$partnerAppId'
      path: '/partner-apps/$partnerAppId'
      fullPath: '/partner-apps/$partnerAppId'
      preLoaderRoute: typeof AuthPartnerAppsPartnerAppIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partner-apps/create': {
      id: '/_auth/partner-apps/create'
      path: '/partner-apps/create'
      fullPath: '/partner-apps/create'
      preLoaderRoute: typeof AuthPartnerAppsCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/points-links/$linkId': {
      id: '/_auth/points-links/$linkId'
      path: '/points-links/$linkId'
      fullPath: '/points-links/$linkId'
      preLoaderRoute: typeof AuthPointsLinksLinkIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/points-links/create': {
      id: '/_auth/points-links/create'
      path: '/points-links/create'
      fullPath: '/points-links/create'
      preLoaderRoute: typeof AuthPointsLinksCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/step-campaigns/$campaignId': {
      id: '/_auth/step-campaigns/$campaignId'
      path: '/step-campaigns/$campaignId'
      fullPath: '/step-campaigns/$campaignId'
      preLoaderRoute: typeof AuthStepCampaignsCampaignIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/step-campaigns/create': {
      id: '/_auth/step-campaigns/create'
      path: '/step-campaigns/create'
      fullPath: '/step-campaigns/create'
      preLoaderRoute: typeof AuthStepCampaignsCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/users/$userId': {
      id: '/_auth/users/$userId'
      path: '/users/$userId'
      fullPath: '/users/$userId'
      preLoaderRoute: typeof AuthUsersUserIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/users/test': {
      id: '/_auth/users/test'
      path: '/users/test'
      fullPath: '/users/test'
      preLoaderRoute: typeof AuthUsersTestImport
      parentRoute: typeof AuthImport
    }
    '/_auth/general-config/': {
      id: '/_auth/general-config/'
      path: '/general-config'
      fullPath: '/general-config'
      preLoaderRoute: typeof AuthGeneralConfigIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/invite-campaigns/': {
      id: '/_auth/invite-campaigns/'
      path: '/invite-campaigns'
      fullPath: '/invite-campaigns'
      preLoaderRoute: typeof AuthInviteCampaignsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/invite-links/': {
      id: '/_auth/invite-links/'
      path: '/invite-links'
      fullPath: '/invite-links'
      preLoaderRoute: typeof AuthInviteLinksIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/markets/': {
      id: '/_auth/markets/'
      path: '/markets'
      fullPath: '/markets'
      preLoaderRoute: typeof AuthMarketsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partner-apps/': {
      id: '/_auth/partner-apps/'
      path: '/partner-apps'
      fullPath: '/partner-apps'
      preLoaderRoute: typeof AuthPartnerAppsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/points-links/': {
      id: '/_auth/points-links/'
      path: '/points-links'
      fullPath: '/points-links'
      preLoaderRoute: typeof AuthPointsLinksIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/step-campaigns/': {
      id: '/_auth/step-campaigns/'
      path: '/step-campaigns'
      fullPath: '/step-campaigns'
      preLoaderRoute: typeof AuthStepCampaignsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/step-challenges/': {
      id: '/_auth/step-challenges/'
      path: '/step-challenges'
      fullPath: '/step-challenges'
      preLoaderRoute: typeof AuthStepChallengesIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/users/': {
      id: '/_auth/users/'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof AuthUsersIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/$market/marketplace/collections': {
      id: '/_auth/$market/marketplace/collections'
      path: '/collections'
      fullPath: '/$market/marketplace/collections'
      preLoaderRoute: typeof AuthMarketMarketplaceCollectionsImport
      parentRoute: typeof AuthMarketMarketplaceImport
    }
    '/_auth/$market/marketplace/liveview': {
      id: '/_auth/$market/marketplace/liveview'
      path: '/liveview'
      fullPath: '/$market/marketplace/liveview'
      preLoaderRoute: typeof AuthMarketMarketplaceLiveviewImport
      parentRoute: typeof AuthMarketMarketplaceImport
    }
    '/_auth/$market/marketplace/soldout': {
      id: '/_auth/$market/marketplace/soldout'
      path: '/soldout'
      fullPath: '/$market/marketplace/soldout'
      preLoaderRoute: typeof AuthMarketMarketplaceSoldoutImport
      parentRoute: typeof AuthMarketMarketplaceImport
    }
    '/_auth/markets/$marketId/leaderboards': {
      id: '/_auth/markets/$marketId/leaderboards'
      path: '/markets/$marketId/leaderboards'
      fullPath: '/markets/$marketId/leaderboards'
      preLoaderRoute: typeof AuthMarketsMarketIdLeaderboardsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/markets/$marketId/rewards': {
      id: '/_auth/markets/$marketId/rewards'
      path: '/markets/$marketId/rewards'
      fullPath: '/markets/$marketId/rewards'
      preLoaderRoute: typeof AuthMarketsMarketIdRewardsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/users/admin/create': {
      id: '/_auth/users/admin/create'
      path: '/users/admin/create'
      fullPath: '/users/admin/create'
      preLoaderRoute: typeof AuthUsersAdminCreateImport
      parentRoute: typeof AuthImport
    }
    '/_auth/$market/marketplace/': {
      id: '/_auth/$market/marketplace/'
      path: '/'
      fullPath: '/$market/marketplace/'
      preLoaderRoute: typeof AuthMarketMarketplaceIndexImport
      parentRoute: typeof AuthMarketMarketplaceImport
    }
    '/_auth/markets/$marketId/': {
      id: '/_auth/markets/$marketId/'
      path: '/markets/$marketId'
      fullPath: '/markets/$marketId'
      preLoaderRoute: typeof AuthMarketsMarketIdIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/users/admin/': {
      id: '/_auth/users/admin/'
      path: '/users/admin'
      fullPath: '/users/admin'
      preLoaderRoute: typeof AuthUsersAdminIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/$market/marketplace/rewards/create': {
      id: '/_auth/$market/marketplace/rewards/create'
      path: '/rewards/create'
      fullPath: '/$market/marketplace/rewards/create'
      preLoaderRoute: typeof AuthMarketMarketplaceRewardsCreateImport
      parentRoute: typeof AuthMarketMarketplaceImport
    }
    '/_auth/$market/marketplace/rewards/$rewardId/coupons': {
      id: '/_auth/$market/marketplace/rewards/$rewardId/coupons'
      path: '/rewards/$rewardId/coupons'
      fullPath: '/$market/marketplace/rewards/$rewardId/coupons'
      preLoaderRoute: typeof AuthMarketMarketplaceRewardsRewardIdCouponsImport
      parentRoute: typeof AuthMarketMarketplaceImport
    }
    '/_auth/$market/marketplace/rewards/bonus/$rewardId': {
      id: '/_auth/$market/marketplace/rewards/bonus/$rewardId'
      path: '/rewards/bonus/$rewardId'
      fullPath: '/$market/marketplace/rewards/bonus/$rewardId'
      preLoaderRoute: typeof AuthMarketMarketplaceRewardsBonusRewardIdImport
      parentRoute: typeof AuthMarketMarketplaceImport
    }
    '/_auth/$market/marketplace/rewards/bonus/create': {
      id: '/_auth/$market/marketplace/rewards/bonus/create'
      path: '/rewards/bonus/create'
      fullPath: '/$market/marketplace/rewards/bonus/create'
      preLoaderRoute: typeof AuthMarketMarketplaceRewardsBonusCreateImport
      parentRoute: typeof AuthMarketMarketplaceImport
    }
    '/_auth/$market/marketplace/rewards/$rewardId/': {
      id: '/_auth/$market/marketplace/rewards/$rewardId/'
      path: '/rewards/$rewardId'
      fullPath: '/$market/marketplace/rewards/$rewardId'
      preLoaderRoute: typeof AuthMarketMarketplaceRewardsRewardIdIndexImport
      parentRoute: typeof AuthMarketMarketplaceImport
    }
  }
}

// Create and export the route tree

interface AuthMarketMarketplaceRouteChildren {
  AuthMarketMarketplaceCollectionsRoute: typeof AuthMarketMarketplaceCollectionsRoute
  AuthMarketMarketplaceLiveviewRoute: typeof AuthMarketMarketplaceLiveviewRoute
  AuthMarketMarketplaceSoldoutRoute: typeof AuthMarketMarketplaceSoldoutRoute
  AuthMarketMarketplaceIndexRoute: typeof AuthMarketMarketplaceIndexRoute
  AuthMarketMarketplaceRewardsCreateRoute: typeof AuthMarketMarketplaceRewardsCreateRoute
  AuthMarketMarketplaceRewardsRewardIdCouponsRoute: typeof AuthMarketMarketplaceRewardsRewardIdCouponsRoute
  AuthMarketMarketplaceRewardsBonusRewardIdRoute: typeof AuthMarketMarketplaceRewardsBonusRewardIdRoute
  AuthMarketMarketplaceRewardsBonusCreateRoute: typeof AuthMarketMarketplaceRewardsBonusCreateRoute
  AuthMarketMarketplaceRewardsRewardIdIndexRoute: typeof AuthMarketMarketplaceRewardsRewardIdIndexRoute
}

const AuthMarketMarketplaceRouteChildren: AuthMarketMarketplaceRouteChildren = {
  AuthMarketMarketplaceCollectionsRoute: AuthMarketMarketplaceCollectionsRoute,
  AuthMarketMarketplaceLiveviewRoute: AuthMarketMarketplaceLiveviewRoute,
  AuthMarketMarketplaceSoldoutRoute: AuthMarketMarketplaceSoldoutRoute,
  AuthMarketMarketplaceIndexRoute: AuthMarketMarketplaceIndexRoute,
  AuthMarketMarketplaceRewardsCreateRoute:
    AuthMarketMarketplaceRewardsCreateRoute,
  AuthMarketMarketplaceRewardsRewardIdCouponsRoute:
    AuthMarketMarketplaceRewardsRewardIdCouponsRoute,
  AuthMarketMarketplaceRewardsBonusRewardIdRoute:
    AuthMarketMarketplaceRewardsBonusRewardIdRoute,
  AuthMarketMarketplaceRewardsBonusCreateRoute:
    AuthMarketMarketplaceRewardsBonusCreateRoute,
  AuthMarketMarketplaceRewardsRewardIdIndexRoute:
    AuthMarketMarketplaceRewardsRewardIdIndexRoute,
}

const AuthMarketMarketplaceRouteWithChildren =
  AuthMarketMarketplaceRoute._addFileChildren(
    AuthMarketMarketplaceRouteChildren,
  )

interface AuthRouteChildren {
  AuthDashboardRoute: typeof AuthDashboardRoute
  AuthTicketsRoute: typeof AuthTicketsRoute
  AuthTransactionsRoute: typeof AuthTransactionsRoute
  AuthMarketMarketplaceRoute: typeof AuthMarketMarketplaceRouteWithChildren
  AuthGeneralConfigAntiCheatRoute: typeof AuthGeneralConfigAntiCheatRoute
  AuthGeneralConfigAutoClaimRoute: typeof AuthGeneralConfigAutoClaimRoute
  AuthGeneralConfigOfferRoute: typeof AuthGeneralConfigOfferRoute
  AuthInviteCampaignsCampaignIdRoute: typeof AuthInviteCampaignsCampaignIdRoute
  AuthInviteCampaignsCreateRoute: typeof AuthInviteCampaignsCreateRoute
  AuthInviteLinksInviteLinkIdRoute: typeof AuthInviteLinksInviteLinkIdRoute
  AuthInviteLinksCreateRoute: typeof AuthInviteLinksCreateRoute
  AuthMarketsCreateRoute: typeof AuthMarketsCreateRoute
  AuthPartnerAppsPartnerAppIdRoute: typeof AuthPartnerAppsPartnerAppIdRoute
  AuthPartnerAppsCreateRoute: typeof AuthPartnerAppsCreateRoute
  AuthPointsLinksLinkIdRoute: typeof AuthPointsLinksLinkIdRoute
  AuthPointsLinksCreateRoute: typeof AuthPointsLinksCreateRoute
  AuthStepCampaignsCampaignIdRoute: typeof AuthStepCampaignsCampaignIdRoute
  AuthStepCampaignsCreateRoute: typeof AuthStepCampaignsCreateRoute
  AuthUsersUserIdRoute: typeof AuthUsersUserIdRoute
  AuthUsersTestRoute: typeof AuthUsersTestRoute
  AuthGeneralConfigIndexRoute: typeof AuthGeneralConfigIndexRoute
  AuthInviteCampaignsIndexRoute: typeof AuthInviteCampaignsIndexRoute
  AuthInviteLinksIndexRoute: typeof AuthInviteLinksIndexRoute
  AuthMarketsIndexRoute: typeof AuthMarketsIndexRoute
  AuthPartnerAppsIndexRoute: typeof AuthPartnerAppsIndexRoute
  AuthPointsLinksIndexRoute: typeof AuthPointsLinksIndexRoute
  AuthStepCampaignsIndexRoute: typeof AuthStepCampaignsIndexRoute
  AuthStepChallengesIndexRoute: typeof AuthStepChallengesIndexRoute
  AuthUsersIndexRoute: typeof AuthUsersIndexRoute
  AuthMarketsMarketIdLeaderboardsRoute: typeof AuthMarketsMarketIdLeaderboardsRoute
  AuthMarketsMarketIdRewardsRoute: typeof AuthMarketsMarketIdRewardsRoute
  AuthUsersAdminCreateRoute: typeof AuthUsersAdminCreateRoute
  AuthMarketsMarketIdIndexRoute: typeof AuthMarketsMarketIdIndexRoute
  AuthUsersAdminIndexRoute: typeof AuthUsersAdminIndexRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthDashboardRoute: AuthDashboardRoute,
  AuthTicketsRoute: AuthTicketsRoute,
  AuthTransactionsRoute: AuthTransactionsRoute,
  AuthMarketMarketplaceRoute: AuthMarketMarketplaceRouteWithChildren,
  AuthGeneralConfigAntiCheatRoute: AuthGeneralConfigAntiCheatRoute,
  AuthGeneralConfigAutoClaimRoute: AuthGeneralConfigAutoClaimRoute,
  AuthGeneralConfigOfferRoute: AuthGeneralConfigOfferRoute,
  AuthInviteCampaignsCampaignIdRoute: AuthInviteCampaignsCampaignIdRoute,
  AuthInviteCampaignsCreateRoute: AuthInviteCampaignsCreateRoute,
  AuthInviteLinksInviteLinkIdRoute: AuthInviteLinksInviteLinkIdRoute,
  AuthInviteLinksCreateRoute: AuthInviteLinksCreateRoute,
  AuthMarketsCreateRoute: AuthMarketsCreateRoute,
  AuthPartnerAppsPartnerAppIdRoute: AuthPartnerAppsPartnerAppIdRoute,
  AuthPartnerAppsCreateRoute: AuthPartnerAppsCreateRoute,
  AuthPointsLinksLinkIdRoute: AuthPointsLinksLinkIdRoute,
  AuthPointsLinksCreateRoute: AuthPointsLinksCreateRoute,
  AuthStepCampaignsCampaignIdRoute: AuthStepCampaignsCampaignIdRoute,
  AuthStepCampaignsCreateRoute: AuthStepCampaignsCreateRoute,
  AuthUsersUserIdRoute: AuthUsersUserIdRoute,
  AuthUsersTestRoute: AuthUsersTestRoute,
  AuthGeneralConfigIndexRoute: AuthGeneralConfigIndexRoute,
  AuthInviteCampaignsIndexRoute: AuthInviteCampaignsIndexRoute,
  AuthInviteLinksIndexRoute: AuthInviteLinksIndexRoute,
  AuthMarketsIndexRoute: AuthMarketsIndexRoute,
  AuthPartnerAppsIndexRoute: AuthPartnerAppsIndexRoute,
  AuthPointsLinksIndexRoute: AuthPointsLinksIndexRoute,
  AuthStepCampaignsIndexRoute: AuthStepCampaignsIndexRoute,
  AuthStepChallengesIndexRoute: AuthStepChallengesIndexRoute,
  AuthUsersIndexRoute: AuthUsersIndexRoute,
  AuthMarketsMarketIdLeaderboardsRoute: AuthMarketsMarketIdLeaderboardsRoute,
  AuthMarketsMarketIdRewardsRoute: AuthMarketsMarketIdRewardsRoute,
  AuthUsersAdminCreateRoute: AuthUsersAdminCreateRoute,
  AuthMarketsMarketIdIndexRoute: AuthMarketsMarketIdIndexRoute,
  AuthUsersAdminIndexRoute: AuthUsersAdminIndexRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/setauth': typeof SetauthRoute
  '/dashboard': typeof AuthDashboardRoute
  '/tickets': typeof AuthTicketsRoute
  '/transactions': typeof AuthTransactionsRoute
  '/$market/marketplace': typeof AuthMarketMarketplaceRouteWithChildren
  '/general-config/anti-cheat': typeof AuthGeneralConfigAntiCheatRoute
  '/general-config/auto-claim': typeof AuthGeneralConfigAutoClaimRoute
  '/general-config/offer': typeof AuthGeneralConfigOfferRoute
  '/invite-campaigns/$campaignId': typeof AuthInviteCampaignsCampaignIdRoute
  '/invite-campaigns/create': typeof AuthInviteCampaignsCreateRoute
  '/invite-links/$inviteLinkId': typeof AuthInviteLinksInviteLinkIdRoute
  '/invite-links/create': typeof AuthInviteLinksCreateRoute
  '/markets/create': typeof AuthMarketsCreateRoute
  '/partner-apps/$partnerAppId': typeof AuthPartnerAppsPartnerAppIdRoute
  '/partner-apps/create': typeof AuthPartnerAppsCreateRoute
  '/points-links/$linkId': typeof AuthPointsLinksLinkIdRoute
  '/points-links/create': typeof AuthPointsLinksCreateRoute
  '/step-campaigns/$campaignId': typeof AuthStepCampaignsCampaignIdRoute
  '/step-campaigns/create': typeof AuthStepCampaignsCreateRoute
  '/users/$userId': typeof AuthUsersUserIdRoute
  '/users/test': typeof AuthUsersTestRoute
  '/general-config': typeof AuthGeneralConfigIndexRoute
  '/invite-campaigns': typeof AuthInviteCampaignsIndexRoute
  '/invite-links': typeof AuthInviteLinksIndexRoute
  '/markets': typeof AuthMarketsIndexRoute
  '/partner-apps': typeof AuthPartnerAppsIndexRoute
  '/points-links': typeof AuthPointsLinksIndexRoute
  '/step-campaigns': typeof AuthStepCampaignsIndexRoute
  '/step-challenges': typeof AuthStepChallengesIndexRoute
  '/users': typeof AuthUsersIndexRoute
  '/$market/marketplace/collections': typeof AuthMarketMarketplaceCollectionsRoute
  '/$market/marketplace/liveview': typeof AuthMarketMarketplaceLiveviewRoute
  '/$market/marketplace/soldout': typeof AuthMarketMarketplaceSoldoutRoute
  '/markets/$marketId/leaderboards': typeof AuthMarketsMarketIdLeaderboardsRoute
  '/markets/$marketId/rewards': typeof AuthMarketsMarketIdRewardsRoute
  '/users/admin/create': typeof AuthUsersAdminCreateRoute
  '/$market/marketplace/': typeof AuthMarketMarketplaceIndexRoute
  '/markets/$marketId': typeof AuthMarketsMarketIdIndexRoute
  '/users/admin': typeof AuthUsersAdminIndexRoute
  '/$market/marketplace/rewards/create': typeof AuthMarketMarketplaceRewardsCreateRoute
  '/$market/marketplace/rewards/$rewardId/coupons': typeof AuthMarketMarketplaceRewardsRewardIdCouponsRoute
  '/$market/marketplace/rewards/bonus/$rewardId': typeof AuthMarketMarketplaceRewardsBonusRewardIdRoute
  '/$market/marketplace/rewards/bonus/create': typeof AuthMarketMarketplaceRewardsBonusCreateRoute
  '/$market/marketplace/rewards/$rewardId': typeof AuthMarketMarketplaceRewardsRewardIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/setauth': typeof SetauthRoute
  '/dashboard': typeof AuthDashboardRoute
  '/tickets': typeof AuthTicketsRoute
  '/transactions': typeof AuthTransactionsRoute
  '/general-config/anti-cheat': typeof AuthGeneralConfigAntiCheatRoute
  '/general-config/auto-claim': typeof AuthGeneralConfigAutoClaimRoute
  '/general-config/offer': typeof AuthGeneralConfigOfferRoute
  '/invite-campaigns/$campaignId': typeof AuthInviteCampaignsCampaignIdRoute
  '/invite-campaigns/create': typeof AuthInviteCampaignsCreateRoute
  '/invite-links/$inviteLinkId': typeof AuthInviteLinksInviteLinkIdRoute
  '/invite-links/create': typeof AuthInviteLinksCreateRoute
  '/markets/create': typeof AuthMarketsCreateRoute
  '/partner-apps/$partnerAppId': typeof AuthPartnerAppsPartnerAppIdRoute
  '/partner-apps/create': typeof AuthPartnerAppsCreateRoute
  '/points-links/$linkId': typeof AuthPointsLinksLinkIdRoute
  '/points-links/create': typeof AuthPointsLinksCreateRoute
  '/step-campaigns/$campaignId': typeof AuthStepCampaignsCampaignIdRoute
  '/step-campaigns/create': typeof AuthStepCampaignsCreateRoute
  '/users/$userId': typeof AuthUsersUserIdRoute
  '/users/test': typeof AuthUsersTestRoute
  '/general-config': typeof AuthGeneralConfigIndexRoute
  '/invite-campaigns': typeof AuthInviteCampaignsIndexRoute
  '/invite-links': typeof AuthInviteLinksIndexRoute
  '/markets': typeof AuthMarketsIndexRoute
  '/partner-apps': typeof AuthPartnerAppsIndexRoute
  '/points-links': typeof AuthPointsLinksIndexRoute
  '/step-campaigns': typeof AuthStepCampaignsIndexRoute
  '/step-challenges': typeof AuthStepChallengesIndexRoute
  '/users': typeof AuthUsersIndexRoute
  '/$market/marketplace/collections': typeof AuthMarketMarketplaceCollectionsRoute
  '/$market/marketplace/liveview': typeof AuthMarketMarketplaceLiveviewRoute
  '/$market/marketplace/soldout': typeof AuthMarketMarketplaceSoldoutRoute
  '/markets/$marketId/leaderboards': typeof AuthMarketsMarketIdLeaderboardsRoute
  '/markets/$marketId/rewards': typeof AuthMarketsMarketIdRewardsRoute
  '/users/admin/create': typeof AuthUsersAdminCreateRoute
  '/$market/marketplace': typeof AuthMarketMarketplaceIndexRoute
  '/markets/$marketId': typeof AuthMarketsMarketIdIndexRoute
  '/users/admin': typeof AuthUsersAdminIndexRoute
  '/$market/marketplace/rewards/create': typeof AuthMarketMarketplaceRewardsCreateRoute
  '/$market/marketplace/rewards/$rewardId/coupons': typeof AuthMarketMarketplaceRewardsRewardIdCouponsRoute
  '/$market/marketplace/rewards/bonus/$rewardId': typeof AuthMarketMarketplaceRewardsBonusRewardIdRoute
  '/$market/marketplace/rewards/bonus/create': typeof AuthMarketMarketplaceRewardsBonusCreateRoute
  '/$market/marketplace/rewards/$rewardId': typeof AuthMarketMarketplaceRewardsRewardIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/setauth': typeof SetauthRoute
  '/_auth/dashboard': typeof AuthDashboardRoute
  '/_auth/tickets': typeof AuthTicketsRoute
  '/_auth/transactions': typeof AuthTransactionsRoute
  '/_auth/$market/marketplace': typeof AuthMarketMarketplaceRouteWithChildren
  '/_auth/general-config/anti-cheat': typeof AuthGeneralConfigAntiCheatRoute
  '/_auth/general-config/auto-claim': typeof AuthGeneralConfigAutoClaimRoute
  '/_auth/general-config/offer': typeof AuthGeneralConfigOfferRoute
  '/_auth/invite-campaigns/$campaignId': typeof AuthInviteCampaignsCampaignIdRoute
  '/_auth/invite-campaigns/create': typeof AuthInviteCampaignsCreateRoute
  '/_auth/invite-links/$inviteLinkId': typeof AuthInviteLinksInviteLinkIdRoute
  '/_auth/invite-links/create': typeof AuthInviteLinksCreateRoute
  '/_auth/markets/create': typeof AuthMarketsCreateRoute
  '/_auth/partner-apps/$partnerAppId': typeof AuthPartnerAppsPartnerAppIdRoute
  '/_auth/partner-apps/create': typeof AuthPartnerAppsCreateRoute
  '/_auth/points-links/$linkId': typeof AuthPointsLinksLinkIdRoute
  '/_auth/points-links/create': typeof AuthPointsLinksCreateRoute
  '/_auth/step-campaigns/$campaignId': typeof AuthStepCampaignsCampaignIdRoute
  '/_auth/step-campaigns/create': typeof AuthStepCampaignsCreateRoute
  '/_auth/users/$userId': typeof AuthUsersUserIdRoute
  '/_auth/users/test': typeof AuthUsersTestRoute
  '/_auth/general-config/': typeof AuthGeneralConfigIndexRoute
  '/_auth/invite-campaigns/': typeof AuthInviteCampaignsIndexRoute
  '/_auth/invite-links/': typeof AuthInviteLinksIndexRoute
  '/_auth/markets/': typeof AuthMarketsIndexRoute
  '/_auth/partner-apps/': typeof AuthPartnerAppsIndexRoute
  '/_auth/points-links/': typeof AuthPointsLinksIndexRoute
  '/_auth/step-campaigns/': typeof AuthStepCampaignsIndexRoute
  '/_auth/step-challenges/': typeof AuthStepChallengesIndexRoute
  '/_auth/users/': typeof AuthUsersIndexRoute
  '/_auth/$market/marketplace/collections': typeof AuthMarketMarketplaceCollectionsRoute
  '/_auth/$market/marketplace/liveview': typeof AuthMarketMarketplaceLiveviewRoute
  '/_auth/$market/marketplace/soldout': typeof AuthMarketMarketplaceSoldoutRoute
  '/_auth/markets/$marketId/leaderboards': typeof AuthMarketsMarketIdLeaderboardsRoute
  '/_auth/markets/$marketId/rewards': typeof AuthMarketsMarketIdRewardsRoute
  '/_auth/users/admin/create': typeof AuthUsersAdminCreateRoute
  '/_auth/$market/marketplace/': typeof AuthMarketMarketplaceIndexRoute
  '/_auth/markets/$marketId/': typeof AuthMarketsMarketIdIndexRoute
  '/_auth/users/admin/': typeof AuthUsersAdminIndexRoute
  '/_auth/$market/marketplace/rewards/create': typeof AuthMarketMarketplaceRewardsCreateRoute
  '/_auth/$market/marketplace/rewards/$rewardId/coupons': typeof AuthMarketMarketplaceRewardsRewardIdCouponsRoute
  '/_auth/$market/marketplace/rewards/bonus/$rewardId': typeof AuthMarketMarketplaceRewardsBonusRewardIdRoute
  '/_auth/$market/marketplace/rewards/bonus/create': typeof AuthMarketMarketplaceRewardsBonusCreateRoute
  '/_auth/$market/marketplace/rewards/$rewardId/': typeof AuthMarketMarketplaceRewardsRewardIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/login'
    | '/setauth'
    | '/dashboard'
    | '/tickets'
    | '/transactions'
    | '/$market/marketplace'
    | '/general-config/anti-cheat'
    | '/general-config/auto-claim'
    | '/general-config/offer'
    | '/invite-campaigns/$campaignId'
    | '/invite-campaigns/create'
    | '/invite-links/$inviteLinkId'
    | '/invite-links/create'
    | '/markets/create'
    | '/partner-apps/$partnerAppId'
    | '/partner-apps/create'
    | '/points-links/$linkId'
    | '/points-links/create'
    | '/step-campaigns/$campaignId'
    | '/step-campaigns/create'
    | '/users/$userId'
    | '/users/test'
    | '/general-config'
    | '/invite-campaigns'
    | '/invite-links'
    | '/markets'
    | '/partner-apps'
    | '/points-links'
    | '/step-campaigns'
    | '/step-challenges'
    | '/users'
    | '/$market/marketplace/collections'
    | '/$market/marketplace/liveview'
    | '/$market/marketplace/soldout'
    | '/markets/$marketId/leaderboards'
    | '/markets/$marketId/rewards'
    | '/users/admin/create'
    | '/$market/marketplace/'
    | '/markets/$marketId'
    | '/users/admin'
    | '/$market/marketplace/rewards/create'
    | '/$market/marketplace/rewards/$rewardId/coupons'
    | '/$market/marketplace/rewards/bonus/$rewardId'
    | '/$market/marketplace/rewards/bonus/create'
    | '/$market/marketplace/rewards/$rewardId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/login'
    | '/setauth'
    | '/dashboard'
    | '/tickets'
    | '/transactions'
    | '/general-config/anti-cheat'
    | '/general-config/auto-claim'
    | '/general-config/offer'
    | '/invite-campaigns/$campaignId'
    | '/invite-campaigns/create'
    | '/invite-links/$inviteLinkId'
    | '/invite-links/create'
    | '/markets/create'
    | '/partner-apps/$partnerAppId'
    | '/partner-apps/create'
    | '/points-links/$linkId'
    | '/points-links/create'
    | '/step-campaigns/$campaignId'
    | '/step-campaigns/create'
    | '/users/$userId'
    | '/users/test'
    | '/general-config'
    | '/invite-campaigns'
    | '/invite-links'
    | '/markets'
    | '/partner-apps'
    | '/points-links'
    | '/step-campaigns'
    | '/step-challenges'
    | '/users'
    | '/$market/marketplace/collections'
    | '/$market/marketplace/liveview'
    | '/$market/marketplace/soldout'
    | '/markets/$marketId/leaderboards'
    | '/markets/$marketId/rewards'
    | '/users/admin/create'
    | '/$market/marketplace'
    | '/markets/$marketId'
    | '/users/admin'
    | '/$market/marketplace/rewards/create'
    | '/$market/marketplace/rewards/$rewardId/coupons'
    | '/$market/marketplace/rewards/bonus/$rewardId'
    | '/$market/marketplace/rewards/bonus/create'
    | '/$market/marketplace/rewards/$rewardId'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/login'
    | '/setauth'
    | '/_auth/dashboard'
    | '/_auth/tickets'
    | '/_auth/transactions'
    | '/_auth/$market/marketplace'
    | '/_auth/general-config/anti-cheat'
    | '/_auth/general-config/auto-claim'
    | '/_auth/general-config/offer'
    | '/_auth/invite-campaigns/$campaignId'
    | '/_auth/invite-campaigns/create'
    | '/_auth/invite-links/$inviteLinkId'
    | '/_auth/invite-links/create'
    | '/_auth/markets/create'
    | '/_auth/partner-apps/$partnerAppId'
    | '/_auth/partner-apps/create'
    | '/_auth/points-links/$linkId'
    | '/_auth/points-links/create'
    | '/_auth/step-campaigns/$campaignId'
    | '/_auth/step-campaigns/create'
    | '/_auth/users/$userId'
    | '/_auth/users/test'
    | '/_auth/general-config/'
    | '/_auth/invite-campaigns/'
    | '/_auth/invite-links/'
    | '/_auth/markets/'
    | '/_auth/partner-apps/'
    | '/_auth/points-links/'
    | '/_auth/step-campaigns/'
    | '/_auth/step-challenges/'
    | '/_auth/users/'
    | '/_auth/$market/marketplace/collections'
    | '/_auth/$market/marketplace/liveview'
    | '/_auth/$market/marketplace/soldout'
    | '/_auth/markets/$marketId/leaderboards'
    | '/_auth/markets/$marketId/rewards'
    | '/_auth/users/admin/create'
    | '/_auth/$market/marketplace/'
    | '/_auth/markets/$marketId/'
    | '/_auth/users/admin/'
    | '/_auth/$market/marketplace/rewards/create'
    | '/_auth/$market/marketplace/rewards/$rewardId/coupons'
    | '/_auth/$market/marketplace/rewards/bonus/$rewardId'
    | '/_auth/$market/marketplace/rewards/bonus/create'
    | '/_auth/$market/marketplace/rewards/$rewardId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  LoginRoute: typeof LoginRoute
  SetauthRoute: typeof SetauthRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  LoginRoute: LoginRoute,
  SetauthRoute: SetauthRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/login",
        "/setauth"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/dashboard",
        "/_auth/tickets",
        "/_auth/transactions",
        "/_auth/$market/marketplace",
        "/_auth/general-config/anti-cheat",
        "/_auth/general-config/auto-claim",
        "/_auth/general-config/offer",
        "/_auth/invite-campaigns/$campaignId",
        "/_auth/invite-campaigns/create",
        "/_auth/invite-links/$inviteLinkId",
        "/_auth/invite-links/create",
        "/_auth/markets/create",
        "/_auth/partner-apps/$partnerAppId",
        "/_auth/partner-apps/create",
        "/_auth/points-links/$linkId",
        "/_auth/points-links/create",
        "/_auth/step-campaigns/$campaignId",
        "/_auth/step-campaigns/create",
        "/_auth/users/$userId",
        "/_auth/users/test",
        "/_auth/general-config/",
        "/_auth/invite-campaigns/",
        "/_auth/invite-links/",
        "/_auth/markets/",
        "/_auth/partner-apps/",
        "/_auth/points-links/",
        "/_auth/step-campaigns/",
        "/_auth/step-challenges/",
        "/_auth/users/",
        "/_auth/markets/$marketId/leaderboards",
        "/_auth/markets/$marketId/rewards",
        "/_auth/users/admin/create",
        "/_auth/markets/$marketId/",
        "/_auth/users/admin/"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/setauth": {
      "filePath": "setauth.tsx"
    },
    "/_auth/dashboard": {
      "filePath": "_auth/dashboard.tsx",
      "parent": "/_auth"
    },
    "/_auth/tickets": {
      "filePath": "_auth/tickets.tsx",
      "parent": "/_auth"
    },
    "/_auth/transactions": {
      "filePath": "_auth/transactions.tsx",
      "parent": "/_auth"
    },
    "/_auth/$market/marketplace": {
      "filePath": "_auth/$market/marketplace.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/$market/marketplace/collections",
        "/_auth/$market/marketplace/liveview",
        "/_auth/$market/marketplace/soldout",
        "/_auth/$market/marketplace/",
        "/_auth/$market/marketplace/rewards/create",
        "/_auth/$market/marketplace/rewards/$rewardId/coupons",
        "/_auth/$market/marketplace/rewards/bonus/$rewardId",
        "/_auth/$market/marketplace/rewards/bonus/create",
        "/_auth/$market/marketplace/rewards/$rewardId/"
      ]
    },
    "/_auth/general-config/anti-cheat": {
      "filePath": "_auth/general-config/anti-cheat.tsx",
      "parent": "/_auth"
    },
    "/_auth/general-config/auto-claim": {
      "filePath": "_auth/general-config/auto-claim.tsx",
      "parent": "/_auth"
    },
    "/_auth/general-config/offer": {
      "filePath": "_auth/general-config/offer.tsx",
      "parent": "/_auth"
    },
    "/_auth/invite-campaigns/$campaignId": {
      "filePath": "_auth/invite-campaigns/$campaignId.tsx",
      "parent": "/_auth"
    },
    "/_auth/invite-campaigns/create": {
      "filePath": "_auth/invite-campaigns/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/invite-links/$inviteLinkId": {
      "filePath": "_auth/invite-links/$inviteLinkId.tsx",
      "parent": "/_auth"
    },
    "/_auth/invite-links/create": {
      "filePath": "_auth/invite-links/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/markets/create": {
      "filePath": "_auth/markets/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/partner-apps/$partnerAppId": {
      "filePath": "_auth/partner-apps/$partnerAppId.tsx",
      "parent": "/_auth"
    },
    "/_auth/partner-apps/create": {
      "filePath": "_auth/partner-apps/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/points-links/$linkId": {
      "filePath": "_auth/points-links/$linkId.tsx",
      "parent": "/_auth"
    },
    "/_auth/points-links/create": {
      "filePath": "_auth/points-links/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/step-campaigns/$campaignId": {
      "filePath": "_auth/step-campaigns/$campaignId.tsx",
      "parent": "/_auth"
    },
    "/_auth/step-campaigns/create": {
      "filePath": "_auth/step-campaigns/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/users/$userId": {
      "filePath": "_auth/users/$userId.tsx",
      "parent": "/_auth"
    },
    "/_auth/users/test": {
      "filePath": "_auth/users/test.tsx",
      "parent": "/_auth"
    },
    "/_auth/general-config/": {
      "filePath": "_auth/general-config/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/invite-campaigns/": {
      "filePath": "_auth/invite-campaigns/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/invite-links/": {
      "filePath": "_auth/invite-links/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/markets/": {
      "filePath": "_auth/markets/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/partner-apps/": {
      "filePath": "_auth/partner-apps/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/points-links/": {
      "filePath": "_auth/points-links/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/step-campaigns/": {
      "filePath": "_auth/step-campaigns/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/step-challenges/": {
      "filePath": "_auth/step-challenges/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/users/": {
      "filePath": "_auth/users/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/$market/marketplace/collections": {
      "filePath": "_auth/$market/marketplace/collections.tsx",
      "parent": "/_auth/$market/marketplace"
    },
    "/_auth/$market/marketplace/liveview": {
      "filePath": "_auth/$market/marketplace/liveview.tsx",
      "parent": "/_auth/$market/marketplace"
    },
    "/_auth/$market/marketplace/soldout": {
      "filePath": "_auth/$market/marketplace/soldout.tsx",
      "parent": "/_auth/$market/marketplace"
    },
    "/_auth/markets/$marketId/leaderboards": {
      "filePath": "_auth/markets/$marketId/leaderboards.tsx",
      "parent": "/_auth"
    },
    "/_auth/markets/$marketId/rewards": {
      "filePath": "_auth/markets/$marketId/rewards.tsx",
      "parent": "/_auth"
    },
    "/_auth/users/admin/create": {
      "filePath": "_auth/users/admin/create.tsx",
      "parent": "/_auth"
    },
    "/_auth/$market/marketplace/": {
      "filePath": "_auth/$market/marketplace/index.tsx",
      "parent": "/_auth/$market/marketplace"
    },
    "/_auth/markets/$marketId/": {
      "filePath": "_auth/markets/$marketId/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/users/admin/": {
      "filePath": "_auth/users/admin/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/$market/marketplace/rewards/create": {
      "filePath": "_auth/$market/marketplace/rewards/create.tsx",
      "parent": "/_auth/$market/marketplace"
    },
    "/_auth/$market/marketplace/rewards/$rewardId/coupons": {
      "filePath": "_auth/$market/marketplace/rewards/$rewardId/coupons.tsx",
      "parent": "/_auth/$market/marketplace"
    },
    "/_auth/$market/marketplace/rewards/bonus/$rewardId": {
      "filePath": "_auth/$market/marketplace/rewards/bonus/$rewardId.tsx",
      "parent": "/_auth/$market/marketplace"
    },
    "/_auth/$market/marketplace/rewards/bonus/create": {
      "filePath": "_auth/$market/marketplace/rewards/bonus/create.tsx",
      "parent": "/_auth/$market/marketplace"
    },
    "/_auth/$market/marketplace/rewards/$rewardId/": {
      "filePath": "_auth/$market/marketplace/rewards/$rewardId/index.tsx",
      "parent": "/_auth/$market/marketplace"
    }
  }
}
ROUTE_MANIFEST_END */
