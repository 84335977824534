import React from "react";
import { Toaster as Sonner } from "sonner";

import { useThemeSelector } from "~/providers/theme";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = React.memo(({ ...props }: ToasterProps) => {
  const theme = useThemeSelector((context) => context.theme);

  return (
    <Sonner
      position="top-right"
      richColors
      visibleToasts={5}
      closeButton
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground",
          actionButton: "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton: "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
      {...props}
    />
  );
});

Toaster.displayName = "Toaster";

export { Toaster };
