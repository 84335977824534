import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { Combobox, ComboboxOption } from "@/combobox";
import { Icon } from "@/icon/icon";
import Tab from "@/tab";
import { Card } from "@/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";
import { Switch } from "@/ui/switch";

import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";
import { UserStatus } from "~/api/model";
import { parseMarketCountries } from "~/helpers/country-list";
import Button from "~/oldComponents/button/Button";
import { userFormSchema, UserSearch } from "~/pages/Users/types";
import { UserTable } from "~/pages/Users/UserTable";

export const Route = createFileRoute("/_auth/users/")({
  validateSearch: userFormSchema,
  component: () => <Users />,
});

function Users() {
  const searchParams = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });
  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      initialData: [],
    },
  });

  const queryClient = useQueryClient();

  const form = useForm<UserSearch>({
    resolver: zodResolver(userFormSchema),
    defaultValues: searchParams,
  });

  const [showAllFields, setShowAllFields] = useState(false);

  const isDefault = (search: UserSearch) => {
    return (
      !search.user_id &&
      !search.email &&
      !search.first_name &&
      !search.last_name &&
      !search.phonenumber &&
      !search.postal_code &&
      !search.street &&
      !search.city &&
      !search.country &&
      !search.is_cheater &&
      !search.is_possible_cheater &&
      !search.status
    );
  };

  function onSubmit(values: z.infer<typeof userFormSchema>) {
    queryClient.invalidateQueries({ queryKey: ["/v1/users/"] });
    setTimeout(() => {
      navigate({ search: values, replace: true });
    }, 300);
  }

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Users</h2>
          <p className="text-foreground">
            Find all users in the system. This page is NOT meant to be used for analytics.
            <br />
            <b>For searching we require one of the fields to be filled in.</b>
          </p>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active to="/users" title="Users" />
          <Tab active={false} to="/users/test" title="Test Users" />
        </div>
        <Card className="flex flex-col gap-10 bg-card px-4 py-10 text-card-foreground">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <button
                  type="button"
                  onClick={() => setShowAllFields(!showAllFields)}
                  className="flex items-center gap-2 text-sm font-medium hover:text-primary w-fit"
                >
                  <Icon icon={showAllFields ? "ArrowDown" : "ArrowRight"} />
                  {showAllFields ? "Show less fields" : "Show more fields"}
                </button>

                <div className="grid grid-cols-3 gap-4">
                  <FormField
                    control={form.control}
                    name="user_id"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">User ID:</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} maxLength={50} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">Email:</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} maxLength={50} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="first_name"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">First Name:</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} maxLength={50} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className={`grid grid-cols-3 gap-4 overflow-hidden transition-all duration-200 ${showAllFields ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"}`}>
                  <FormField
                    control={form.control}
                    name="last_name"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">Last Name:</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} maxLength={50} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phonenumber"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">Phonenumber:</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} maxLength={50} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="street"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">Street:</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} maxLength={50} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="postal_code"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">Postal Code:</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} maxLength={50} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="city"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">City:</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} maxLength={50} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="country"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">Country:</FormLabel>
                        <FormControl>
                          <Combobox
                            searchName="Country"
                            placeholder="Select"
                            options={[{ value: undefined, label: "All" }, ...parseMarketCountries(countries)]}
                            onChange={(value: ComboboxOption) => {
                              if (value.value) {
                                field.onChange(value.value);
                              } else {
                                form.setValue("country", null);
                              }
                            }}
                            value={field.value}
                            showFlag
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="status"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">status:</FormLabel>
                        <FormControl>
                          <Select
                            onValueChange={(value) => {
                              if (value === "all") {
                                form.setValue("status", undefined);
                              } else {
                                form.setValue("status", value as UserStatus);
                              }
                            }}
                            defaultValue={field.value}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="All" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="all" onClick={() => form.setValue("status", undefined)}>
                                All
                              </SelectItem>
                              <SelectItem value={UserStatus.Active}>{UserStatus.Active}</SelectItem>
                              <SelectItem value={UserStatus.Deleted}>{UserStatus.Deleted}</SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="is_cheater"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">Is Cheater</FormLabel>
                        <FormControl>
                          <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="is_possible_cheater"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">Is Possible Cheater</FormLabel>
                        <FormControl>
                          <Switch checked={field.value} onCheckedChange={(value) => field.onChange(value)} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-end gap-6">
                <Button type="submit">Search</Button>
              </div>
            </form>
          </Form>

          {!isDefault(searchParams) && <UserTable searchParams={searchParams} navigate={navigate} />}
        </Card>
      </div>
    </section>
  );
}
