import React from "react";
import clsx from "clsx";

import Icon from "../icon/Icon";

import "./form-field.scss";

type Props = {
  label: string;
  name: string;
  helpText: string;
  required?: boolean;
  big?: boolean;
  horizontal?: boolean;
  row?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  custom?: boolean;
  triggered?: boolean;
};

export const FormField: React.FC<Props> = ({
  label,
  helpText,
  required,
  name,
  big,
  children,
  horizontal,
  row,
  disabled,
  custom,
  triggered,
}: Props) => {
  const classes = clsx({
    "form-field": true,
    "form-field--big": big,
    "form-field--horizontal": horizontal,
    "form-field--row": row,
    "form-field--disabled": disabled,
    "form-field--custom": custom,
  });

  const labelClasses = clsx({
    "form-field__label": true,
    "form-field__label--triggered": triggered,
  });

  return (
    <div className={classes}>
      {children}
      <label className={labelClasses} htmlFor={name}>
        {required && "* "}
        {label} <Icon icon="FaCircleInfo" title={helpText} />
      </label>
    </div>
  );
};
