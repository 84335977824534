import React, { ReactNode } from "react";
import { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import Tab from "@/tab";
import { Card } from "@/ui/card";

import {
  getGetGeneralConfigV1GeneralConfigGetSuspenseQueryOptions,
  useUpdateGeneralConfigV1GeneralConfigPatch,
} from "~/api/general-config/general-config.gen";
import { OfferConfigSubModel } from "~/api/model";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Form } from "~/oldComponents/form/Form";
import { Field } from "~/oldComponents/form/types";
import { useAuthStore } from "~/store/authStore";

export const Route = createFileRoute("/_auth/general-config/offer")({
  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(getGetGeneralConfigV1GeneralConfigGetSuspenseQueryOptions());
  },
  component: () => <GeneralConfigPage />,
});

function GeneralConfigPage() {
  const isAdminOwner = useAuthStore((state) => state.isAdminOwner);

  const { data: generalConfig } = useSuspenseQuery(getGetGeneralConfigV1GeneralConfigGetSuspenseQueryOptions());

  const [offers, setOffers] = useState<OfferConfigSubModel>(generalConfig.offers);

  const updateGeneralConfigMutation = useUpdateGeneralConfigV1GeneralConfigPatch({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
      onSuccess: () => {
        toast("Config was successfully updated!");
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await updateGeneralConfigMutation.mutateAsync({
      data: { ...generalConfig, offers },
    });
  };

  const offerConfigFields: Field[] = [
    {
      name: "bundleVersion",
      label: "IOS bundle version:",
      helpText: "IOS bundle version for which will be disabled offers with containsSubscription flag equal to true",
      type: "text",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setOffers({ iosBundleVersion: event.target.value });
      },
      value: offers.iosBundleVersion,
    },
  ];

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Edit General Config</h2>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={false} to="/general-config" title="Rewards" />
          <Tab active to="/general-config/offer" title="Offer" />
          <Tab active={false} to="/general-config/anti-cheat" title="Anti Cheat" />
          <Tab active={false} to="/general-config/auto-claim" title="Auto claim" />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <Form readonly={!isAdminOwner} vertical name="offer-config" fields={offerConfigFields} onSubmit={onSubmit} submitText="Save offer config" />
        </Card>
      </div>
    </section>
  );
}
