/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  CouponCodeModelInput,
  CouponCodeModelOutput,
  CouponCodePatch,
  GetCouponCodesV1CouponCodesGetParams,
  HTTPValidationError
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get coupon codes for an offer
 * @summary Get Coupon Codes
 */
export const getCouponCodesV1CouponCodesGet = (
    params: GetCouponCodesV1CouponCodesGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<CouponCodeModelOutput[]>(
      {url: `/v1/coupon_codes/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCouponCodesV1CouponCodesGetQueryKey = (params: GetCouponCodesV1CouponCodesGetParams,) => {
    return [`/v1/coupon_codes/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCouponCodesV1CouponCodesGetQueryOptions = <TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCouponCodesV1CouponCodesGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>> = ({ signal }) => getCouponCodesV1CouponCodesGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetCouponCodesV1CouponCodesGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>>
export type GetCouponCodesV1CouponCodesGetQueryError = ErrorType<HTTPValidationError>


export function useGetCouponCodesV1CouponCodesGet<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCouponCodesV1CouponCodesGet<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCouponCodesV1CouponCodesGet<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Coupon Codes
 */

export function useGetCouponCodesV1CouponCodesGet<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetCouponCodesV1CouponCodesGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCouponCodesV1CouponCodesGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCouponCodesV1CouponCodesGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>> = ({ signal }) => getCouponCodesV1CouponCodesGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetCouponCodesV1CouponCodesGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>>
export type GetCouponCodesV1CouponCodesGetSuspenseQueryError = ErrorType<HTTPValidationError>


export function useGetCouponCodesV1CouponCodesGetSuspense<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCouponCodesV1CouponCodesGetSuspense<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCouponCodesV1CouponCodesGetSuspense<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Coupon Codes
 */

export function useGetCouponCodesV1CouponCodesGetSuspense<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetCouponCodesV1CouponCodesGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create coupon codes
 * @summary Create Coupon Codes
 */
export const createCouponCodesV1CouponCodesPost = (
    couponCodeModelInput: BodyType<CouponCodeModelInput[]>,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/v1/coupon_codes/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: couponCodeModelInput, signal
    },
      options);
    }
  


export const getCreateCouponCodesV1CouponCodesPostMutationOptions = <TData = Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<CouponCodeModelInput[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['createCouponCodesV1CouponCodesPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>, {data: BodyType<CouponCodeModelInput[]>}> = (props) => {
          const {data} = props ?? {};

          return  createCouponCodesV1CouponCodesPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: BodyType<CouponCodeModelInput[]>}, TContext>}

    export type CreateCouponCodesV1CouponCodesPostMutationResult = NonNullable<Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>>
    export type CreateCouponCodesV1CouponCodesPostMutationBody = BodyType<CouponCodeModelInput[]>
    export type CreateCouponCodesV1CouponCodesPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Coupon Codes
 */
export const useCreateCouponCodesV1CouponCodesPost = <TData = Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<CouponCodeModelInput[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {data: BodyType<CouponCodeModelInput[]>},
        TContext
      > => {

      const mutationOptions = getCreateCouponCodesV1CouponCodesPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update coupon code
 * @summary Update Coupon Code
 */
export const updateCouponCodeV1CouponCodesCouponCodeIdPatch = (
    couponCodeId: string,
    couponCodePatch: BodyType<CouponCodePatch>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<CouponCodeModelOutput>(
      {url: `/v1/coupon_codes/${couponCodeId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: couponCodePatch
    },
      options);
    }
  


export const getUpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationOptions = <TData = Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{couponCodeId: string;data: BodyType<CouponCodePatch>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['updateCouponCodeV1CouponCodesCouponCodeIdPatch'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>, {couponCodeId: string;data: BodyType<CouponCodePatch>}> = (props) => {
          const {couponCodeId,data} = props ?? {};

          return  updateCouponCodeV1CouponCodesCouponCodeIdPatch(couponCodeId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{couponCodeId: string;data: BodyType<CouponCodePatch>}, TContext>}

    export type UpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>>
    export type UpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationBody = BodyType<CouponCodePatch>
    export type UpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Coupon Code
 */
export const useUpdateCouponCodeV1CouponCodesCouponCodeIdPatch = <TData = Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{couponCodeId: string;data: BodyType<CouponCodePatch>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {couponCodeId: string;data: BodyType<CouponCodePatch>},
        TContext
      > => {

      const mutationOptions = getUpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Bulk delete coupon codes
 * @summary Bulk Delete Coupon Codes
 */
export const bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete = (
    bulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteBody: BodyType<string[]>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/coupon_codes/bulk_delete/`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: bulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteBody
    },
      options);
    }
  


export const getBulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationOptions = <TData = Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
) => {
const mutationKey = ['bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>, {data: BodyType<string[]>}> = (props) => {
          const {data} = props ?? {};

          return  bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{data: BodyType<string[]>}, TContext>}

    export type BulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>>
    export type BulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationBody = BodyType<string[]>
    export type BulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Bulk Delete Coupon Codes
 */
export const useBulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete = <TData = Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>, TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        TData,
        TError,
        {data: BodyType<string[]>},
        TContext
      > => {

      const mutationOptions = getBulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    