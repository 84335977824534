import React from "react";
import { createFileRoute, Outlet } from "@tanstack/react-router";

import { getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions } from "~/api/marketplace/marketplace.gen";
import SplashPage from "~/pages/Splash/Splash";
import { MarketplaceProvider } from "~/providers/marketplace";
import { useMarketStore } from "~/store/market";

export const Route = createFileRoute("/_auth/$market/marketplace")({
  loader: ({ context: { queryClient }, params: { market } }) => {
    queryClient.ensureQueryData(getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions({ market }));
  },
  component: () => <MarketPlaceLayout />,
  pendingComponent: () => <SplashPage />,
  wrapInSuspense: true,
});

function MarketPlaceLayout() {
  // If the market from the route does not match the market from the store, overwrite the market in the store
  const { market: marketFromRoute } = Route.useParams();
  const setMarket = useMarketStore((state) => state.setMarket);
  const market = useMarketStore((state) => state.market);
  if (market !== marketFromRoute) {
    setMarket(marketFromRoute);
  }

  return (
    <MarketplaceProvider>
      <Outlet />
    </MarketplaceProvider>
  );
}
