import React from "react";

import { Form } from "../form/Form";

import PageLayout from "./PageLayout";
import { FormConfig, PageConfig } from "./types";

type Props = {
  loading: boolean;
  page: PageConfig;
  form: FormConfig;
};

export const CreateEditPage = ({ page, form, loading }: Props) => {
  const memoizedFields = React.useMemo(() => form.fields, [form.fields]);
  const memoizedOnSubmit = React.useCallback(
    (event?: React.FormEvent) => {
      if (form.onSubmit && event) {
        form.onSubmit(event);
      }
    },
    [form],
  );

  return (
    <PageLayout title={page.title} description={page.description} addLink={page.addLink} onDeleteClick={page.onDelete} loading={loading}>
      {!loading && (
        <Form
          name={form.name}
          fields={memoizedFields}
          onSubmit={memoizedOnSubmit}
          submitText={form.submitText}
          vertical={form.vertical}
          showStayOnPage={form.showStayOnPage}
        />
      )}
    </PageLayout>
  );
};
