import React, { ReactNode, useState } from "react";
import { useEffect } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";
import { ImageType, StepCampaignStatus } from "~/api/model";
import {
  getGetStepCampaignByIdV1StepCampaignsStepCampaignIdGetSuspenseQueryOptions,
  useUpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch,
} from "~/api/step-campaigns/step-campaigns.gen";
import { parseMarketCountries } from "~/helpers/country-list";
import { convertToUsableDate } from "~/helpers/date-formatting";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/step-campaigns/$campaignId")({
  loader: ({ context: { queryClient }, params: { campaignId } }) => {
    return queryClient.ensureQueryData(getGetStepCampaignByIdV1StepCampaignsStepCampaignIdGetSuspenseQueryOptions(campaignId));
  },
  component: () => <StepCampaignPage />,
});

function StepCampaignPage() {
  const navigate = useNavigate();

  const stepCampaignId = Route.useParams().campaignId;

  const { data: stepCampaign, isFetching } = useSuspenseQuery(
    getGetStepCampaignByIdV1StepCampaignsStepCampaignIdGetSuspenseQueryOptions(stepCampaignId),
  );

  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      initialData: [],
    },
  });

  const [publishDate, setPublishDate] = useState<Date | null>(null);
  const [unPublishDate, setUnPublishDate] = useState<Date | null>(null);
  const [stepCampaignState, setStepCampaign] = useState(stepCampaign);

  useEffect(() => {
    if (stepCampaign.publishDate) {
      setPublishDate(convertToUsableDate(stepCampaign.publishDate));
    }
    if (stepCampaign.unPublishDate) {
      setUnPublishDate(convertToUsableDate(stepCampaign.unPublishDate));
    }
  }, [stepCampaign]);

  const updateStepCampaignMutation = useUpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch({
    mutation: {
      onSuccess: () => {
        toast("Step campaign was successfully updated!");
        navigate({
          to: "/step-campaigns",
          search: {
            status: stepCampaignState.status as StepCampaignStatus,
            country: stepCampaignState.country,
          },
        });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    if (!stepCampaignState.companyLogoUrl || !stepCampaignState.backgroundImageUrl) {
      return toast.error("Error", {
        description: "Please upload both a company logo and background image before saving.",
      });
    }
    await updateStepCampaignMutation.mutateAsync({
      stepCampaignId,
      data: stepCampaignState,
    });
  };

  const page: PageConfig = {
    title: "Edit step campaign",
    description: "Use this elements, if you want to show some hints or additional information",
  };

  const form: FormConfig = {
    fields: [
      {
        name: "country",
        label: "Country:",
        type: "search-select",
        helpText: "Specify campaign country.",
        change: (data: string) => {
          setStepCampaign(prev => ({ ...prev, country: data }));
        },
        value: stepCampaignState.country,
        options: parseMarketCountries(countries),
        empty: " ",
        row: true,
        required: true,
      },
      {
        name: "title",
        label: "Title:",
        type: "text",
        helpText: "Campaign title.",
        value: stepCampaignState.title,
        change: (event: React.ChangeEvent<HTMLInputElement>) => {
          setStepCampaign(prev => ({ ...prev, title: event.target.value }));
        },
        row: true,
        required: true,
      },
      {
        name: "subtitle",
        label: "SubTitle:",
        type: "text",
        helpText: "Campaign sub-title.",
        value: stepCampaignState.subTitle,
        change: (event: React.ChangeEvent<HTMLInputElement>) => {
          setStepCampaign(prev => ({ ...prev, subTitle: event.target.value }));
        },
        row: true,
        required: true,
      },
      {
        name: "stepsDivider",
        label: "Steps divider:",
        type: "number",
        helpText: "How many steps user need to get to be rewarded.",
        value: stepCampaignState.stepsDivider,
        change: (event: React.ChangeEvent<HTMLInputElement>) => {
          setStepCampaign(prev => ({ ...prev, stepsDivider: +event.target.value }));
        },
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "reward",
        label: "Reward:",
        type: "number",
        helpText: "Reward points amount.",
        value: stepCampaignState.reward,
        change: (event: React.ChangeEvent<HTMLInputElement>) => {
          setStepCampaign(prev => ({ ...prev, reward: +event.target.value }));
        },
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "stepsPerMinute",
        label: "Steps per minute:",
        type: "number",
        helpText: "Acceptable steps per minute.",
        value: stepCampaignState.acceptableStepsPerMin,
        change: (event: React.ChangeEvent<HTMLInputElement>) => {
          setStepCampaign(prev => ({ ...prev, acceptableStepsPerMin: +event.target.value }));
        },
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "publishDate",
        label: "Publish date:",
        helpText: "Campaign publish date.",
        type: "datetime-picker",
        value: publishDate,
        change: (date: Date) => {
          setPublishDate(date);
          setStepCampaign(prev => ({ ...prev, publishDate: date.toISOString() }));
        },
        custom: true,
      },
      {
        name: "unpublishDate",
        label: "Unpublish date:",
        helpText: "Campaign unpublish date.",
        type: "datetime-picker",
        value: unPublishDate,
        change: (date: Date) => {
          setUnPublishDate(date);
          setStepCampaign(prev => ({ ...prev, unPublishDate: date.toISOString() }));
        },
        custom: true,
      },
      {
        name: "logo",
        label: "Logo:",
        helpText: "Campaign logo.",
        type: "image-cropper",
        imageType: ImageType.step_campaign_logo,
        value: stepCampaignState.companyLogoUrl,
        change: (imageUrl: string) => {
          setStepCampaign(prev => ({ ...prev, companyLogoUrl: imageUrl }));
        },
        row: true,
        custom: true,
      },
    ],
    onSubmit,
    submitText: "Save",
    name: "edit-step-campaign-link",
  };

  return <CreateEditPage loading={isFetching} page={page} form={form} />;
}
