import { useMemo } from "react";
import { createFileRoute, Link } from "@tanstack/react-router";
import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { DataTable } from "@/datatable";
import { Icon } from "@/icon/icon";
import { buttonVariants } from "@/ui/button";
import { Card } from "@/ui/card";

import { ChallengeDisplay } from "~/types/challenges";

export const Route = createFileRoute("/_auth/step-challenges/")({
  component: () => <StepCampaignList />,
});

const StepCampaignList = () => {
  const columns = useMemo<ColumnDef<ChallengeDisplay>[]>(
    () => [
      {
        accessorKey: "actions",
        header: () => "",
        cell: ({ row }) => (
          <Link className={buttonVariants({ variant: "default" })} to="/users/$userId" params={{ userId: row.original._id as string }}>
            <Icon icon="Search" />
          </Link>
        ),
      },
      {
        accessorKey: "logo",
        header: () => "",
      },
      {
        accessorKey: "title",
        header: () => "Title",
      },
      {
        accessorKey: "status",
        header: () => "Status",
      },
      {
        accessorKey: "sponsored",
        header: () => "Sponsored",
      },
      {
        accessorKey: "prize",
        header: () => "Prize",
      },
      {
        accessorKey: "users_joined",
        header: () => "Users joined",
      },
      {
        accessorKey: "users_completed",
        header: () => "Users completed",
      },
      {
        accessorKey: "required_steps",
        header: () => "Required steps",
      },
      {
        accessorKey: "point_prize",
        header: () => "Point Prize",
      },
      {
        accessorKey: "schedule",
        header: () => "Schedule",
      },
    ],
    [],
  );

  const table = useReactTable({
    data: [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Step Challenges</h2>
          <p className="text-foreground">In here you will find a list of step challenges.</p>
        </div>
      </section>
      <div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <DataTable table={table} columns={columns} emptyState="No results found. Please ajust the filters" hidePagination />
        </Card>
      </div>
    </section>
  );
};
